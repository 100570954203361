import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contrato } from '../../api/contrato';
import { ContratacionDetalle, ContratoSearch } from '../../api/contratacion/contratacion-detalle';
import { ContratoId } from 'src/app/api/contratacion/contratacion';

@Injectable({
  providedIn: 'root'
})
export class ContratacionRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msContratacion + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();

    paths.detail = "detalle"
    paths.remove = "remove";
    paths.search = "search";

    /*     restPaths.detail = "";
        restPaths.add = ""; */

    return paths;
  }

  detail<ContratoSearch, ContratacionDetalle>(id: ContratoId): Observable<ContratacionDetalle> {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail;
    if (!path.endsWith("/")) {
      path += "/"
    }



    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, id, { headers }) as Observable<ContratacionDetalle>;
  }



}
