import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SueldoID } from '../../api/empleado/sueldo';

@Injectable({
  providedIn: 'root'
})
export class SueldoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msSueldo;

    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.remove = "";

    return paths;
  }

  delete(idSueldo: number, idEmpleado: number): Observable<Message> {
    let path = this.getBaseEndpoint() + this.getRestPaths().remove;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .set("idSueldo", idSueldo.toString())
      .set("idEmpleado", idEmpleado.toString());

    return this.httpClient.delete(path, { headers, params }) as Observable<Message>;
  }
}
