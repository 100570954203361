import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { CuentaEmpleadoRestcService } from 'src/app/client/cuenta/cuenta-empleado-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CuentaEmpleadoPsService extends BaseProcess<CuentaEmpleadoRestcService>{

  constructor(httpClient: HttpClient, crest: CuentaEmpleadoRestcService) {
    super(httpClient, crest);
  }
}
