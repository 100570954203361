import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMessages, BaseView, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';
import { ContactoReg } from 'src/app/api/contacto/contacto-reg';
import { Contratacion } from 'src/app/api/contratacion/contratacion';
import { ContratacionDetalle, ContratoSearch } from 'src/app/api/contratacion/contratacion-detalle';
import { ContratacionFilter } from 'src/app/api/contratacion/contratacion-filter';
import { ContratacionReg } from 'src/app/api/contratacion/contratacion-reg';
import { ContratoMinReg } from 'src/app/api/contratacion/contrato-min-reg';
import { PersonaReg } from 'src/app/api/contratacion/persona-reg';
import { Contrato } from 'src/app/api/contrato';
import { CuentaEmpleado } from 'src/app/api/cuenta/cuenta-empleado';
import { DireccionDet } from 'src/app/api/direccion/direccion-det';
import { DireccionMinReg } from 'src/app/api/direccion/direccion-min-reg';
import { Empleado } from 'src/app/api/empleado';
import { ContratoSteps } from 'src/app/api/enums/contrato-steps.enum';
import { ContratacionPsService } from 'src/app/procs/contratacion/contratacion-ps.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'axks-contrato-renovacion',
  templateUrl: './contrato-renovacion.component.html',
  styleUrls: ['./contrato-renovacion.component.scss']
})
export class ContratoRenovacionComponent extends BaseView implements OnInit {

  @Input() activeIndex: number = 0;
  @Input() item: ContratacionReg = {};

  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  botoninicio: boolean;
  steps: MenuItem[];
  dir: DireccionDet = {};

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    this.initContrato();
    this.steps = [
      { label: 'Datos Personales', command: () => function () { this.goStep(ContratoSteps.personales) } },
      { label: 'Dirección', command: () => function () { this.goStep(ContratoSteps.direccion) } },
      { label: 'Medios de contacto', command: () => function () { this.goStep(ContratoSteps.contacto) } },
      { label: 'Contrato', command: () => function () { this.goStep(ContratoSteps.contacto) } },
      { label: 'Organigrama', command: () => function () { this.goStep(ContratoSteps.organigrama) } },
      { label: 'Datos bancarios', command: () => function () { this.goStep(ContratoSteps.datosBancarios) } },
      { label: 'Finalizar', command: () => function () { this.goStep(ContratoSteps.finalizar) } }
    ] as MenuItem[];
  }

  initContrato() {
    this.dir = {};
    if (!this.item) {
    }

    if (this.item.mediosContacto) {
      let correoPersonal = {
        idTipoContacto: 4
      } as ContactoReg;
      this.item.mediosContacto.push(correoPersonal);

      let telMovil = {
        idTipoContacto: 3
      } as ContactoReg;
      this.item.mediosContacto.push(telMovil);

    }

    this.item.cuentaBancoEmpleado = {} as CuentaEmpleado;
  }

  goStep(step: number) {
    switch (step) {
      case ContratoSteps.personales:
      case ContratoSteps.direccion:
      case ContratoSteps.contacto:
      case ContratoSteps.contrato:
      case ContratoSteps.organigrama:
      case ContratoSteps.datosBancarios:
      case ContratoSteps.finalizar:
        this.activeIndex = step;
        break;
    }
  }

  nextPage() {
    this.goStep(this.activeIndex + 1);
  }

  backPage() {
    this.goStep(this.activeIndex - 1);
  }

  showNext() {
    return this.activeIndex < ContratoSteps.finalizar ? true : false;
  }

  showBack() {
    return this.activeIndex > ContratoSteps.personales ? true : false;
  }

  showSave() {
    return (this.activeIndex == ContratoSteps.finalizar) ? true : false;
  }

  save(event) {
    let cont = this.item.contrato;
    this.item.idPeriodoPago = cont.idPeriodoPago;
    this.item.sueldo = cont.sueldo;
    this.item.sueldoDiario = cont.sueldoDiario;
    this.item.imss = cont.imss;

    this.botoninicio = true
    this.onSave.emit(this.item);
  }

  cancel(event) {
    this.onCancel.emit(event);
  }

  onDirChange(event) {
    this.dir = event as DireccionDet;
    this.item.direcciones[0] = {
      id: this.dir.id,
      calle: this.dir.calle,
      numExt: this.dir.numExt,
      numInt: this.dir.numInt
    };
  }

  onCuentaBancoChanges(event) {
    this.item.cuentaBancoEmpleado = event;
  }

  onRfcChange(event) {
    this.item.rfc = event;
  }

  onInfonavitChange(event) {
    this.item.infonavit = event;
  }
}
