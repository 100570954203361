import { version } from '../../package.json';
export const environment = {
  production: false,
  uat: true,
  dev: false,
  version: version,

  appId: "rh-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  msEmpresa: "https://pers.uat-ms.axkans.org/personas/company",
  msTipoContacto: "https://pers.uat-ms.axkans.org/personas/tcontact",
  msArea: "https://rh.uat-ms.axkans.org/rh/area",
  msPuesto: "https://rh.uat-ms.axkans.org/rh/puesto",
  msTipoContrato: "https://rh.uat-ms.axkans.org/rh/tcontrat",
  msEmpleado: "https://rh.uat-ms.axkans.org/rh/empleado",
  msEmpleadoCat: "https://rh.uat-ms.axkans.org/rh/empleado/all",
  msContrato: "https://rh.uat-ms.axkans.org/rh/contrato",
  msCodigoPosta: "https://pers.uat-ms.axkans.org/personas/cp",
  msContratacion: "https://rh.uat-ms.axkans.org/rh/contratacion",
  msCatLideres: "https://rh.uat-ms.axkans.org/rh/empleado/ps/catlideres",
  msPeriodoPago: "https://nomina.uat-ms.axkans.org/nomina/periodo-pago",
  msRoles: "https://sec.uat-ms.axkans.org/autentica/rol",
  msBancoReceptor: "https://nomina.uat-ms.axkans.org/nomina/banco-receptor/all",
  msTipoCuenta: "https://nomina.uat-ms.axkans.org/nomina/tipo-cuenta/all",
  msConfVacaciones: 'https://rh.uat-ms.axkans.org/rh/configuracion-vacaciones',
  msListSolicVacaciones: 'https://rh.uat-ms.axkans.org/rh/vacaciones/lista-solicitudes',
  msDashboardLiderRev: 'https://rh.uat-ms.axkans.org/rh/contratacion/dashboard-contratos-por-vencer-lider',
  msDashboardRhRev: 'https://rh.uat-ms.axkans.org/rh/contratacion/dashboard-contratos-por-vencer-RH',
  msListContratoVencimientoLider: 'https://rh.uat-ms.axkans.org/rh/contratacion/contratos-por-vencer-lider',
  msListContratoVencimientoRH: 'https://rh.uat-ms.axkans.org/rh/contratacion/contratos-por-vencer-RH',
  msContratoPorVencer: 'https://rh.uat-ms.axkans.org/rh/contratacion/contratos-por-vencer',
  msDireccion: 'https://pers.uat-ms.axkans.org/personas/direccion',
  msAutorizaLider: 'https://rh.uat-ms.axkans.org/rh/contratacion/renovar-contrato-lider',
  msAutorizaRH: 'https://rh.uat-ms.axkans.org/rh/contratacion/pre-renovacion',
  msPreRenovacion: 'https://rh.uat-ms.axkans.org/rh/contratacion/pre-renovacion',
  UrlFormulario: 'https://rh.uat-gui.axkans.org/renovacion-rh',
  UrlFormularioLider: 'https://rh.uat-gui.axkans.org/renovacion-lider',
  msCodigoPread: 'https://pers.uat-ms.axkans.org/personas/direccion/',
  msPersona: 'https://pers.uat-ms.axkans.org/personas/person',
  msLiquidacion: 'https://rh.uat-ms.axkans.org/rh/contratacion/liquidacion',
  msReportes: 'https://rh.uat-ms.axkans.org/rh/reportes',
  msCuentaEmpleado: 'https://nomina.uat-ms.axkans.org/nomina/cuenta-empleado',
  msSueldo: 'https://nomina.uat-ms.axkans.net/nomina/sueldo',
  msOrganigrama: "https://rh.uat-ms.axkans.org/rh/organigrama",
  msSolicitudApoyo: 'https://rh.uat-ms.axkans.org/rh/solicitud-apoyo',
  msChecador: 'https://rh.uat-ms.axkans.net/rh/checador',
  msEmpleadoChecador: 'https://rh.uat-ms.axkans.net/rh/empleado-checador',
  msTipoDescuento: 'https://nomina.uat-ms.axkans.net/nomina/tipo-descuento',
  msAjusteSueldo: 'https://nomina.uat-ms.axkans.net/nomina/ajuste-sueldo',

  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};