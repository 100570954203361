import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Arest, RestBasicPaths } from '@axks/net';
import { TipoContacto } from 'src/app/api/tipo-contacto';

/**
 * Client rest to interact with Contact type microservices
 * 
 * @author Juan Godínez
 */
@Injectable({
  providedIn: 'root'
})
export class TipoContactoRestcService extends Arest<TipoContacto,number>{

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }

  getRestPaths(): RestBasicPaths {
    
    let paths = super.getRestPaths();
    paths.detail = "";

    return paths;
}

  getBaseEndpoint(): string {
    return environment.msTipoContacto+"/";
  }
  processBody(res: any) {
    let data = res;
    return data || {};
  }
}