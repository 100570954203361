import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { CpRestcService } from 'src/app/client/direccion/cp-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CodigoPostalPsService extends BaseProcess<CpRestcService>{

  constructor(httpClient: HttpClient, restc: CpRestcService) { 
    super(httpClient,restc);
  }
}
