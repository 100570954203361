<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('area')">
        <axks-area-cat [(value)]="selArea" (onValueChange)="onValueChange($event)"></axks-area-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('puesto') && item.idArea">
        <axks-puesto-cat [(value)]="item.idPuesto" [idArea]="item.idArea"
            (onValueChange)="onValueChangePuesto($event)"></axks-puesto-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('lider')">
        <p-checkbox [(ngModel)]="item.lider" binary="true" inputId="lider"></p-checkbox>
        &nbsp; <label for="lider" class="form-field-label">Lider</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idJefeInmediato')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idJefeInmediato" [options]="catLider" optionLabel="nombre"
                optionValue="idEmpleado" [autoDisplayFirst]="false" [virtualScroll]="true" itemSize="10" [filter]="true"
                filterBy="nombre" autoWidth="false" [style]="{'width':'100%'}" (onChange)="onValueChangeJefe($event)"
                emptyFilterMessage="Sin resultados" emptyMessage="Sin resultados" appendTo="body"></p-dropdown>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Jefe inmediato</label>
        </span>

    </div>


    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idRol')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-rol" [(ngModel)]="item.idRol" [options]="catSisRoles" optionLabel="nombre"
                optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="true" itemSize="10" [filter]="true"
                filterBy="nombre" autoWidth="false" [style]="{'width':'100%'}" (onChange)="onValueChangeRol($event)"
                appendTo="body" emptyFilterMessage="Sin resultados" emptyMessage="Sin resultados"></p-dropdown>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Rol del sistema</label>
        </span>

    </div>


</div>
<p-toast></p-toast>