import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'axks-solicitudes-colaboradores',
  templateUrl: './solicitudes-colaboradores.component.html',
  styleUrls: ['./solicitudes-colaboradores.component.scss']
})
export class SolicitudesColaboradoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
