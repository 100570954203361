import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Empleado } from 'src/app/api/empleado/empleado';
import { EmpleadoMin } from 'src/app/api/empleado/empleado-min';
import { EmpleadoFilter } from 'src/app/api/empleado/empleado-filter';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { EmpleadoDetalle } from 'src/app/api/empleado/empleado-detalle';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { PreRenovacionReg } from 'src/app/api/pre-renovacion/pre-renovacion-reg';
import { Estatus } from '../../../api/estatus';
import { EstatusContrato } from '../../../api/enums/estatus-contrato';

@Component({
  selector: 'axks-empleado',
  templateUrl: './empleado.component.html',
  styleUrls: ['./empleado.component.scss'],
  providers: [MessageService, OperationScreenModeService]
})
export class EmpleadoComponent extends BaseMainComponent<EmpleadoPsService> implements OnInit {

  @Output() propagarRenovacion = new EventEmitter<any>();

  extraButtons: ButtonModel[];
  tieneVacaciones: boolean;
  autorizaRenovacion: PreRenovacionReg[] = [];
  OP_MO = OperationMode;
  activeState: boolean[] = [true, false, false];
  displayDialog: boolean = false;
  btnEliminarEmp: ButtonModel;
  showCard: boolean = false;
  urlParams = new URLSearchParams(window.location.search);
  initModDetail: boolean = false;
  mostrarDetalle = false;
  showDialogFoto: boolean = false;
  botonFotoEmp: ButtonModel;
  operationMode: OperationMode;
  irDetalle: boolean = false;
  empsInactivos: boolean = false;

  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private psService: EmpleadoPsService,
    private router: Router,

  ) {
    super(deviceService, messageService, screenModeService, sessProvider);

    if (this.urlParams.get("newEmp")) {
      this.initModDetail = true;
    }
  }

  ngOnInit() {
    //this.initExtraButtons();
    super.ngOnInit();
    if (this.initModDetail) {
      setTimeout(() => {
        this.initDetalle();
      }, 100);
    }
  }

  initEmptyItem(): ComponentItem {

    let item = {
      item: {
        direcciones: [],
        mediosContacto: [],
        personales: {
          nacionalidad: 'Mexicana'
        }
      } as EmpleadoDetalle,
      minimumItem: { personales: {} } as EmpleadoMin,
      registerItem: { personales: {} } as Empleado
    } as ComponentItem;

    switch (this.operationMode) {
      case OperationMode.SEARCH:
        item.item = { personales: {} } as EmpleadoFilter;
        break;
      case OperationMode.EDIT:
        item.item = {} as Empleado;
        break;
    }

    return item;
  }

  catchEmpsInactivos(event) {
    this.empsInactivos = event;
  }

  find(event: any): void {
    //super.find(event);
    this.showProgress = true;
    this.psService.searchWithSwitch(this.item.item, this.empsInactivos).subscribe(
      (data) => {
        this.showProgress = false;
        this.resultList = data;
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error de busqueda", "No se pudo consultar los empleados");
      }
    );
  }

  getService(): EmpleadoPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'ID', field: 'id', inDetail: true, inResultList: true },

      // Tab Datos personales
      { header: 'Nombre', field: 'personales.nombre', groupId: 'Datos Personales', inAll: true, showGroupId: false },
      { header: 'Apellido Paterno', field: 'personales.apellidoPaterno', groupId: 'Datos Personales', inAll: true },
      { header: 'Apellido Materno', field: 'personales.apellidoMaterno', groupId: 'Datos Personales', inAll: true },
      { header: 'Genero', field: 'personales.genero', groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatGenero },
      { header: 'Fecha de Nacimiento', field: "personales.fechaNacimiento", groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatDate },
      { header: 'CURP', field: 'personales.curp', groupId: 'Datos Personales', inDetail: true },
      { header: 'RFC', field: 'rfc', groupId: 'Datos Personales', inDetail: true },
      { header: 'Estado Civil', field: 'personales.estadoCivil', groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatEstadoCivil },
      { header: 'NSS', field: 'personales.nss', groupId: 'Datos Personales', inDetail: true },

      // Tab Empleado
      { header: 'Puesto', field: 'puesto.puesto', inDetail: true },
      { header: 'Área', field: 'puesto.area.area', inDetail: true },
      { header: 'Sueldo', field: 'sueldo.sueldo', inDetail: true, formatFunction: this.formatMonto },
      { header: 'Sueldo diario', field: 'sueldo.sueldoDiario', inDetail: true, formatFunction: this.formatMonto },

      // Tab Dirección
      { header: 'Calle', field: 'calle', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'No. Exterior', field: 'numExt', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'No. Interior', field: 'numInt', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Colonia', field: 'colonia', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Código postal', field: 'cp', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Alcaldía ó Municipio.', field: 'alcMun', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Ciudad', field: 'ciudad', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Estado', field: 'estado', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'País', field: 'pais', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },

      // Tab Medios de contacto
      { header: 'Contacto', field: 'contacto', arrayFieldName: 'mediosContacto', groupId: 'Medios Contacto', inDetail: true },
      { header: 'Tipo de Contacto', field: 'tipoContacto.tipoContacto', arrayFieldName: 'mediosContacto', groupId: 'Medios Contacto', inDetail: true },

      // Tab Contrato activo
      { header: 'ID contrato ', field: 'contrato.idContrato', inDetail: true, groupId: 'Contrato Activo' },
      { header: 'Fecha contratacion ', field: 'contrato.fecContratacion', inDetail: true, groupId: 'Contrato Activo', formatFunction: this.formatDate },
      { header: 'Fecha Ingreso ', field: 'fechaIniContratacion', inResultList: true, formatFunction: this.formatDate },
      { header: 'Fecha termino contrato ', field: 'contrato.fecTerminoContrato', inDetail: true, groupId: 'Contrato Activo', formatFunction: this.formatDate },
      { header: 'Tipo de contrato ', field: 'contrato.nombreTipoContrato', inDetail: true, groupId: 'Contrato Activo' },
      { header: 'Jefe Inmediato ', field: 'jefeInmediato.nombre', inDetail: true, groupId: 'Contrato Activo' },

      // Tab Cuentas Bancarias
      { header: 'Número de cuenta ', field: 'numeroCuenta', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },
      { header: 'Banco ', field: 'idBancoReceptor.bancoReceptor', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },
      { header: 'Tipo de cuenta', field: 'idTipoCuenta.tipoCuenta', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },

      // Tab Historico de contratos
      { header: 'ID contrato', field: 'idContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Histórico de contratos' },
      { header: 'Fecha Contrataciòn', field: 'fecContratacion', arrayFieldName: 'contratos', inDetail: true, groupId: 'Histórico de contratos', formatFunction: this.formatDate },
      { header: 'Fecha Termino de contrato', field: 'fecTerminoContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Histórico de contratos', formatFunction: this.formatDate },
      { header: 'Estatus', field: 'nombreEstatus', arrayFieldName: 'contratos', inDetail: true, groupId: 'Histórico de contratos' },
      { header: 'Tipo de contrato', field: 'nombreTipoContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Histórico de contratos' },
    ] as ColumnDefinition[];
  }

  formatDate(fecha: string): string {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(fecha, "dd 'de' MMMM 'de' yyyy");
  }

  formatMonto(monto): string {
    let pipe = new CurrencyPipe("es-MX", "MXN");
    return pipe.transform(monto);
  }

  formatGenero(value: string): string {
    if (value == 'M')
      return 'Masculino';
    else if (value == 'F')
      return 'Femenino';
    else
      return '';
  }

  formatEstadoCivil(value: string): string {
    if (value == 'S')
      return 'Soltero/a';
    else if (value == 'C')
      return 'Casado/a';
    else if (value == 'V')
      return 'Viudo/a'
    else
      return value;
  }

  getIdOfSelectedItem(): string {
    let profile = this.getProfile() as ProfileC;
    let empresa = profile.empresa;

    let id = "?idEmpleado=" + this.item.minimumItem.id + "&idEmpresa=" + empresa;
    return id;
  }

  getComponentTitle(): string {
    return "Empleado";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "personales", "nombre", "apellidoPaterno", "apellidoMaterno", "empInactivo"
    ];
    this.detailFields = [];
  }


  formatBoolean(value: any) {
    if (value) {
      return 'Renovar';
    } else if (value == null) {
      return '';
    } else {
      return 'En contrato';
    }
  }

  save(event: any): void {
    this.irDetalle = false;
    super.save(event);
  }

  addNew(event) {
    super.addNew(event);
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.ADDNEW);
    this.router.navigate(["contrato"],
      {
        queryParams: {
          "e": profile.empresa,
          "a": profile.aplicacion,
          "cw": true,
          "s": profile.sesion
        }
      }
    );
  }

  rowSelect(event) {
    super.rowSelect(event);

    setTimeout(() => {
      this.configExtraButtons();
    }, 2500);
  }

  configExtraButtons() {
    this.extraButtons = [];
    if (this.item.item.contrato != null && this.item.item.contrato.idEstatus == EstatusContrato.CONTRATO_ACTIVO) {
      this.initButtonLiquidar();
    }

    this.initBotonFotoEmp();

    if (this.item.item.contratoVencer == true) {
      this.initButtonRenueva();
    }
  }

  initButtonRenueva() {
    this.extraButtons.push(
      {
        name: 'btnRenovar',
        enable: true,
        label: 'Renovar',
        position: 'left',
        showLabel: true,
        showWhen: [OperationMode.SELECTED],
        onClick: this.accionRenueva.bind(this)
      }
    );
  }

  initButtonLiquidar() {
    this.btnEliminarEmp = {
      name: 'btnLiquidar',
      enable: true,
      label: 'Liquidar',
      position: 'left',
      showLabel: true,
      showWhen: [OperationMode.SELECTED],
      onClick: this.accionLiquida.bind(this)
    };

    this.extraButtons.push(this.btnEliminarEmp);
  }

  initBotonFotoEmp() {
    this.botonFotoEmp = {
      name: 'btnFotoEmp',
      enable: true,
      label: 'Foto',
      position: 'left',
      showLabel: true,
      showWhen: [OperationMode.SELECTED],
      onClick: this.accionBotonFotoEmp.bind(this)
    };

    this.extraButtons.push(this.botonFotoEmp);
  }

  accionBotonFotoEmp() {
    this.showDialogFoto = true;
    this.botonFotoEmp.enable = false;
  }

  ocultarDialogFoto(event) {
    this.showDialogFoto = false;
    this.botonFotoEmp.enable = true;
  }

  accionRenueva = (valor: any) => {
    let profile = this.getProfile() as ProfileC;
    let empresa = profile.empresa;

    this.propagarRenovacion.emit(this.autorizaRenovacion);
    this.router.navigate(["renovacion-rh"], {
      queryParams: {
        "e": empresa,
        "a": profile.aplicacion,
        "s": profile.sesion,
      }
    });
  }


  accionLiquida = (valor: any) => {
    this.showCard = true;
    this.btnEliminarEmp.enable = false;
  }

  selSolicitud(item) {
    window.parent.postMessage({
      for: "redirect",
      url: environment.UrlFormulario,
      params: {
        ep: item.idEmpresa,
        em: item.idEmpleado,
      }
    }, "*");
  }

  cancel(event) {
    if (this.irDetalle == true) {
      this.mostrarDetalle = true;
      this.screenModeService.screenMode.next(OperationMode.SELECTED);
      super.rowSelect(this.item.item);
      this.irDetalle = false;
    }
    else {
      this.mostrarDetalle = false;
      super.cancel(event);
      this.urlParams.delete('newEmp');
      window.history.replaceState(null, document.title, window.location.origin + window.location.pathname + '?' + this.urlParams.toString());
    }
  }

  edit(event: any): void {
    this.mostrarDetalle = false;
    this.irDetalle = true;
    this.screenModeService.screenMode.next(OperationMode.EDIT);
    super.edit(event);
  }

  onTabClose(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Closed', detail: 'Index: ' + event.index })
  }

  onTabOpen(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Expanded', detail: 'Index: ' + event.index });
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  disableCardEst(event) {
    this.showCard = event;
    this.btnEliminarEmp.enable = true;
  }

  initDetalle() {
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    let itemMin = {
      id: parseInt(this.urlParams.get('newEmp'))
    } as EmpleadoMin;
    this.rowSelect(itemMin);
    this.mostrarDetalle = true;
  }

}
