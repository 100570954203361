<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
        *ngIf="operationMode == opMode.SEARCH && allowFormFields('personales')">
        <axks-personales-form [item]="item.personales" [operationMode]="operationMode" [formFields]="formFields"
            [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
        </axks-personales-form>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12"
        *ngIf="allowFormFields('empInactivo') && operationMode == opMode.SEARCH">
        <div style="padding-left: 1vw;">
            <p-checkbox binary="true" inputId="fl-empInact" (onChange)="emitirEmpsInactivos($event)"></p-checkbox>
            &nbsp; <label for="fl-empInact" style="color: #5c5c5c;">Empleados inactivos</label>
        </div>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12 frm-part-block" *ngIf="operationMode == opMode.EDIT ">
        <p-tabView>
            <p-tabPanel header="Datos personales">
                <axks-personales-form [item]="item.personales" [operationMode]="operationMode" [formFields]="formFields"
                    [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields" [rfc]="item.rfc"
                    (rfcChange)="setRFC($event)">
                </axks-personales-form>
            </p-tabPanel>

            <p-tabPanel header="Dirección" *ngIf="allowFormFields('direcciones')">
                <ng-container *ngFor="let dir of item.direcciones">
                    <axks-direccion-form [item]="dir" [operationMode]="operationMode" [formFields]="formFields"
                        [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields"
                        (onDirchange)="changeDir($event)">
                    </axks-direccion-form>
                </ng-container>
            </p-tabPanel>

            <p-tabPanel header="Medios de Contacto" *ngIf="allowFormFields('contactos')">
                <ng-container *ngFor="let mcont of item.mediosContacto">
                    <ng-container [ngSwitch]="mcont.tipoContacto.id">
                        <axks-contacto-form-telefono *ngSwitchCase="TP_CONTACTO.TELEFONO_CASA" [item]="mcont"
                            [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                            [listFields]="listFields" [detailFields]="detailFields"
                            [tipoContacto]="mcont.tipoContacto">
                        </axks-contacto-form-telefono>

                        <axks-contacto-form *ngSwitchCase="TP_CONTACTO.CORREO_ELECTRONICO_EMPRESARIAL" [item]="mcont"
                            [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                            [listFields]="listFields" [detailFields]="detailFields"
                            [tipoContacto]="mcont.tipoContacto">
                        </axks-contacto-form>

                        <axks-contacto-form-telefono *ngSwitchCase="TP_CONTACTO.TELEFONO_MOVIL" [item]="mcont"
                            [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                            [listFields]="listFields" [detailFields]="detailFields"
                            [tipoContacto]="mcont.tipoContacto">
                        </axks-contacto-form-telefono>

                        <axks-contacto-form *ngSwitchCase="TP_CONTACTO.CORREO_ELECTRONICO_PERSONAL" [item]="mcont"
                            [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                            [listFields]="listFields" [detailFields]="detailFields"
                            [tipoContacto]="mcont.tipoContacto">
                        </axks-contacto-form>

                        <axks-contacto-form *ngSwitchDefault [item]="mcont" [operationMode]="operationMode"
                            [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                            [detailFields]="detailFields">
                        </axks-contacto-form>
                    </ng-container>
                </ng-container>
            </p-tabPanel>

            <p-tabPanel header="Contrato" *ngIf="allowFormFields('contrato')">
                <ng-container *ngIf="item.contrato && item.sueldo; else elseTemplate">
                    <axks-contrato-emp-form [item]="item" [infonavit]="item.infonavit"
                        (infonavitChange)="onInfonavitChange($event)" [operationMode]="operationMode"
                        [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                        [detailFields]="detailFields">
                    </axks-contrato-emp-form>
                </ng-container>
                <ng-template #elseTemplate>
                    <div>
                        <h3>Sin contrato y/o sueldo activo</h3>
                    </div>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Organigrama" *ngIf="allowFormFields('puesto')">
                <axks-organigrama-emp-form [item]="item" [operationMode]="operationMode" [formFields]="formFields"
                    [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
                </axks-organigrama-emp-form>
            </p-tabPanel>

            <p-tabPanel header="Cuenta">
                <ng-container *ngIf="item.datosBancarios && item.datosBancarios.length > 0 else elseCuentasBancarias">
                    <ng-container *ngFor="let db of item.datosBancarios">
                        <axks-cuenta-form [item]="db" [operationMode]="operationMode" [formFields]="formFields"
                            [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
                        </axks-cuenta-form>
                    </ng-container>
                </ng-container>
                <ng-template #elseCuentasBancarias>
                    <div>
                        <h3>Sin cuentas bancarias</h3>
                    </div>
                </ng-template>
            </p-tabPanel>

        </p-tabView>
    </div>
</div>
<p-toast></p-toast>