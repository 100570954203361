import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';
import { ContactoReg } from 'src/app/api/contacto/contacto-reg';
import { ContratacionReg } from 'src/app/api/contratacion/contratacion-reg';
import { CuentaEmpleado } from 'src/app/api/cuenta/cuenta-empleado';
import { DireccionDet } from 'src/app/api/direccion/direccion-det';
import { ContratoSteps } from 'src/app/api/enums/contrato-steps.enum';
import { ContratacionPsService } from '../../../procs/contratacion/contratacion-ps.service';
import { Contratacion } from 'src/app/api/contratacion/contratacion';
import { PreRenovacionReg } from 'src/app/api/pre-renovacion/pre-renovacion-reg';
import { TipoContacto } from '../../../enums/tipo-contacto.enum';

@Component({
  selector: 'axks-contrato-wizard',
  templateUrl: './contrato-wizard.component.html',
  styleUrls: ['./contrato-wizard.component.scss'],
  providers: [OperationScreenModeService]
})
export class ContratoWizardComponent extends BaseView implements OnInit, AfterContentInit {

  @Input() activeIndex: number = 0;
  @Input() item: ContratacionReg = {};
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() esRenovacion: boolean = false;
  @Input() prerenovacion: PreRenovacionReg;

  botoninicio: boolean;
  steps: MenuItem[];
  dir: DireccionDet = {};

  TP_CONTACTO = TipoContacto;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    protected router: Router,
    private clientContratacion: ContratacionPsService) {

    super(screenModeService, deviceService, messageService, sessProvider);
  }
  ngAfterContentInit(): void {
    window.scroll(0, 0)
  }

  ngOnInit(): void {
    this.initContrato();
    this.steps = [
      { label: 'Datos Personales', command: () => function () { this.goStep(ContratoSteps.personales) } },
      { label: 'Dirección', command: () => function () { this.goStep(ContratoSteps.direccion) } },
      { label: 'Medios de contacto', command: () => function () { this.goStep(ContratoSteps.contacto) } },
      { label: 'Contrato', command: () => function () { this.goStep(ContratoSteps.contacto) } },
      { label: 'Organigrama', command: () => function () { this.goStep(ContratoSteps.organigrama) } },
      { label: 'Datos bancarios', command: () => function () { this.goStep(ContratoSteps.datosBancarios) } },
      { label: 'Finalizar', command: () => function () { this.goStep(ContratoSteps.finalizar) } }
    ] as MenuItem[];

    window.scroll(0, 0);
  }

  getHeaderMobile(): string {
    switch (this.activeIndex) {
      case 0: // Personas
        return "Datos Personales";
      case 1:
        return "Dirección";
      case 2:
        return "Medios de contacto";
      case 3:
        return "Contrato";
      case 4:
        return "Organigrama";
      case 5:
        return "Datos bancarios";
      case 6:
        return "Finalizar";

    }
  }

  initContrato() {
    this.dir = {};

    if (this.esRenovacion) {
      try {
        this.dir = this.item.direcciones[0];
        if (!this.item.cuentaBancoEmpleado) {
          this.item.cuentaBancoEmpleado = {
            //idBancoReceptor: {},
            //idTipoCuenta: {},
            activa: true
          }
        }

      } catch (e) {
        this.dir = {};
      }
    } else {
      this.item.personales = {
        nacionalidad: 'Mexicana'
      };
      if (this.item.mediosContacto) {
        let correoPersonal = {
          idTipoContacto: TipoContacto.CORREO_ELECTRONICO_PERSONAL
        } as ContactoReg;
        this.item.mediosContacto.push(correoPersonal);

        let telMovil = {
          idTipoContacto: TipoContacto.TELEFONO_MOVIL
        } as ContactoReg;
        this.item.mediosContacto.push(telMovil);

      }
      this.item.cuentaBancoEmpleado = {
        idBancoReceptor: {},
        // idTipoCuenta: {},
        key: {},
        activa: true
      } as CuentaEmpleado;
    }

  }

  goStep(step: number) {

    switch (step) {
      case ContratoSteps.personales:
      case ContratoSteps.direccion:
      case ContratoSteps.contacto:
      case ContratoSteps.contrato:
      case ContratoSteps.organigrama:
      case ContratoSteps.datosBancarios:
      case ContratoSteps.finalizar:
        this.activeIndex = step;
        break;
    }
  }

  nextPage() {
    this.goStep(this.activeIndex + 1);
  }

  backPage() {
    this.goStep(this.activeIndex - 1);
  }

  showNext() {
    return this.activeIndex < ContratoSteps.finalizar ? true : false;
  }

  showBack() {
    return this.activeIndex > ContratoSteps.personales ? true : false;
  }

  showSave() {
    return (this.activeIndex == ContratoSteps.finalizar) ? true : false;
  }

  save(event) {
    let cont = this.item.contrato;
    this.item.idPeriodoPago = cont.idPeriodoPago;
    this.item.sueldo = cont.sueldo;
    this.item.sueldoDiario = cont.sueldoDiario;
    this.item.imss = cont.imss;

    if (this.validaForm(this.item)) {

      let profile = this.getProfile() as ProfileC;
      let resultContratacion = {} as Contratacion;
      this.showProgress = true;
      this.clientContratacion.register(this.item).subscribe(
        (data) => {
          resultContratacion = data;
          this.successMessage("Empleado registrado", "Se registro correctamente el nuevo empleado");
          this.showProgress = false;
          setTimeout(() => {
            this.router.navigate(["empleado"], {
              queryParams: {
                "e": profile.empresa,
                "a": profile.aplicacion,
                "s": profile.sesion,
                "newEmp": resultContratacion.empleado.id
              }
            });
          }, 2000);
        },
        (error) => {
          this.showProgress = false;
          this.errorMessage("Error de contratación", error.error.message);
        }
      );
    } else {
      this.errorMessage("Requeridos", "no se informaron los campos requeridos");
    }
  }

  validaForm(formItem: ContratacionReg): boolean {

    if (!formItem.personales.nombre) {
      return false;
    }
    if (!formItem.personales.apellidoPaterno) {
      return false;
    }
    if (!formItem.personales.apellidoMaterno) {
      return false;
    }
    if (!formItem.personales.genero) {
      return false;
    }
    if (!formItem.personales.fechaNacimiento) {
      return false;
    }
    if (!formItem.personales.curp) {
      return false;
    }
    if (!formItem.personales.estadoCivil) {
      return false;
    }
    if (!formItem.personales.nss) {
      return false;
    }
    if (!formItem.mediosContacto) {
      return false;
    }
    if (!formItem.contrato.idTipoContrato) {
      return false;
    }
    if (!formItem.contrato.fecContratacion) {
      return false;
    }
    if (!formItem.contrato.idPeriodoPago) {
      return false;
    }
    if (!formItem.contrato.sueldo) {
      return false;
    }
    if (!formItem.contrato.sueldoDiario) {
      return false;
    }
    if (!formItem.cuentaBancoEmpleado.numeroCuenta || formItem.cuentaBancoEmpleado.numeroCuenta.trim() == "") {
      return false;
    }
    if (!formItem.cuentaBancoEmpleado.idBancoReceptor.id) {
      return false;
    }
    if (!formItem.cuentaBancoEmpleado.idTipoCuenta.id) {
      return false;
    }
    else {
      return true;
    }
  }

  cancel(event) {
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.SEARCH);
    if (!this.esRenovacion) {
      this.router.navigate(["empleado"], {
        queryParams: {
          "e": profile.empresa,
          "a": profile.aplicacion,
          "s": profile.sesion
        }
      });
    }
    else {
      this.router.navigate(["renovacion-rh"], {
        queryParams: {
          "e": profile.empresa,
          "a": profile.aplicacion,
          "s": profile.sesion
        }
      });
    }
  }

  onDirChange(event) {
    this.dir = event as DireccionDet;
    this.item.direcciones[0] = {
      id: this.dir.id,
      calle: this.dir.calle,
      numExt: this.dir.numExt,
      numInt: this.dir.numInt
    };
  }

  onRenovDir($event) {
  }

  onCuentaBancoChanges(event) {
    this.item.cuentaBancoEmpleado = event;
  }

  onRfcChange(event) {
    this.item.rfc = event;
  }

  onInfonavitChange(event) {
    this.item.infonavit = event;
  }
}
