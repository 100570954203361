<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <div class="p-grid">
            <div class="p-col-8">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="dp-year" [(ngModel)]="anio" [autoDisplayFirst]="false" [options]="rangeYears"
                        optionLabel="label" optionValue="label" [style]="{'width':'100%'}"></p-dropdown>
                    <label for="dp-year">Año</label>
                </span>
            </div>
            <div class="p-col-4">
                <br>
                <button pButton type="button" label="Buscar" icon="pi pi-search" (click)="initCargaReportes()"></button>
            </div>
        </div>
    </div>

    <div class="p-sm-0 p-md-6 p-lg-8 p-xl-9 p-col-0"></div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <p-table #tc [value]="cuotasEmpleados" [responsive]="true"
            [resizableColumns]="true" [paginator]="true" [showCurrentPageReport]="true" [rows]="25"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
            [globalFilterFields]="['nombreEmpleado','nombreLider', 'monto']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">Cuota de empleados temporales, periodo {{anio}}</span>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tc.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idEmpleado">Núm. Empleado<p-sortIcon field="idEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreEmpleado">Nombre Empleado<p-sortIcon field="nombreEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreLider">Lider<p-sortIcon field="nombreLider"></p-sortIcon>
                    </th>
                    <th pSortableColumn="monto">Monto a cubrir<p-sortIcon field="monto"></p-sortIcon>
                    </th>
                    <th pSortableColumn="metaActual">Meta actual<p-sortIcon field="metaActual"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        <span class="p-column-title">Núm. Empleado</span>
                        {{value.idEmpleado}}
                    </td>
                    <td>
                        <span class="p-column-title">Nombre Empleado</span>
                        {{value.nombreEmpleado}}
                    </td>
                    <td>
                        <span class="p-column-title">Lider</span>
                        {{value.nombreLider}}
                    </td>
                    <td>
                        <span class="p-column-title">Monto a cubrir</span>
                        {{value.monto | currency: 'MXN':'$'}}
                    </td>
                    <td>
                        <span class="p-column-title">Meta actual</span>
                        {{value.metaActual | currency: 'MXN':'$'}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-toast></p-toast>