<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('nombre')">
        <span class="p-float-label">
            <input required="true" requiredMessage="The input must not be empty" id="fl-nmprs" type="text" pInputText
                [pKeyFilter]=Alphabetic [(ngModel)]="item.nombre">
            <label for="fl-coment" class="label-full"><span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">*</span>
                Nombre</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('apellidoPaterno')">
        <span class="p-float-label">
            <input id="fl-appa" type="text" pInputText [pKeyFilter]=Alphabetic [(ngModel)]="item.apellidoPaterno">
            <label for="fl-coment" class="label-full"><span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">*</span> Apellido
                paterno</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('apellidoMaterno')">
        <span class="p-float-label">
            <input id="fl-apma" type="text" pInputText [pKeyFilter]=Alphabetic [(ngModel)]="item.apellidoMaterno">
            <label for="fl-coment" class="label-full"><span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">*</span> Apellido
                materno</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('genero')">
        <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Género</label>
        <br><br>
        &nbsp; <p-radioButton name="genero" value="M" [(ngModel)]="item.genero"></p-radioButton>
        &nbsp; <label>Masculino</label>
        &nbsp;
        &nbsp; <p-radioButton name="genero" value="F" [(ngModel)]="item.genero"></p-radioButton>
        &nbsp; <label>Femenino</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('curp')">
        <span class="p-float-label">
            <input id="fl-curp" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.curp">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> CURP</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaNacimiento')">
        <span class="p-float-label">
            <p-calendar id="fl-fecn" [(ngModel)]="item.fechaNacimiento" dateFormat="yy-mm-dd" [yearNavigator]="true"
                [yearRange]="yearRange" appendTo="body" [monthNavigator]="true" dataType="string" appendTo="body">
            </p-calendar>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Fecha de nacimiento</label>
        </span>

    </div>


    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('estadoCivil')">
        <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Estado civil</label>:
        <br><br>
        &nbsp; <p-radioButton name="edoCivil" value="S" [(ngModel)]="item.estadoCivil"></p-radioButton>
        &nbsp; <label>Soltero/a</label> &nbsp;
        &nbsp; <p-radioButton name="edoCivil" value="C" [(ngModel)]="item.estadoCivil"></p-radioButton>
        &nbsp; <label>Casado/a</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('nss')">
        <span class="p-float-label">
            <input id="fl-nss" type="text" pInputText pKeyFilter="num" [(ngModel)]="item.nss">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> NSS</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('rfc')">
        <span class="p-float-label">
            <input id="fl-rfc" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="rfc"
                (change)="funcionRfc()">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> RFC</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('nacionalidad')">
        <span class="p-float-label">
            <input id="fl-nac" type="text" pInputText [pKeyFilter]=Alphabetic [(ngModel)]="item.nacionalidad">
            <label for="fl-nac">Nacionalidad</label>
        </span>
    </div>

    <ng-container *ngIf="item.nacionalidad!='Mexicana'">
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('numDocMigratorio')">
            <span class="p-float-label">
                <input id="fl-docmig" type="text" pInputText [pKeyFilter]=Alphanumeric
                    [(ngModel)]="item.numDocMigratorio">
                <label for="fl-docmig" class="label-full">N° de documento migratorio</label>
            </span>
        </div>
    </ng-container>
</div>
<p-toast></p-toast>