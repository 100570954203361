<div class="p-grid">
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(1)">
            <div class="div-center">
                <fa-icon [icon]="faCalendarPlus" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Registro de asistencias</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(2)">
            <div class="div-center">
                <fa-icon [icon]="faCalendarCheck" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Asistencias por revisar</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(3)">
            <div class="div-center">
                <fa-icon [icon]="faUsersCog" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Colaboradores en checador</span>
            </div>
        </p-card>
    </div>
</div>