import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.uat';

@Injectable({
  providedIn: 'root'
})
export class DireccionRestcService extends ARestClient{
  

  constructor(client: HttpClient) { 
    super(client);
  }

  getBaseEndpoint(): string {
    return environment.msDireccion+"/";
  }
  processBody(res: any) {
    return res || {};
  }

  
}
