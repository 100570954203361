import { Injectable } from '@angular/core';
import { Empleado } from 'src/app/api/empleado';
import { BaseProcess, BaseService, Message } from '@axks/components';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { EmpleadoMin } from '../../api/empleado/empleado-min';
import { EmpleadoFilter } from '../../api/empleado/empleado-filter';
import { EmpleadoMinVw } from 'src/app/api/empleado/empleado';


@Injectable({
  providedIn: 'root'
})
export class EmpleadoPsService extends BaseProcess<EmpleadoRestcService> {

  client: EmpleadoRestcService;

  constructor(httpClient: HttpClient, crest: EmpleadoRestcService) {
    super(httpClient, crest);
    this.client = crest;
  }

  find<EmpleadoFilter, EmpleadoMin>(filter: Empleado): Observable<EmpleadoMin[]> {

    let empFilter = {} as EmpleadoFilter;
    empFilter["nombre"] = filter["personales"].nombre;
    empFilter["apellidoPaterno"] = filter["personales"].apellidoPaterno;
    empFilter["apellidoMaterno"] = filter["personales"].apellidoMaterno;

    return super.find(empFilter);

  }

  searchWithSwitch(item: any, empsInactivos: boolean): Observable<EmpleadoMin[]> {
    let filtro = {
      nombre: item.personales.nombre,
      apellidoPaterno: item.personales.apellidoPaterno,
      apellidoMaterno: item.personales.apellidoMaterno
    } as EmpleadoFilter;
    return this.client.searchWithSwitch(filtro, empsInactivos);
  }

}