<div class="p-grid frm-part">
    <!-- {{item | json}} -->
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('empleado')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idEmpleado" [options]="empleadosActivos"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" [filter]="true"
                filterBy="nombreCompleto" appendTo="body" [baseZIndex]="20000"></p-dropdown>
            <label for="fl-coment">Colaborador</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('liderSolicitante')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idLiderSolicitante" [options]="lideres" optionLabel="nombre"
                optionValue="idEmpleado" [autoDisplayFirst]="false" [filter]="true" filterBy="nombre" appendTo="body"
                [baseZIndex]="20000"></p-dropdown>
            <label for="fl-coment">Líder Solicitante</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('liderAutoriza')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idLiderAutoriza" [options]="lideres" optionLabel="nombre"
                optionValue="idEmpleado" [autoDisplayFirst]="false" [filter]="true" filterBy="nombre" appendTo="body"
                [baseZIndex]="20000"></p-dropdown>
            <label for="fl-coment">Líder que autoriza</label>
        </span>
    </div>
</div>