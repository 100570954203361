import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area';
import { AreaPsService } from 'src/app/procs/area/area-ps.service';

@Component({
  selector: 'axks-area-cat',
  templateUrl: './area-cat.component.html',
  styleUrls: ['./area-cat.component.scss']
})
export class AreaCatComponent extends BaseView implements OnInit{

  catalog: Area[];

  @Input() value?: Area;

  @Output() onValueChange = new EventEmitter<Area>();
  

  constructor(
    screenModeService: OperationScreenModeService, 
    deviceService: DeviceDetectorService, 
    messageService: MessageService, 
    sessionProv: SessionClientProvider,
    private areaps: AreaPsService) { 
    super(screenModeService, deviceService, messageService, sessionProv);
  }

  ngOnInit(): void {
    let prof = this.profile;

    let filter ={ idEmpresa:prof.empresa, empresa:{id: prof.empresa} } as Area;
    this.areaps.find(filter).subscribe(
      (data) =>{
        this.catalog = data;
      }
    );
  }

  onChange(event){

    // REcuperas el valor del catàlogo


    let arean = {
      id: this.value.id
    } as Area;

    let are = this.catalog.find(ar => (ar.id == this.value.id));

    if(are && are.id === this.value.id){
      arean.area = are.area
    }

    this.onValueChange.emit(arean);

 
  }

}
