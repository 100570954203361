import { Injectable } from '@angular/core';
import { BaseProcess, BaseService, Message, SessionClientProvider } from '@axks/components';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TipoContrato } from '../../api/tipo-contrato';
import { TipoContratoRestcService } from 'src/app/client/tipo-contrato/tipo-contrato-restc.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';


@Injectable({
  providedIn: 'root'
})
export class TipoContratoPsService extends BaseProcess<TipoContratoRestcService> {

  constructor(httpClient: HttpClient,
    crest: TipoContratoRestcService){
    super(httpClient, crest);
  }

  /* constructor(httpClient: HttpClient,
    private crest: TipoContratoRestcService) {
      super(httpClient, crest)
    //this.profile = sessProvider.getProfile();
  } */
/* 

  find(filter: TipoContrato): Observable<TipoContrato[]> {
    let empresaId = this.profile.empresa;
    filter.idEmpresa = empresaId;
    let res = this.crest.search(filter) as Observable<any>;
    return res;
  }
  detail(item: number): Observable<TipoContrato> {
    let res = this.crest.detail(item) as Observable<any>;
    res.subscribe((data) => {
      console.debug(data)
    })
    return res;
  }
  register(item: TipoContrato): Observable<TipoContrato> {
    let empresaId = this.profile.empresa;
    item.idEmpresa = empresaId;
    let res = this.crest.addElement(item);

    return res;
  }
  edit(item: TipoContrato): Observable<TipoContrato> {
    let empresaId = this.profile.empresa;
    item.idEmpresa = empresaId;

    let res = this.crest.modifyElement(item) as Observable<any>;

    return res;
  }
  delete(item: number): Observable<Message> {
    let res = this.crest.removeElement(item) as Observable<any>;
    res.subscribe((data) => {
      console.debug(data)
    })
    return res;
  } */


}
