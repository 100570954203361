import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreRenovacionRestcService {
  
  constructor(private httpClient: HttpClient) { }

  listaRenovLider(valore): Observable<any>{
    let path = environment. msDashboardRhRev;

    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.post(path, valore, {headers});
  }
}
