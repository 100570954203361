import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoDescuentoRestcService } from 'src/app/client/tipo-descuento/tipo-descuento-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoDescuentoPsService extends BaseProcess<TipoDescuentoRestcService>{

  constructor(httpClient: HttpClient, crest: TipoDescuentoRestcService) {
    super(httpClient, crest);
  }
}
