import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { Checador } from 'src/app/api/checador/checador';
import { ChecadorRestcService } from 'src/app/client/checador/checador-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ChecadorPsService extends BaseProcess<ChecadorRestcService> {

  client: ChecadorRestcService;

  constructor(httpClient: HttpClient, crest: ChecadorRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  cargarExcel(file: any, idUserEmp: number): Observable<any> {
    return this.client.cargarExcel(file, idUserEmp);
  }

  porRevisar(fecInicio: string, fecFinal: string): Observable<Checador[]> {
    return this.client.porRevisar(fecInicio, fecFinal);
  }

  generarDescuento(item: any): Observable<Message> {
    return this.client.generarDescuento(item);
  }

  validarAsistencia(idChecador: number): Observable<Message> {
    return this.client.validarAsistencia(idChecador);
  }
}
