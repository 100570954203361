import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area/area';
import { Puesto } from 'src/app/api/puesto/puesto';

@Component({
  selector: 'axks-puesto-form',
  templateUrl: './puesto-form.component.html',
  styleUrls: ['./puesto-form.component.scss']
})
export class PuestoFormComponent extends BaseView implements OnInit {

  @Input() item: Puesto = {};
  @Input() areas: Area[];

  area: Area = {};
  OP_MODE = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        area: {}
      } as Puesto;
    }

    if (this.item && this.item.area) {
      this.area = this.item.area;
    }

  }

  onValueChange(event) {
    this.item.idAreaLaboral = event.id;
  }


}
