<div class="progress container" *ngIf="!loadContent ||showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <div style="padding: 0.5rem;">
        <!-- {{indexMenu}} -->
        <!-- <div class="div-barra"> -->
        <p-toolbar class="p-shadow-2 titulo-general">
            <div class="p-toolbar-group-left">
                <div>
                    <h3>Gestión de colaboradores. <ng-container
                            *ngIf="tituloReporte && tituloReporte.trim() != ''">{{tituloReporte}}</ng-container></h3>
                </div>
            </div>
            <div class="p-toolbar-group-right">
                <button *ngIf="indexMenu" pButton type="button" label="Regresar" icon="pi pi-arrow-circle-left"
                    (click)="accederMenu(null)"></button>
            </div>
        </p-toolbar>
        <!-- </div> -->

        <div class="p-shadow-2 div-contenedor">

            <ng-container [ngSwitch]="indexMenu">
                <axks-banca-liberacion-recursos *ngSwitchCase="1"
                    (tituloReportes)="setTituloReportes($event)"></axks-banca-liberacion-recursos>

                <axks-banca-no-asignados *ngSwitchCase="2"
                    (tituloReportes)="setTituloReportes($event)"></axks-banca-no-asignados>

                <axks-solicitudes-apoyo *ngSwitchCase="3"
                    (tituloReportes)="setTituloReportes($event)"></axks-solicitudes-apoyo>

                <axks-solicitudes-por-autorizar *ngSwitchCase="4"
                    (tituloReportes)="setTituloReportes($event)"></axks-solicitudes-por-autorizar>

                <axks-historico-solicitudes-apoyo *ngSwitchCase="5"
                    (tituloReportes)="setTituloReportes($event)"></axks-historico-solicitudes-apoyo>

                <axks-banca-menu *ngSwitchDefault (numMenu)="accederMenu($event)"
                    (tituloReportes)="setTituloReportes($event)"></axks-banca-menu>
            </ng-container>


        </div>
    </div>
</ng-container>