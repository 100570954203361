<p-dialog header="Empleado. Foto" [(visible)]="showDialog" [modal]="true" [baseZIndex]="10000"
    [breakpoints]="{'1200px': '60vw', '992px': '80vw', '768px': '90vw', '576px': '100vw'}" [draggable]="false"
    (onHide)="ocultarVentana()">

    <div class="contenedor-progress-spinner" *ngIf="!myLoadContent || showProgress">
        <p-progressSpinner strokeWidth="8"></p-progressSpinner>
    </div>

    <div *ngIf="myLoadContent">
        <div *ngIf="myLoadContent == true && showProgress == false && fotoExistente == false" class="contenedor-upload">
            <p-fileUpload mode="advanced" name="fotoNombre" accept="image/*" fileLimit="1" maxFileSize="20000000"
                (uploadHandler)="subirFoto($event)" customUpload="true" [auto]="false" chooseLabel="Cargar foto"
                uploadLabel="Subir foto" [showCancelButton]="true" [showUploadButton]="true" cancelLabel="Eliminar foto"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="content">
                    <ul *ngIf="fotosCargadas.length">
                        <li *ngFor="let file of fotosCargadas">{{file.name}} - {{file.size}} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </div>
        <div *ngIf="myLoadContent == true && showProgress == false && fotoExistente == true" class="contenedor-imagen">
            <img [src]="foto" class="responsivo">
        </div>
    </div>

    <p-toast></p-toast>
</p-dialog>