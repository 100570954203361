import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { DireccionRestcService } from '../../client/direccion/direccion-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DireccionPsService extends BaseProcess<DireccionRestcService>{

  constructor(httpClient: HttpClient,
     crest: DireccionRestcService) { 
    super(httpClient, crest);
  }

  
}
