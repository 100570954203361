<p-dialog header="Liquidar empleado" [(visible)]="showDialog" position="center" [modal]="true" [maximizable]="true"
    [style]="{width: '70vw', 'background-color':'#f4f4f4'}" [baseZIndex]="10000" (onHide)="ocultarDialog()"
    contentStyleClass="content-dialog"
    [breakpoints]="{'1200px': '60vw', '992px': '80vw', '768px': '90vw', '576px': '100vw'}">

    <div class="contenedor-progress-spinner" *ngIf="showProgress">
        <p-progressSpinner strokeWidth="8"></p-progressSpinner>
    </div>

    <!-- {{items | json}} -->
    <p-card *ngIf="!showProgress" [style]="{'border': 'outset 0px'}">
        <div class="p-grid frm-part">
            <div class="p-col-12">
                <br><br>
                <span class="p-float-label">
                    <p-dropdown [options]="cuentasBancarias" [(ngModel)]="item.cuenta" [style]="{'width':'100%'}"
                        optionLabel="label" [showClear]="true" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="fl-nmemp">
                        <span style="color: red; font-weight: bold;">* </span>
                        Cuenta de deposito
                    </label>
                </span>
            </div>

            <div class="p-col-12">
                <br><br>
                <span class="p-float-label">
                    <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto-liq" mode="currency" currency="MXN"
                        [style]="{'width':'100%'}">
                    </p-inputNumber>
                    <label for="fl-monto-liq">
                        <span style="color: red; font-weight: bold;">* </span>
                        Monto de liquidación
                    </label>
                </span>
            </div>

            <div class="p-col-12">
                <br><br>
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="item.fechaTermino" [baseZIndex]="20000" appendTo="body"
                        [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange" inputId="fl-fecTermino"
                        [style]="{'width':'100%'}" dataType="string" dateFormat="yy/mm/dd" [showIcon]="true">
                    </p-calendar>
                    <label for="fl-fecTermino">
                        <span style="color: red; font-weight: bold;">* </span>
                        Fecha termino de contrato
                    </label>
                </span>
            </div>

            <div class="p-col-12">
                <br><br>
                <span class="p-float-label">
                    <input id="fl-tp-cuenta" type="text" pInputText [(ngModel)]="item.tipoCuenta"
                        [style]="{'width':'100%'}"
                        pTooltip="Si no se cuenta con la informacion, favor de pedir los datos al area de finanzas"
                        tooltipZIndex="20000">
                    <label for="fl-tp-cuenta">
                        <span style="color: red; font-weight: bold;">* </span>
                        Tipo de cuenta
                    </label>
                </span>
            </div>

            <div class="p-col-12">
                <br><br>
                <span class="p-float-label">
                    <input id="fl-plz-banx" type="text" pInputText [(ngModel)]="item.plazaBanxico"
                        [style]="{'width':'100%'}"
                        pTooltip="Si no se cuenta con la informacion, favor de pedir los datos al area de finanzas"
                        tooltipZIndex="20000">
                    <label for="fl-plz-banx">
                        <span style="color: red; font-weight: bold;">* </span>
                        Plaza Banxico
                    </label>
                </span>
            </div>

            <div class="p-col-12">
                <br>
                <br>
                <span style="color:red">*</span>&nbsp;
                <span style="color: red; font-size: 0.7rem;"> Campos requeridos</span>
            </div>
        </div>
    </p-card>

    <ng-template pTemplate="footer">
        <p-button type="button" (click)="guardar()" label="Liquidar" icon="pi pi-save"></p-button>
    </ng-template>

    <p-toast></p-toast>
</p-dialog>