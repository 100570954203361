import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { CuotaTemporales } from 'src/app/api/reportes/reportes';
import { ReportesRestcService } from 'src/app/client/reportes/reportes-restc.service';

@Component({
  selector: 'axks-reportes-temporales',
  templateUrl: './reportes-temporales.component.html',
  styleUrls: ['./reportes-temporales.component.scss']
})
export class ReportesTemporalesComponent extends BaseView implements OnInit {

  anio: number;
  rangeYears: any[];
  cuotasEmpleados: CuotaTemporales[];

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private clientReportes: ReportesRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    this.initRangeYears();
    this.anio = new Date().getFullYear();

    setTimeout(() => {
      this.initCargaReportes();
    }, 500);

    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initRangeYears() {
    this.rangeYears = [];
    let currentYear = new Date().getFullYear();
    for (let itemYear = currentYear; itemYear > currentYear - 5; itemYear--) {
      this.rangeYears.push({ label: itemYear });
    }
  }

  initCargaReportes() {
    if (this.anio) {
      this.showProgress = true;
      this.clientReportes.cuotasTemporales(this.anio).subscribe(
        (data) => {
          this.cuotasEmpleados = data;
          if (this.cuotasEmpleados.length == 0) {
            this.infoMessage("Sin registros", "No hay cuotas por cubrir de empleados temporales en este periodo");
          }
          this.showProgress = false;
        },
        (error) => {
          this.errorMessage("Error", "No se pudo consultar las cuotas de empleados temporales.");
          this.showProgress = false;
        }
      );
    }
    else {
      this.infoMessage("Falta de información", "No se ha seleccionado un periodo de tiempo para consultar");
    }
  }

  configTituloReportes() {
    this.tituloReportes.emit("Cuotas Empleados Temporales");
  }

  exportExcel() {
    if (this.cuotasEmpleados.length > 0) {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.cuotasEmpleados);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Cuotas-empleados-temporales");
      });
    }
    else{
      this.infoMessage("Sin elementos","No hay elementos para exportar a excel");
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) +
        "-" + (mes < 10 ? "0" + mes : mes) +
        "-" + now.getFullYear() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

}
