import { Injectable } from '@angular/core';
import { BaseProcess, BaseService, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Puesto } from '../../api/puesto';
import { PuestoRestcService } from 'src/app/client/puesto/puesto-restc.service';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class PuestoService extends BaseProcess<PuestoRestcService>{

  constructor(httpClient: HttpClient,
    crest: PuestoRestcService,
    private messages: MessageService) { 
      super(httpClient,crest);
    }

  

}
