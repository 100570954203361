import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudApoyoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msSolicitudApoyo;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  porAutorizar(filtro: SolicitudApoyo): Observable<SolicitudApoyo[]> {
    let path = this.getBaseEndpoint() + "por-autorizar";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, filtro, { headers }) as Observable<SolicitudApoyo[]>;
  }

  autorizar(item: SolicitudApoyo, autorizacion: boolean): Observable<Message> {
    let path = this.getBaseEndpoint() + "autorizar";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("autorizacion", autorizacion.toString());

    return this.httpClient.post(path, item, { headers, params }) as Observable<Message>;
  }
}
