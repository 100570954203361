<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <br>
        <button pButton type="button" label="Buscar" icon="pi pi-search" (click)="initEmpleadosAsignados()"></button>
    </div>

    <div class="p-col-12">
        <p-table #tv [value]="empleados" [resizableColumns]="true" [paginator]="true" [showCurrentPageReport]="true"
            [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}" class="result result-list"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]" [globalFilterFields]="['nombreEmpleado']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        Colaboradores asignados
                    </span>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tv.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idEmpleado">Núm. Colaborador<p-sortIcon field="idEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreEmpleado">Colaborador<p-sortIcon field="nombreEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fecContrat">Fecha de asignación<p-sortIcon field="fecIniAsigna"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fechaExpiracion">Fecha fin de asignación<p-sortIcon
                            field="fecFinAsigna"></p-sortIcon>
                    </th>
                    <th>Liberación de colaborador</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        {{value.idEmpleado}}
                    </td>
                    <td>
                        {{value.nombreEmpleado}}
                    </td>
                    <td>
                        <ng-container *ngIf="value.fecIniAsigna; else elseFecIniAsgina">
                            {{value.fecIniAsigna | date: "dd 'de' MMMM 'de' yyyy"}}
                        </ng-container>
                        <ng-template #elseFecIniAsgina>
                            Indefinido
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="value.fecFinAsigna; else elseFecFinAsgina">
                            {{value.fecFinAsigna | date: "dd 'de' MMMM 'de' yyyy"}}
                        </ng-container>
                        <ng-template #elseFecFinAsgina>
                            Indefinido
                        </ng-template>
                    </td>
                    <td>
                        <div>
                            <p-button icon="fa fa-user-times" styleClass="p-button-danger p-button-rounded"
                                pTooltip="El colaborador dejara de estar a su cargo, dejandolo disponible para otros lideres"
                                (onClick)="liberarEmpleado(value)"></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-toast></p-toast>