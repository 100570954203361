import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ChecadorPsService } from 'src/app/procs/checador/checador-ps.service';
import { Checador } from 'src/app/api/checador/checador';

@Component({
  selector: 'axks-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.scss']
})
export class AsistenciaComponent extends BaseMainComponent<ChecadorPsService> implements OnInit{

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProv: SessionClientProvider,
    private clientCheck: ChecadorPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProv);
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idEmpleado: {},
        idEmpLider: {}
      } as Checador,
      minimumItem: {
        idEmpleado: {},
        idEmpLider: {}
      } as Checador,
      registerItem: {
        idEmpleado: {},
        idEmpLider: {}
      } as Checador
    } as ComponentItem;
    return empty;
  }
  getService(): ChecadorPsService {
    return this.clientCheck;
  }
  getColListDefinition(): ColumnDefinition[] {
    let columns = [
      { field: 'id', header: 'Núm. Registro', inAll: true },
      { field: 'idEmpleado.id', header: 'Núm. Empleado', inDetail: true },
      { field: 'idEmpleado.nombreCompleto', header: 'Empleado', inAll: true },
      { field: 'fecEntrada', header: 'Fecha y hora de entrada', inAll: true, formatFunction: this.formatoFecha },
      { field: 'fecSalida', header: 'Fecha y hora de salida', inAll: true, formatFunction: this.formatoFecha },
      { field: 'sucursal', header: 'Lugar de trabajo', inAll: true },
      { field: 'fecAsisLider', header: 'Fecha de registro', inDetail: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return columns;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return '';
  }

  configTituloReportes() {
    this.tituloReportes.emit('Asistencias');
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ['excel'];
    this.formSearch = ['empleado'];
  }

  formatoFecha(value: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy, hh:mm:ss a", "GMT-0" );
  }

  catchShowProgressForm(event) {
    this.showProgress = event;
  }

  reloadMain(event) {
    this.screenModeService.screenMode.next(OperationMode.SEARCH);
    setTimeout(() => {
      super.find(this.item.item);
    }, 500);
  }

}
