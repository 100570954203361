import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area/area';
import { AreaReg } from 'src/app/api/area/area-reg';
import { AreaPsService } from 'src/app/procs/area/area-ps.service';

@Component({
  selector: 'axks-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
  providers: [MessageService]
})
export class AreaComponent extends BaseMainComponent<AreaPsService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    private areaps: AreaPsService,
    protected sessProvider: SessionClientProvider
  ) {
    super(deviceService, messageService, screenModeService, sessProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let profile = this.getProfile() as ProfileC;
    let empresa = profile.empresa;

    let empty = {
      item: { idEmpresa: empresa } as Area,
      registerItem: { idEmpresa: empresa } as AreaReg,
      minimumItem: { idEmpresa: empresa } as Area
    } as ComponentItem;

    return empty;
  }

  getService(): AreaPsService {
    return this.areaps;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'id', inAll: true },
      { header: 'Area', field: 'area', inAll: true },
      { header: 'Descripción', field: 'descripcion', inAll: true },
    ] as ColumnDefinition[];

  }

  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }
  
  getComponentTitle(): string {
    return "Área de trabajo";
  }

}
