<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('area')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.puesto.area.id" [options]="catArea" optionLabel="area"
                optionValue="id" [filter]="true" filterBy="area" [autoDisplayFirst]="false" autoWidth="false"
                appendTo="body" (onChange)="setArea($event)">
            </p-dropdown>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Área</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('puesto') && item.puesto.area.id">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.puesto.id" [options]="catPuesto" optionLabel="puesto"
                optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="true" itemSize="10" [filter]="true"
                filterBy="puesto" autoWidth="false" appendTo="body"></p-dropdown>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Puesto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('lider')">
        <p-checkbox [(ngModel)]="item.lider" binary="true" inputId="lider"></p-checkbox>
        &nbsp; <label for="lider" class="form-field-label">Es lider</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('jefeInmediato')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.jefeInmediato.idEmpleado" [options]="catLider"
                optionLabel="nombre" optionValue="idEmpleado" [autoDisplayFirst]="false" [virtualScroll]="true"
                itemSize="10" [filter]="true" filterBy="nombre" autoWidth="false" [style]="{'width':'100%'}"
                appendTo="body"></p-dropdown>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Jefe inmediato</label>
        </span>
    </div>

</div>
<p-toast></p-toast>