import { Component, Input, OnInit } from '@angular/core';
import { BaseMessages, BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Area } from 'src/app/api/area';
import { ContratacionReg } from 'src/app/api/contratacion/contratacion-reg';
import { LiderCat } from 'src/app/api/empleado/lider-cat';
import { CatLiderPsService } from 'src/app/procs/empleado/cat-lider-ps.service';
import { RolPsService } from 'src/app/procs/usuario/rol-ps.service';
import { Rol, RolItem } from 'src/app/api/usuario/rol';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Puesto } from '../../../api/puesto';

@Component({
  selector: 'axks-organigrama-form',
  templateUrl: './organigrama-form.component.html',
  styleUrls: ['./organigrama-form.component.scss']
})
export class OrganigramaFormComponent extends BaseView implements OnInit {

  @Input() item: ContratacionReg = {} as ContratacionReg;

  selArea: Area = {};
  yearRange: any;
  catLider: LiderCat[];
  catSisRoles: Rol[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private catLiderPs: CatLiderPsService,
    private rolPs: RolPsService,
    protected sessProvider: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {} as ContratacionReg
    }

    this.initDates();
    let profile = this.getProfile() as ProfileC;
    let idEmpresa = profile.empresa;
    this.initCatLider(idEmpresa);
    this.initCatRolesSistema(idEmpresa);
    try {
      this.selArea.id = this.item.idArea;
    } catch (e) { }

  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 1) + ':' + (year + 1);
  }

  initCatLider(idEmpresa: number) {
    this.catLiderPs.getCatalogoLider(idEmpresa).subscribe(data => {
      this.catLider = data as LiderCat[];
    });
  }

  initCatRolesSistema(idEmpresa: number) {
    let filtro = {
      esDelGrupo: true,
      idEmpresa: idEmpresa
    } as RolItem;

    this.rolPs.find(filtro).subscribe(data => {
      this.catSisRoles = data as Rol[];
    });
  }

  onValueChange(event) {
    this.item.idArea = event.id;
    this.selArea = { idArea: event } as Area;
    this.item["area"] = event.area;
  }

  onValueChangePuesto(event) {
    this.item.idPuesto = event.id
    this.item["puesto"] = event.puesto;
  }

  onValueChangeJefe(event) {
    this.item["jefeInmediato"] = event.originalEvent.target.innerText;
  }

  onValueChangeRol(event) {
    this.item["rol"] = event.originalEvent.target.innerText;
  }

}
