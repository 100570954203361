import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { Organigrama } from 'src/app/api/organigrama/organigrama';
import { environment } from 'src/environments/environment';
import { OrganigramaId } from '../../api/organigrama/organigrama';
import { Message } from '@axks/components';

@Injectable({
  providedIn: 'root'
})
export class OrganigramaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msOrganigrama;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  recursosPorLider(idLider: number): Observable<Organigrama[]> {
    let path = this.getBaseEndpoint() + "recursos-por-lider/" + idLider;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<Organigrama[]>;
  }

  liberarRecurso(item: Organigrama): Observable<Message> {
    let path = this.getBaseEndpoint() + "liberar-recurso";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, item, { headers }) as Observable<Message>;
  }

  detail<OrganigramaId, Organigrama>(id: OrganigramaId): Observable<Organigrama> {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, id, { headers }) as Observable<Organigrama>;
  }

  empleadosEnBanca(item: Organigrama): Observable<Organigrama[]> {
    let path = this.getBaseEndpoint() + "empleados-en-banca";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, item, { headers }) as Observable<Organigrama[]>;
  }

  asignarEmpleado(item: Organigrama, idLider: number): Observable<Message> {
    let path = this.getBaseEndpoint() + "asignar-empleado";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idLider", idLider.toString());

    return this.httpClient.post(path, item, { headers, params }) as Observable<Message>;
  }

  actual(idEmpleado: number): Observable<Organigrama> {
    let path = this.getBaseEndpoint() + "actual/" + idEmpleado;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<Organigrama>;
  }

}
