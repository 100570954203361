import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { SolicitudApoyoRestcService } from 'src/app/client/solicitud-apoyo/solicitud-apoyo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitudApoyoPsService extends BaseProcess<SolicitudApoyoRestcService> {

  client: SolicitudApoyoRestcService;

  constructor(httpClient: HttpClient, crest: SolicitudApoyoRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  porAutorizar(filtro: SolicitudApoyo): Observable<SolicitudApoyo[]> {
    return this.client.porAutorizar(filtro);
  }

  autorizar(item: SolicitudApoyo, autorizacion: boolean): Observable<Message> {
    return this.client.autorizar(item, autorizacion);
  }
}
