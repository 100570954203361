export enum ContratoSteps {
    personales,
    direccion,
    contacto,
    contrato,
    organigrama,
    datosBancarios,
    finalizar

}
