import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area';
import { PeriodoPago } from 'src/app/api/contratacion/periodo-pago';
import { TipoContrato } from 'src/app/api/enums/tipo-contrato.enum';
import { PeriodoPagoPsService } from 'src/app/procs/contratacion/periodo-pago-ps.service';
import { EmpleadoDetalle } from '../../../api/empleado/empleado-detalle';
import { PeriodoPagoEnum } from 'src/app/enums/periodo-pago.enum';

@Component({
  selector: 'axks-contrato-emp-form',
  templateUrl: './contrato-emp-form.component.html',
  styleUrls: ['./contrato-emp-form.component.scss']
})
export class ContratoEmpFormComponent extends BaseView implements OnInit {

  @Input() item: EmpleadoDetalle;
  @Input() infonavit: boolean;
  @Output() infonavitChange = new EventEmitter();
  @Input() esRenovacion: boolean = false;

  selArea: Area = {};
  selPeri: PeriodoPago = {};;
  yearRange: any;
  catPeriodopago: PeriodoPago[];
  tpContrato = TipoContrato;
  PERIODO_PAGO = PeriodoPagoEnum;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private periodoPagoPs: PeriodoPagoPsService,
    protected sessProvider: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessProvider);

  }

  ngOnInit(): void {
    this.initDates();
    this.initCatPeriodoPago();

    if (!this.item) {
      this.item = {
      } as EmpleadoDetalle;
    }

    if (this.item.contrato.fecTerminoContrato) {
      this.item.contrato.fecTerminoContrato = this.formatoFecha(this.item.contrato.fecTerminoContrato)
    }

    if (this.item.contrato.fecContratacion) {
      this.item.contrato.fecContratacion = this.formatoFecha(this.item.contrato.fecContratacion)
    }
  }

  initCatPeriodoPago() {
    this.periodoPagoPs.all().subscribe(
      (data) => {
        this.catPeriodopago = data as PeriodoPago[];
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar los periodos de pago");
      }
    );
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 5) + ':' + (year + 5);
  }

  onValueChange(event) {
    this.item.contrato.idTipoContrato = event.id;
    /* this.item.idBancoReceptor=event.id; */

    this.item["tipoContrato"] = event.tipoContrato;
    /*  this.item["bancoReceptor"]=event.bancoReceptor; */
  }

  funcionInfonavit() {
    this.infonavitChange.emit(this.infonavit);
  }

  onValueChangePeriodo(event) {
    this.item["periodoPago"] = event.originalEvent.target.innerText;
  }

  formatoFecha(value: string) {
    let d = Date.parse(value);
    return formatDate(d, 'yyyy/MM/dd', 'es-MX');
  }

  contratoIndefinido(forItem: EmpleadoDetalle) {
    if (!forItem.contrato.idTipoContrato) {
      return false;
    }
  }

}

