import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeriodoPago } from 'src/app/api/contratacion/periodo-pago';
import { TipoContacto } from 'src/app/api/tipo-contacto';
import { TipoContrato } from 'src/app/api/tipo-contrato';
import { TipoContratoPsService } from 'src/app/procs/tipo-contrato/tipo-contrato-ps.service';

@Component({
  selector: 'axks-tipo-contrato-cat',
  templateUrl: './tipo-contrato-cat.component.html',
  styleUrls: ['./tipo-contrato-cat.component.scss']
})
export class TipoContratoCatComponent implements OnInit {

  catalog: TipoContrato[];
  @Input() value?: number;

  @Input() idEmpresa: number;

  @Output() onValueChange = new EventEmitter<TipoContrato>();

  constructor(private arestps: TipoContratoPsService) { }

  ngOnInit(): void {

    let filter = {
      empresa: { id: this.idEmpresa }
    } as TipoContrato;

    this.arestps.find(filter).subscribe(
      (data) => {
        this.catalog = data as TipoContrato[];
      }
    );

  }

  onChange(event){
    let tcont = {
      id: this.value
    } as TipoContrato;

    let tcf = this.catalog.find(tc => (tc.id == this.value));

    if(tcf && tcf.id === this.value){
      tcont.tipoContrato = tcf.tipoContrato
    }

    this.onValueChange.emit(tcont);
  }
}
