<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid">
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <div class="p-grid">
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-emp" [options]="catEmpleados" [(ngModel)]="filtro.key.idEmpleado"
                        optionValue="id" optionLabel="nombreCompleto" [autoDisplayFirst]="false" [filter]="true"
                        filterBy="nombreCompleto" [style]="{'width':'90%'}" [baseZIndex]="20000" appendTo="body"
                        [showClear]="true">
                    </p-dropdown>
                    <label for="fl-emp">Empleado</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
                <br>
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="filtro.fecInicio" dateFormat="yy-mm-dd" [yearNavigator]="true"
                        [yearRange]="yearRange" [readonlyInput]="true" [monthNavigator]="true" inputId="cal-fecIni"
                        [showIcon]="true" [style]="{'width':'90%'}">
                    </p-calendar>
                    <label for="cal-fecIni">Fecha inicio</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
                <br>
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="filtro.fecFin" [monthNavigator]="true" dateFormat="yy-mm-dd"
                        [yearNavigator]="true" [yearRange]="yearRange" [readonlyInput]="true" inputId="cal-fecFin"
                        [showIcon]="true" [style]="{'width':'90%'}">
                    </p-calendar>
                    <label for="cal-fecFin">Fecha fin</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <br>
                <div class="p-d-flex p-jc-end p-mr-5">
                    <p-button label="Buscar" icon="pi pi-search" (onClick)="initCargaReportes()"></p-button>
                    &nbsp;&nbsp;
                    <p-button (onClick)="limpiarFiltros()">
                        <fa-icon [icon]="faBroom"></fa-icon>
                        <label class="p-ml-2">Limpiar</label>
                    </p-button>
                </div>
            </div>
        </div>
    </div>

    <div class="p-sm-0 p-md-6 p-lg-8 p-xl-9 p-col-0"></div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <p-table #tv [value]="items" class="result result-list" [scrollable]="true" [paginator]="true"
            [showCurrentPageReport]="true" [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]" [globalFilterFields]="['empleado']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        Ajustes de sueldo
                    </span>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tv.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idEmpleado">Núm. Empleado<p-sortIcon field="idEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="empleado">Empleado<p-sortIcon field="empleado"></p-sortIcon>
                    </th>
                    <th>Periodos</th>
                    <th>Ajuste de sueldo</th>
                    <th pSortableColumn="fecAplica">Fecha de aplicación<p-sortIcon field="fecAplica"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        {{value.key.idEmpleado}}
                    </td>
                    <td>
                        {{value.empleado}}
                    </td>
                    <td>
                        {{value.periodoAnterior}} → {{value.periodoNuevo}}
                    </td>
                    <td>
                        {{value.sueldoAnterior ? (value.sueldoAnterior | currency: "MXN": "$") : (0 | currency: "MXN":
                        "$")}} → {{value.nuevoSueldo ? (value.nuevoSueldo | currency: "MXN" : "$") : (0 | currency:
                        "MXN" : "$")}}
                    </td>
                    <td>
                        {{value.fecAplica | date: "dd 'de' MMMM 'de' yyyy"}}
                        <!-- <ng-container *ngIf="value.aniosAntiguedad == 1; else elseAnios">
                            año
                        </ng-container>
                        <ng-template #elseAnios>
                            años
                        </ng-template> -->
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-toast></p-toast>