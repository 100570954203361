import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { BancoReceptor, TipoCuenta } from 'src/app/api/cuenta/cuenta-cats';
import { CuentaEmpleado } from 'src/app/api/cuenta/cuenta-empleado';
import { CuentaCatsRestcService } from 'src/app/client/cuenta/cuenta-cats-restc.service';

@Component({
  selector: 'axks-cuenta-form',
  templateUrl: './cuenta-form.component.html',
  styleUrls: ['./cuenta-form.component.scss']
})
export class CuentaFormComponent extends BaseView implements OnInit, OnChanges {

  @Input() item: CuentaEmpleado;
  @Input() cEmp: CuentaEmpleado;

  @Output() onChange = new EventEmitter<CuentaEmpleado>();

  catBancoRec: BancoReceptor[] = [];
  catTipoCuenta: TipoCuenta[] = [];
  selBancoRec: BancoReceptor = {};
  selTipoCuenta: TipoCuenta = {};

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private catRestc: CuentaCatsRestcService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idBancoReceptor: {},
        idTipoCuenta: {},
        key: {}
      }
    }

    this.initCatalogos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChange.emit(this.item);
  }

  initCatalogos() {
    this.catRestc.catBancoReceptor().subscribe(
      data => {
        this.catBancoRec = data as BancoReceptor[];
      }
    );

    this.catRestc.catTipoCuenta().subscribe(
      data => {
        this.catTipoCuenta = data as TipoCuenta[];
      }
    );
  }

  setLabel(event) {
    let labelBanco = event.originalEvent.target.innerText;
    if(this.item.idBancoReceptor) {
      this.item.idBancoReceptor.bancoReceptor = labelBanco;
    }
    else {
      this.item.idBancoReceptor = {
        bancoReceptor: labelBanco
      };
    }
  }
}
