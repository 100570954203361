<div class="p-grid">
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(1)">
            <div class="div-center">
                <fa-icon [icon]="faCalendarDay" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Vacaciones a expirar</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(2)">
            <div class="div-center">
                <fa-icon [icon]="faFileSignature" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Contratos a vencer</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(3)">
            <div class="div-center">
                <fa-icon [icon]="faUserClock" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Empleados temporales</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(4)">
            <div class="div-center">
                <fa-icon [icon]="faMoneyBill" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Ajustes de sueldo</span>
            </div>
        </p-card>
    </div>
</div>