import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { Organigrama } from 'src/app/api/organigrama/organigrama';
import { OrganigramaRestcService } from 'src/app/client/organigrama/organigrama-restc.service';

@Injectable({
  providedIn: 'root'
})
export class OrganigramaPsService extends BaseProcess<OrganigramaRestcService>{

  client: OrganigramaRestcService;

  constructor(httpClient: HttpClient, crest: OrganigramaRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  recursosPorLider(idLider: number): Observable<Organigrama[]> {
    return this.client.recursosPorLider(idLider);
  }

  liberarRecurso(item: Organigrama): Observable<Message> {
    return this.client.liberarRecurso(item);
  }

  empleadosEnBanca(item: Organigrama): Observable<Organigrama[]> {
    return this.client.empleadosEnBanca(item);
  }

  asignarEmpleado(item: Organigrama, idLider: number): Observable<Message> {
    return this.client.asignarEmpleado(item, idLider);
  }

  actual(idEmpleado: number): Observable<Organigrama> {
    return this.client.actual(idEmpleado);
  }
}
