<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [options]="catEmpleados" [(ngModel)]="item.idEmpleado" optionValue="id"
                optionLabel="nombreCompleto" [autoDisplayFirst]="false" [filter]="true" filterBy="nombreCompleto"
                [baseZIndex]="20000" appendTo="body">
            </p-dropdown>
            <label for="fl-emp">
                <span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">*</span>
                Empleado
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('empChecador')">
        <br>
        <span class="p-float-label">
            <input id="fl-idempch" type="text" pInputText [(ngModel)]="item.idChecador">
            <label for="fl-idempch" class="label-full"><span *ngIf="operationMode != op.SEARCH"
                    [style]="{'color':'red'}">*</span> ID Checador</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('nur')">
        <br>
        <span class="p-float-label">
            <input id="fl-nur" type="text" pInputText [(ngModel)]="item.nur">
            <label for="fl-nur" class="label-full"><span *ngIf="operationMode != op.SEARCH"
                    [style]="{'color':'red'}">*</span> NUR</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('sucursal')">
        <br>
        <span class="p-float-label">
            <input id="fl-nur" type="text" pInputText [(ngModel)]="item.sucursal">
            <label for="fl-nur" class="label-full"><span *ngIf="operationMode != op.SEARCH"
                    [style]="{'color':'red'}">*</span> Lugar de trabajo</label>
        </span>
    </div>
</div>