<!-- {{item|json}} -->
<div class="p-grid frm-part" *ngIf="item">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('tipo')">
        <axks-tipo-contrato-cat [(value)]="item.contrato.idTipoContrato" [idEmpresa]="item.idEmpresa"
            (onValueChange)="onValueChange($event)"></axks-tipo-contrato-cat>
    </div>
    
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecContrat')">
        <span class="p-float-label">
            <p-calendar id="fl-feccont" [(ngModel)]="item.contrato.fecContratacion" dateFormat="yy/mm/dd"
                [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                appendTo="body"></p-calendar>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Fecha de contratación</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12"
        *ngIf="allowFormFields('fecContrat') && item.contrato.idTipoContrato != tpContrato.PLANTA ">
        <span class="p-float-label">
            <p-calendar id="fl-feccont" [(ngModel)]="item.contrato.fecTerminoContrato" dateFormat="yy/mm/dd"
                [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                appendTo="body"></p-calendar>
            <label for="fl-nmemp">Fecha fin de contrato</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idPeriodoPago')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.sueldo.idPeriodo" [options]="catPeriodopago"
                optionLabel="periodoPago" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="true"
                itemSize="10" autoWidth="false" [style]="{'width':'100%'}" (onChange)="onValueChangePeriodo($event)"
                appendTo="body">
            </p-dropdown>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Periodo de pago</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('sueldo')">
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.sueldo.sueldo" inputId="currency-us" mode="currency" currency="MXN"
                locale="en-US">
            </p-inputNumber>
            <label for="fl-coment" *ngIf="item.sueldo.idPeriodo != PERIODO_PAGO.SEMANAL" class="label-full"><span [style]="{'color':'red'}">*</span> Sueldo mensual</label>
            <label for="fl-coment" *ngIf="item.sueldo.idPeriodo == PERIODO_PAGO.SEMANAL" class="label-full"><span [style]="{'color':'red'}">*</span> Sueldo semanal</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('sueldoDiario')">
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.sueldo.sueldoDiario" inputId="currency-us" mode="currency" currency="MXN"
                locale="en-US">
            </p-inputNumber>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Sueldo diario</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('imss')">
        <p-checkbox [(ngModel)]="item.imss" binary="true" inputId="imss"></p-checkbox>
        &nbsp; <label for="imss" class="form-field-label">IMSS</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('infonavit')">
        <p-checkbox [(ngModel)]="infonavit" binary="true" inputId="infonavit" (click)="funcionInfonavit()"></p-checkbox>
        &nbsp; <label for="infonavit" class="form-field-label">INFONAVIT</label>
    </div>

</div>
<p-toast></p-toast>