import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ContratacionRestcService } from 'src/app/client/contratacion/contratacion-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ContratacionPsService extends BaseProcess<ContratacionRestcService> {

  constructor(httpClient: HttpClient,
    restc: ContratacionRestcService) { 
      super(httpClient, restc);
    }
}
