<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<p-scrollPanel [style]="{width: '100%', height: '80vh'}">
    <div class="form-wizard-steps">
        <p-steps [model]="steps" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
        <div *ngIf="isMobile()" class="form-wizard mobile-header">
            <h3>{{getHeaderMobile()}}</h3>
        </div>
    </div>
    <p-card class="form-wizard frm-part-block">
        <!-- {{item | json}}  -->
        <ng-template pTemplate="content">
            <p-scrollPanel [style]="{width: '100%', height: '65vh'}">
                <axks-personales-form *ngIf="activeIndex==0" [item]="item.personales" [rfc]="item.rfc"
                    (rfcChange)="onRfcChange($event)" [operationMode]="operationMode" [formFields]="formFields"
                    [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
                </axks-personales-form>

                <axks-direccion-form *ngIf="activeIndex==1" [item]="dir" [operationMode]="operationMode"
                    [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                    [detailFields]="detailFields" (onDirchange)="onDirChange($event)" (renovDir)="onRenovDir($event)"
                    [esRenovacion]="esRenovacion"></axks-direccion-form>

                <ng-container *ngIf="activeIndex==2">
                    <ng-container *ngFor="let mcont of item.mediosContacto">
                        <ng-container [ngSwitch]="mcont.idTipoContacto">
                            <axks-contacto-form-telefono *ngSwitchCase="TP_CONTACTO.TELEFONO_CASA" [item]="mcont"
                                [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                                [listFields]="listFields" [detailFields]="detailFields">
                            </axks-contacto-form-telefono>

                            <axks-contacto-form *ngSwitchCase="TP_CONTACTO.CORREO_ELECTRONICO_EMPRESARIAL"
                                [item]="mcont" [operationMode]="operationMode" [formFields]="formFields"
                                [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
                            </axks-contacto-form>

                            <axks-contacto-form-telefono *ngSwitchCase="TP_CONTACTO.TELEFONO_MOVIL" [item]="mcont"
                                [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                                [listFields]="listFields" [detailFields]="detailFields">
                            </axks-contacto-form-telefono>

                            <axks-contacto-form *ngSwitchCase="TP_CONTACTO.CORREO_ELECTRONICO_PERSONAL" [item]="mcont"
                                [operationMode]="operationMode" [formFields]="formFields" [formSearch]="formSearch"
                                [listFields]="listFields" [detailFields]="detailFields">
                            </axks-contacto-form>

                            <axks-contacto-form *ngSwitchDefault [item]="mcont" [operationMode]="operationMode"
                                [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                                [detailFields]="detailFields">
                            </axks-contacto-form>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <axks-contrato-form *ngIf="activeIndex==3" [item]="item.contrato" [infonavit]="item.infonavit"
                    (infonavitChange)="onInfonavitChange($event)" [operationMode]="operationMode"
                    [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                    [detailFields]="detailFields" [esRenovacion]="esRenovacion" [prerenovacion]="prerenovacion">
                </axks-contrato-form>

                <axks-organigrama-form *ngIf="activeIndex==4" [item]="item" [operationMode]="operationMode"
                    [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                    [detailFields]="detailFields">
                </axks-organigrama-form>

                <axks-cuenta-form *ngIf="activeIndex==5" [item]="item.cuentaBancoEmpleado"
                    [operationMode]="operationMode" (onChange)="onCuentaBancoChanges($event)">
                </axks-cuenta-form>

                <axks-contrato-preview *ngIf="activeIndex==6" [item]="item" [direccion]="dir"
                    [operationMode]="operationMode">
                </axks-contrato-preview>
            </p-scrollPanel>


        </ng-template>

        <ng-template pTemplate="footer">

            <div style="text-align: right; padding-bottom: 10px;">
                <p-button label="Anterior" title="Anterior" (onClick)="backPage()" *ngIf="showBack()"
                    icon="pi pi-angle-left" [disabled]="showProgress"></p-button>
                &nbsp;
                <p-button label="Cancelar" title="Cancelar" (onClick)="cancel()" icon="pi pi-ban"
                    [disabled]="showProgress"></p-button>
                &nbsp;
                <p-button label="Siguiente" title="Siguiente" (onClick)="nextPage()" *ngIf="showNext()"
                    icon="pi pi-angle-right" iconPos="right"></p-button>
                &nbsp;
                <p-button label="Guardar" title="Guardar" (onClick)="save()" *ngIf="showSave() && !this.botoninicio"
                    icon="pi pi-save" iconPos="right" [disabled]="showProgress">
                </p-button>
                &nbsp;
                <!-- <p-button label="Inicio" title="Inicio" (onClick)="cancel()" *ngIf="showSave() && this.botoninicio" icon="pi pi-home" iconPos="right">
            </p-button> -->
            </div>
        </ng-template>
    </p-card>
</p-scrollPanel>
<p-toast></p-toast>