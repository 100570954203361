import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { LiderCat } from 'src/app/api/empleado/lider-cat';
import { Organigrama } from 'src/app/api/organigrama/organigrama';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { SolicitudApoyoPsService } from 'src/app/procs/solicitud-apoyo/solicitud-apoyo-ps.service';

@Component({
  selector: 'axks-solicitudes-por-autorizar',
  templateUrl: './solicitudes-por-autorizar.component.html',
  styleUrls: ['./solicitudes-por-autorizar.component.scss']
})
export class SolicitudesPorAutorizarComponent extends BaseView implements OnInit {

  lideres: LiderCat[];
  filtro: SolicitudApoyo;
  items: SolicitudApoyo[];

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private restcService: SolicitudApoyoPsService,
    private clientEmp: EmpleadoRestcService) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.filtro) {
      this.filtro = {
        idLiderAutoriza: this.profile.idEmpleado
      };
    }

    this.initCatLideres();

    setTimeout(() => {
      this.initCargaSolicitudes();
    }, 500);

    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initCatLideres() {
    this.clientEmp.lideresActivos(this.profile.empresa).subscribe(
      (data) => {
        this.lideres = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener la lista de líderes");
      }
    );
  }

  initCargaSolicitudes() {
    this.showProgress = true;
    this.restcService.porAutorizar(this.filtro).subscribe(
      (data) => {
        this.items = data;
        if (this.items.length == 0) {
          this.infoMessage("Sin registros", "No hay solicitudes pendientes por revisar.");
        }
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar los colaboradores en banca.");
        this.showProgress = false;
      }
    );
  }

  configTituloReportes() {
    this.tituloReportes.emit("Solicitudes de apoyo por autorizar");
  }

  procesarSolicitud(item: Organigrama, aut: boolean) {
    this.showProgress = true;
    this.restcService.autorizar(item, aut).subscribe(
      (data) => {
        this.showProgress = false;
        let msg = data as Message;
        this.successMessage(msg.message, msg.details);
        setTimeout(() => {
          this.initCargaSolicitudes();
        }, 1000);
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  exportExcel() {
    if (this.items.length > 0) {
      import("xlsx").then(xlsx => {
        let itemsExcel = [];
        for(let item of this.items) {
          itemsExcel.push({
            NUM_SOLICITUD: item.id,
            ID_COLABORADOR: item.idEmpleado,
            COLABORADOR: item.idEmpleado,
            FEC_SOLICITUD: item.fecSolicitud,
            ID_LIDER: item.idLiderSolicitante,
            LIDER: item.liderSolicitante
          });
        }
        const worksheet = xlsx.utils.json_to_sheet(itemsExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Solicitudes-por-autorizar");
      });
    }
    else {
      this.infoMessage("Sin elementos", "No hay elementos para exportar a excel");
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) +
        "-" + (mes < 10 ? "0" + mes : mes) +
        "-" + now.getFullYear() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

}

