import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ContratosRenovacion } from 'src/app/api/contratos-renovacion';
import { PreRenovacionReg } from 'src/app/api/pre-renovacion/pre-renovacion-reg';
import { Prerenovacion } from 'src/app/api/pre-renovacion/prerenovacion';
import { ContrataciocConsumo } from 'src/app/api/contratacion/contratacioc-consumo';

import { ListContraVencRHService } from '../../../client/list-contra-venc-RH/list-contra-venc-rh.service';
import { Router } from '@angular/router';
import { TipoContrato } from 'src/app/api/enums/tipo-contrato.enum';

@Component({
  selector: 'axks-list-contr-renov-rh',
  templateUrl: './list-contr-renov-rh.component.html',
  styleUrls: ['./list-contr-renov-rh.component.scss']
})
export class ListContrRenovRhComponent extends BaseView implements OnInit {

  @Input() item: ContratosRenovacion;
  @Output() propagarRenovacion = new EventEmitter<any>();

  listaRenov: ContratosRenovacion[] = [];
  vistoBuenoLiderReg: Prerenovacion = {};
  autorizaRenovacion: PreRenovacionReg[] = [];
  ContratoRenovacion: ContrataciocConsumo[] = [];
  showDialogBaja: boolean = false;
  idEmpleado: number;
  profile = this.getProfile() as ProfileC;
  tipoContratoEnum = TipoContrato;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListContraVencRHService,
    protected router: Router) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
    this.obtenerListaRh();
  }

  obtenerListaRh() {
    this.showProgress = true;

    this.listaRestc.register().subscribe(
      (data) => {
        this.listaRenov = data as ContratosRenovacion[];
        this.showProgress = false;
        if (this.listaRenov.length == 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin renovaciones', detail: 'No hay ninguna renovación pendiente por revisar' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener las solicitudes de renovación' });
      }
    );
  }

  autorizar(valor: any) {
    this.screenModeService.screenMode.next(OperationMode.ADDNEW);
    this.router.navigate(["contrato"], {
      queryParams: {
        "em": valor.idEmpleado,
        "ep": this.profile.empresa,
        "e": this.profile.empresa,
        "a": this.profile.aplicacion,
        "s": this.profile.sesion
      }
    });
  }

  ocultarDialog(event) {
    this.showDialogBaja = event;
    this.obtenerListaRh();
  }

  rechazar(valor: any) {
    this.idEmpleado = valor.idEmpleado;
    this.showDialogBaja = true;
  }
}
