import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListContraVencLiderService extends ARestClient{
  
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  
  getBaseEndpoint(): string {
    throw new Error('Method not implemented.');
  }
  processBody(res: any) {
    throw new Error('Method not implemented.');
  }

  

  register(idJefe: number): Observable<any>{
  /*   let prueba = idJefe+22 */
    let path = environment.msListContratoVencimientoLider+ "/" + idJefe;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }
} 
