import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { VencimientoContrato } from 'src/app/api/dashboard/vencimiento-contrato';
import { environment } from 'src/environments/environment';
import { ContrVencerLiderService } from '../../../client/dashboard/contr-vencer-lider.service';

@Component({
  selector: 'axks-dashboard-contrato-fin-lider',
  templateUrl: './dashboard-contrato-fin-lider.component.html',
  styleUrls: ['./dashboard-contrato-fin-lider.component.scss']
})
export class DashboardContratoFinLiderComponent extends BaseView implements OnInit {

  dashboard: VencimientoContrato[] = [];
  profile = this.getProfile() as ProfileC;
  showContent: boolean;
  myLoadContent: boolean;
  mensajeSol: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private dashJefeResct: ContrVencerLiderService) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    let urlParams = new URLSearchParams(window.location.search);
    this.myLoadContent = false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerSolicitudes();
    }, 1500);
  }

  obtenerSolicitudes() {

    this.dashJefeResct.listaLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.dashboard = data as VencimientoContrato[];
        this.myLoadContent = true;
        this.initContent();
      },
      (error) => {
        this.myLoadContent = true;
        this.mensajeSol = "Error. No se pudo consultar las solicitudes de renovación de contrato.";
      }
    );
  }

  initContent() {
    if (this.dashboard.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
      this.mensajeSol = "No hay solicitudes de renovación de contrato pendientes por revisar.";
    }
  }

  selSolicitud(item) {
    window.parent.postMessage({
      for: "redirect",
      url: environment.UrlFormularioLider,
      params: {
        ep: item.idEmpresa,
        em: item.idEmpleado,
      }
    }, "*");
  }
}
