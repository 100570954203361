import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMessages, BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area';
import { Contrato } from 'src/app/api/contratacion/contrato';
import { PeriodoPago } from 'src/app/api/contratacion/periodo-pago';
import { BancoReceptor } from 'src/app/api/cuenta/cuenta-cats';
import { PeriodoPagoPsService } from 'src/app/procs/contratacion/periodo-pago-ps.service';
import { environment } from 'src/environments/environment';
import { ContratacionReg } from '../../../api/contratacion/contratacion-reg';
import { ContratacionDetalle } from '../../../api/contratacion/contratacion-detalle';
import { Empleadocat } from '../../../api/empleadocat';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { PreRenovacionReg } from '../../../api/pre-renovacion/pre-renovacion-reg';
import { TipoContrato } from 'src/app/api/enums/tipo-contrato.enum';
import { PeriodoPagoEnum } from '../../../enums/periodo-pago.enum';


@Component({
  selector: 'axks-contrato-form',
  templateUrl: './contrato-form.component.html',
  styleUrls: ['./contrato-form.component.scss']
})
export class ContratoFormComponent extends BaseView implements OnInit {

  @Input() item: Contrato;
  @Input() items: ContratacionReg = {};
  @Input() infonavit: boolean;
  @Output() infonavitChange = new EventEmitter();
  @Input() esRenovacion: boolean = false;
  @Input() prerenovacion: PreRenovacionReg;

  selArea: Area = {};
  selPeri: PeriodoPago = {};;
  yearRange: any;
  catPeriodopago: PeriodoPago[];
  catEmpleados: Empleadocat[] = [];
  tipoContrato = TipoContrato;
  PERIODO_PAGO = PeriodoPagoEnum;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private periodoPagoPs: PeriodoPagoPsService,
    private catRest: EmpleadoRestcService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
    /* if(this.profile){
      this.loadContent = true;
    } */
  }

  ngOnInit(): void {
    this.initDates();
    this.initCatPeriodoPago();

    if (!this.prerenovacion) {
      this.prerenovacion = {};
    }

    if (!this.item) {
      this.item = {
      } as Contrato;
    }
    if (!this.items) {
      this.items = {
        puesto: [{}],
        tipoContrato: [{}],
      } as ContratacionDetalle;
    }

    if (this.item.fecTerminoContrato) {
      this.item.fecTerminoContrato = this.formatoFecha(this.item.fecTerminoContrato)
    }

    if (this.item.fecContratacion) {
      this.item.fecContratacion = this.formatoFecha(this.item.fecContratacion)
    }
    if (this.esRenovacion) {

    }


  }

  initCatPeriodoPago() {
    this.periodoPagoPs.all().subscribe(data => {
      this.catPeriodopago = data as PeriodoPago[];
    })
  }


  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = 2011 + ':' + (year + 1);
  }

  onValueChange(event) {


    this.item.idTipoContrato = event.id;
    /* this.item.idBancoReceptor=event.id; */

    this.item["tipoContrato"] = event.tipoContrato;
    /*  this.item["bancoReceptor"]=event.bancoReceptor; */

  }

  funcionInfonavit() {
    this.infonavitChange.emit(this.infonavit);
  }

  onValueChangePeriodo(event) {
    this.item["periodoPago"] = event.originalEvent.target.innerText;


  }

  formatoFecha(value: string) {
    let d = Date.parse(value);

    return formatDate(d, 'yyyy/MM/dd', 'es-MX');
  }

  contratoIndefinido(forItem: ContratacionDetalle) {
    if (!forItem.idTipoContrato)
      return false;
  }

}