import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { CatLiderRestcService } from 'src/app/client/empleado/cat-lider-restc.service';
import { LiderCat } from 'src/app/api/empleado/lider-cat';

@Injectable({
  providedIn: 'root'
})
export class CatLiderPsService extends BaseProcess<CatLiderRestcService>{

  client: CatLiderRestcService;
  constructor(httpClient: HttpClient, crest: CatLiderRestcService) { 
    super(httpClient, crest)
    this.client = crest;
  }

  getCatalogoLider(idEmpresa:number): Observable<LiderCat>{
    return this.client.findById(idEmpresa);
  }
}
