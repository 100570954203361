import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AreaComponent } from './views/area/area/area.component';
import { ContratoComponent } from './views/contrato/contrato/contrato.component';
import { ListContrRenovLiderComponent } from './views/contrato/list-contr-renov-lider/list-contr-renov-lider.component';
import { ListContrRenovRhComponent } from './views/contrato/list-contr-renov-rh/list-contr-renov-rh.component';
import { VacacionesDashComponent } from './views/dashboard/vacaciones-dash/vacaciones-dash.component';
import { DireccionFormComponent } from './views/direccion/direccion-form/direccion-form.component';
import { EmpleadoComponent } from './views/empleado/empleado/empleado.component';
import { HomeComponent } from './views/home/home.component';
import { PuestoComponent } from './views/puesto/puesto/puesto.component';
import { TipoContratoComponent } from './views/tipo-contrato/tipo-contrato/tipo-contrato.component';
import { DashboardContratoFinRhComponent } from './views/contrato/dashboard-contrato-fin-rh/dashboard-contrato-fin-rh.component';
import { DashboardContratoFinLiderComponent } from './views/contrato/dashboard-contrato-fin-lider/dashboard-contrato-fin-lider.component';
import { ContratoWizardComponent } from './views/contrato/contrato-wizard/contrato-wizard.component';
import { EmpleadoVistaComponent } from './views/empleado/empleado-vista/empleado-vista.component';
import { ReportesComponent } from './views/reportes/reportes/reportes.component';
import { AjusteSueldoComponent } from './views/sueldo/ajuste-sueldo/ajuste-sueldo.component';
import { BancaComponent } from './views/banca/banca/banca.component';
import { EmpleadoChecadorComponent } from './views/asistencia/empleado-checador/empleado-checador.component';
import { AsistenciaRevisarComponent } from './views/asistencia/asistencia-revisar/asistencia-revisar.component';
import { AsistenciaIndexComponent } from './views/asistencia/asistencia-index/asistencia-index.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'area', component: AreaComponent },
  { path: 'puesto', component: PuestoComponent },
  { path: 'tcontr', component: TipoContratoComponent },
  { path: 'empleado', component: EmpleadoComponent },
  { path: 'empleado-vista', component: EmpleadoVistaComponent },
  { path: 'renovacion-lider', component: ListContrRenovLiderComponent },
  { path: 'renovacion-rh', component: ListContrRenovRhComponent },
  { path: 'dashboard-rh', component: DashboardContratoFinRhComponent },
  { path: 'dashboard-lider', component: DashboardContratoFinLiderComponent },
  {
    path: 'contrato', component: ContratoComponent, pathMatch: "full",
    children: [
      { path: 'direccion', component: DireccionFormComponent, outlet: 'step' }
    ]
  },
  { path: 'dashboard/vacaciones', component: VacacionesDashComponent },
  { path: 'contratacion', component: ContratoWizardComponent },
  { path: 'reportes', component: ReportesComponent },
  { path: 'ajuste-sueldo', component: AjusteSueldoComponent },
  { path: 'banca', component: BancaComponent },
  { path: 'checador', component: AsistenciaIndexComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
