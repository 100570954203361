import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VacacDashRestcService } from 'src/app/client/dashboard/vacac-dash-restc.service';
import { ConfVacaciones } from 'src/app/api/vacaciones/conf-vacaciones';
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-vacaciones-dash',
  templateUrl: './vacaciones-dash.component.html',
  styleUrls: ['./vacaciones-dash.component.scss']
})
export class VacacionesDashComponent extends BaseView implements OnInit {

  data: any;
  options: any;
  confVac: ConfVacaciones;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private restc: VacacDashRestcService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }


  ngOnInit(): void {
    //setTimeout(() =>{ 
    this.getConfVacacionesEmpleado();
    //}, 2000);


  }

  getConfVacacionesEmpleado() {
    let perfil = this.getProfile() as ProfileC;

    let idEmpleado = perfil.idEmpleado;

    this.restc.findById(idEmpleado).subscribe(
      (data) => {
        this.confVac = data as ConfVacaciones;
        this.initData();
      },
      (error) => {
     
      }
    );
  }

  initData() {
    if (!this.confVac) {
      this.confVac = { disponibles: 0, utilizado: 0 }
    }
    this.data = {
      labels: ['Disponibles', 'Utilizados'],
      datasets: [
        {
          data: [this.confVac.disponibles = this.confVac.disponibles, this.confVac.utilizado = this.confVac.utilizado],
          backgroundColor: [
            "#36A2EB",
            "#FF6384",
          ],
          hoverBackgroundColor: [
            "#36A2EB",
            "#FF6384",
          ]
        }]
    };

    this.options = {
      title: {
        display: true,
        text: "Días disponibles: " + this.confVac.disponibles
      },
      legend: {
        position: 'bottom'
      }
    }
  }

}
