<span class="p-float-label">
    <p-dropdown inputId="fl-tcnm" [(ngModel)]="value" [options]="catalog" optionLabel="puesto" optionValue="id"
        [autoDisplayFirst]="false" [virtualScroll]="true" itemSize="10" [filter]="true" filterBy="puesto"
        emptyFilterMessage="Sin resultados" emptyMessage="Sin resultados" autoWidth="false"
        (onChange)="onChange($event)" appendTo="body"></p-dropdown>
    <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Puesto</label>
</span>

<!-- {{value}} -->

<p-toast></p-toast>