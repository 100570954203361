import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { faUserClock, faFileSignature, faCalendarDay, faCalendarCheck, faUsersCog, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'axks-asistencia-menu',
  templateUrl: './asistencia-menu.component.html',
  styleUrls: ['./asistencia-menu.component.scss']
})
export class AsistenciaMenuComponent extends BaseView implements OnInit {

  @Output() numMenu = new EventEmitter<number>();
  @Output() tituloReportes = new EventEmitter<String>();

  faCalendarPlus = faCalendarPlus;
  faCalendarCheck = faCalendarCheck;
  faUsersCog = faUsersCog;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  emitirNumReporte(event) {
    this.numMenu.emit(event);
  }

  configTituloReportes() {
    this.tituloReportes.emit("Menú");
  }
}
