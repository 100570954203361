<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [options]="catEmpleados" [(ngModel)]="item.idEmpleado.id" optionValue="id"
                optionLabel="nombreCompleto" [autoDisplayFirst]="false" [filter]="true" filterBy="nombreCompleto"
                [style]="{'width':'90%'}" [baseZIndex]="20000" appendTo="body">
            </p-dropdown>
            <label for="fl-emp">Empleado</label>
        </span>
    </div>

    <div class="p-col-12" *ngIf="allowFormFields('excel')">
        <br>
        <div>
            <p-fileUpload mode="advanced" name="archivoAsistencias" fileLimit="1" maxFileSize="20000000"
                (uploadHandler)="subirExcel($event)" customUpload="true" [auto]="false" chooseLabel="Cargar archivo"
                uploadLabel="Subir archivo" [showCancelButton]="true" [showUploadButton]="true"
                cancelLabel="Eliminar archivo" [style]="{'width':'90%'}">
                <ng-template pTemplate="content">
                    <ul *ngIf="archivosCargados.length">
                        <li *ngFor="let file of archivosCargados">
                            {{file.name}} - {{file.size}} bytes
                        </li>
                    </ul>
                </ng-template>
            </p-fileUpload>
        </div>
    </div>
</div>