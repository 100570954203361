export enum Roles {
    SU = 1,
    EMPLEADO = 2,
    GERENTE_RH = 3,
    TECNICO_SOPORTE = 4,
    FINANZAS = 5,
    GESTOR_COMPRAS = 6,
    LIDER = 7,
    DIRECTOR = 8
}
