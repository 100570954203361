import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { SolicitudApoyoPsService } from '../../../../procs/solicitud-apoyo/solicitud-apoyo-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'axks-solicitudes-apoyo',
  templateUrl: './solicitudes-apoyo.component.html',
  styleUrls: ['./solicitudes-apoyo.component.scss']
})
export class SolicitudesApoyoComponent extends BaseMainComponent<SolicitudApoyoPsService> implements OnInit {

  empleadosActivos: EmpleadoNombre[];
  @Output() tituloReportes = new EventEmitter<String>();

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProv: SessionClientProvider,
    private restService: SolicitudApoyoPsService,
    private restEmp: EmpleadoRestcService
  ) {
    super(deviceService, messageService, screenModeService, sessionProv);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initEmpleadosActivos();

    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idLiderSolicitante: this.profile.idEmpleado
      } as SolicitudApoyo,
      minimumItem: {
        idLiderSolicitante: this.profile.idEmpleado
      } as SolicitudApoyo,
      registerItem: {
        idLiderSolicitante: this.profile.idEmpleado
      } as SolicitudApoyo
    } as ComponentItem;
    return empty;
  }
  getService(): SolicitudApoyoPsService {
    return this.restService;

  }
  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      { field: 'id', header: 'Núm. Solicitud', inAll: true },
      { field: 'idEmpleado', header: 'Núm. Colaborador', inDetail: true },
      { field: 'empleado', header: 'Colaborador solicitado', inAll: true },
      { field: 'fecSolicitud', header: 'Fecha de solicitud', inAll: true, formatFunction: this.formatoFecha },
      { field: 'idLiderAutoriza', header: 'Núm. Colaborador líder que autoriza', inDetail: true },
      { field: 'liderAutoriza', header: 'Líder que autoriza', inAll: true },
      { field: 'autorizacionLider', header: 'Autorización de líder', inAll: true, formatFunction: this.formatoBoolean },
      { field: 'fecAutLider', header: 'Fecha de autorización', inDetail: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return cols;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "";
  }

  initEmpleadosActivos() {
    this.restEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.empleadosActivos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener la lista de colaboradores activos");
      }
    );
  }

  configTituloReportes() {
    this.tituloReportes.emit("Solicitudes de apoyo");
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ["empleado", "liderAutoriza", "nota", "liderApoyo"];
    this.formSearch = ["empleado"];
  }

  formatoFecha(value: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy, hh:mm:ss a", "America/Mexico_City");
  }

  formatoBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Autorizado' : 'Rechazado');
    }
    else {
      return 'Pendiente...';
    }
  }

}
