<div class="p-grid frm-part">
    <!-- {{item | json}} -->
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('empleado')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idEmpleado" [options]="empleadosActivos"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" [filter]="true"
                filterBy="nombreCompleto" appendTo="body" [baseZIndex]="20000"
                (onChange)="changeLider($event)"></p-dropdown>
            <label for="fl-coment">Colaborador</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('liderAutoriza') && item.idEmpleado">
        <span class="p-float-label">
            <input id="fl-nmemp" type="text" pInputText [(ngModel)]="item.liderAutoriza" [disabled]="true">
            <label for="fl-nmemp">Líder que autoriza</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="allowFormFields('nota')">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <label for="fl-coment" style="font-size: 0.7rem; color: red">
                * Se notificará al líder sobre el colaborador que se solicita como apoyo para su autorización a la
                petición.
            </label>
        </div>
    </div>
</div>