<div class="p-grid">
    <!-- {{profile | json}} -->
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6" *ngIf="showCard(INDEX.EMPLEADOS_ASIGNADOS)">
        <p-card class="card-reporte" (click)="emitirNumReporte(1)">
            <div class="div-center">
                <fa-icon [icon]="iconUsers" class="icon-center" style="font-size: 5rem;"></fa-icon>
                <span><br>Colaboradores asignados</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6" *ngIf="showCard(INDEX.EMPLEADOS_SIN_ASIGNACION)">
        <p-card class="card-reporte" (click)="emitirNumReporte(2)">
            <div class="div-center">
                <fa-icon [icon]="iconUsersFree" class="icon-center" size="6x"></fa-icon>
                <span><br>Colaboradores sin asignación</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6" *ngIf="showCard(INDEX.SOLICITUDES_APOYO)">
        <p-card class="card-reporte" (click)="emitirNumReporte(3)">
            <div class="div-center">
                <fa-icon [icon]="iconHelp" class="icon-center" size="6x"></fa-icon>
                <span><br>Solicitudes de apoyo</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6" *ngIf="showCard(INDEX.SOLICITUDES_APOYO_AUTORIZAR)">
        <p-card class="card-reporte" (click)="emitirNumReporte(4)">
            <div class="div-center">
                <fa-icon [icon]="iconCheck" class="icon-center" size="6x"></fa-icon>
                <span><br>Solicitudes de apoyo por autorizar</span>
            </div>
        </p-card>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6" *ngIf="showCard(INDEX.TODAS_SOLICITUDES_APOYO)">
        <p-card class="card-reporte" (click)="emitirNumReporte(5)">
            <div class="div-center">
                <fa-icon [icon]="iconClipboardCheck" class="icon-center" size="6x"></fa-icon>
                <span><br>Todas las solicitudes de apoyo</span>
            </div>
        </p-card>
    </div>
</div>