


<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="steps" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>


</div>
<p-card>
  <!--  {{item | json}}  -->
    <ng-template pTemplate="content">
        <axks-personales-form *ngIf="activeIndex==0" [item]="item.personales" [rfc]="item.rfc" (rfcChange)="onRfcChange($event)" [operationMode]="operationMode"
            [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
        </axks-personales-form>

        <axks-direccion-form *ngIf="activeIndex==1" [item]="dir" [operationMode]="operationMode"
            [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields"
            (onDirchange)="onDirChange($event)"></axks-direccion-form>

        <ng-container *ngIf="activeIndex==2">
            <axks-contacto-form *ngFor="let mcont of item.mediosContacto" [item]="mcont" [operationMode]="operationMode"
                [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields"
                [detailFields]="detailFields">
            </axks-contacto-form>
        </ng-container>


        <axks-contrato-form *ngIf="activeIndex==3" [item]="item.contrato" [infonavit]="item.infonavit" (infonavitChange)="onInfonavitChange($event)" [operationMode]="operationMode"
            [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">
        </axks-contrato-form>
        
        <axks-organigrama-form *ngIf="activeIndex==4" [item]="item" [operationMode]="operationMode"
            [formFields]="formFields" [formSearch]="formSearch" [listFields]="listFields" [detailFields]="detailFields">

        </axks-organigrama-form>
        
        <axks-cuenta-form *ngIf="activeIndex==5" [item]="item.cuentaBancoEmpleado" 
        [operationMode]="operationMode" (onChange)="onCuentaBancoChanges($event)">

        </axks-cuenta-form>

        <axks-contrato-preview *ngIf="activeIndex==6" [item]="item" [direccion]="dir" [operationMode]="operationMode">
        </axks-contrato-preview>

        

    </ng-template>

    <ng-template pTemplate="footer">

        <div class="p-grid p-nogutter p-justify-end">
            <p-button label="Anterior" (onClick)="backPage()" *ngIf="showBack()" icon="pi pi-angle-left"></p-button>
            <p-button label="Cancelar" (onClick)="cancel()" icon="pi pi-times"></p-button>
            <p-button label="Siguiente" (onClick)="nextPage()" *ngIf="showNext()" icon="pi pi-angle-right"
                iconPos="right"></p-button>
            <p-button label="Guardar" (onClick)="save()" *ngIf="showSave()" icon="pi pi-save" iconPos="right">
            </p-button>
        </div>
    </ng-template>
</p-card>
<p-toast></p-toast>