import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoContacto } from 'src/app/api/contacto/tipo-contacto';
import { TipoContactoRestcService } from 'src/app/client/tipo-contacto/tipo-contacto-restc.service';

@Component({
  selector: 'axks-tipo-contacto-cat',
  templateUrl: './tipo-contacto-cat.component.html',
  styleUrls: ['./tipo-contacto-cat.component.scss']
})
export class TipoContactoCatComponent implements OnInit {

  catalog: TipoContacto[];

  @Input() value: TipoContacto;
  @Output() onValueChange = new EventEmitter<TipoContacto>();

  constructor(private tcRest: TipoContactoRestcService) {
  }

  ngOnInit(): void {
    this.tcRest.getAll().subscribe(result => {
      this.catalog = result || {} as TipoContacto[];
    });
  }

/*   onChange(event) {
    let tcont = {
      id: this.value.id
    } as TipoContacto;

    let tcf = this.catalog.find(tc => (tc.id == this.value.id));

    if (tcf && tcf.id === this.value.id) {
      tcont.tipoContacto = tcf.tipoContacto
    }

    this.onValueChange.emit(tcont);
  } */

}
