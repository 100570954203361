<!-- {{item | json}} -->
<!-- {{selAsentamiento | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('pais')">
        <span class="p-float-label">
            <input id="fl-pais" type="text" pInputText [(ngModel)]="item.pais" disabled="true">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> País</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('cp')">
        <span class="p-float-label">
            <input id="fl-cp" type="text" pInputText pKeyFilter="num" [(ngModel)]="item.cp" (blur)="findWithCP($event)">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Código Postal</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12"
        *ngIf="allowFormFields('cp') && asentamientos && !selAsentamiento.id">
        <p-listbox [options]="asentamientos" [(ngModel)]="selAsentamiento" optionLabel="asentamiento" [checkbox]="true"
            [listStyle]="{'max-height':'100px'}" (onChange)="onSelAsentamiento($event)" emptyMessage="Sin resultados">
            <ng-template pTemplate="header">
                Selecciona una colonia
            </ng-template>
        </p-listbox>
    </div>

    <ng-container *ngIf="!asentamientos || selAsentamiento.id">
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('estado')">
            <span class="p-float-label">
                <input id="fl-edo" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.estado"
                    [disabled]="item.estado">
                <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Estado</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('alcMun')">
            <span class="p-float-label">
                <input id="fl-almun" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.alcMun"
                    [disabled]="item.alcMun">
                <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Alcaldía / Municipio</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('ciudad')">
            <span class="p-float-label">
                <input id="fl-ciud" type="text" pInputText [(ngModel)]="item.ciudad" [disabled]="item.ciudad">
                <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Ciudad</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('colonia')">
            <span class="p-float-label">
                <input id="fl-ciud" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.colonia"
                    [disabled]="item.colonia">
                <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Colonia</label>
            </span>
        </div>
    </ng-container>


    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('calle')">
        <span class="p-float-label">
            <input id="fl-calle" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.calle"
                (ngModelChange)="changeComplementDir($event)">
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Calle</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('numExt')">
        <span class="p-float-label">
            <input id="fl-numext" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.numExt"
                (ngModelChange)="changeComplementDir($event)">
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Número exterior</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('numInt')">
        <span class="p-float-label">
            <input id="fl-numint" type="text" pInputText [pKeyFilter]=Alphanumeric [(ngModel)]="item.numInt"
                (ngModelChange)="changeComplementDir($event)">
            <label for="fl-numint" class="label-full">Número interior</label>
        </span>
    </div>

</div>
<p-toast></p-toast>