import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { Checador } from 'src/app/api/checador/checador';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChecadorRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msChecador;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();

    paths.remove = "remove";

    return paths;
  }

  cargarExcel(file: any, idUserEmp: number): Observable<any> {
    let path = this.getBaseEndpoint() + "cargar-excel";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const formData = new FormData();
    formData.append("file", file);

    const params = new HttpParams()
      .append("idUserEmp", idUserEmp.toString());

    return this.httpClient.post(path, formData, { headers, params });
  }

  porRevisar(fecInicio: string, fecFinal: string): Observable<Checador[]> {
    let path = this.getBaseEndpoint() + "por-revisar";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("fecha-inicio", fecInicio)
      .append("fecha-fin", fecFinal);

    return this.httpClient.get(path, { headers, params }) as Observable<Checador[]>;
  }

  generarDescuento(item: any): Observable<Message> {
    let path = this.getBaseEndpoint() + "generar-descuento";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, item, { headers }) as Observable<Message>;
  }

  validarAsistencia(idChecador: number): Observable<Message> {
    let path = this.getBaseEndpoint() + "validar-asistencia/" + idChecador;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<Message>;
  }
}
