import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMessages, BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Contratacion } from 'src/app/api/contratacion/contratacion';
import { ContratacionReg } from 'src/app/api/contratacion/contratacion-reg';
import { DireccionDet } from 'src/app/api/direccion/direccion-det';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-contrato-preview',
  templateUrl: './contrato-preview.component.html',
  styleUrls: ['./contrato-preview.component.scss']
})
export class ContratoPreviewComponent extends BaseView implements OnInit {

  @Input() item: ContratacionReg;
  @Input() direccion: DireccionDet;

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) { 
      super(screenModeService, deviceService, messageService, sessProvider);
    }

  ngOnInit(): void {
    if(!this.item){
      this.item = {}
    }

  }

  estadoCivil(val:string){
    switch(val){
      case 'S':
        return 'Soltero';
      case 'C':
        return 'Casado';
      default:
        return '';
    }
  }

  genero(val: string){
    switch(val){
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Femenino';
      default:
        return '';
    }
  }

  

}
