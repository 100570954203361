import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';

@Component({
  selector: 'axks-empleado-foto',
  templateUrl: './empleado-foto.component.html',
  styleUrls: ['./empleado-foto.component.scss']
})
export class EmpleadoFotoComponent extends BaseView implements OnInit {

  @Input() idEmpleado: number;
  @Input() showDialog: boolean;

  @Output() ocultarDialog = new EventEmitter<boolean>();

  fotosCargadas: any[] = [];
  foto: any;
  fotoExistente: boolean;
  myLoadContent: boolean;

  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private clientFoto: EmpleadoRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.fotosCargadas) {
      this.fotosCargadas = [];
    }

    this.myLoadContent = false;
    setTimeout(() => {
      this.existeFoto(this.idEmpleado);
    }, 100);
  }

  subirFoto(event) {
    this.showProgress = true;
    this.clientFoto.cargarFoto(this.idEmpleado, event.files[0]).subscribe(
      (data) => {
        this.showProgress = false;
        this.successMessage("Foto guardada", "Se guardo la foto correctamente");
        setTimeout(() => {
          this.ngOnInit();
        }, 100);
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  obtenerFoto(idEmpleado: number) {
    this.showProgress = true;
    this.clientFoto.obtenerFoto(idEmpleado).subscribe(
      (data) => {
        this.foto = this.convertirBlobEnImagen(data);
        this.showProgress = false;
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  convertirBlobEnImagen(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.foto = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  existeFoto(idEmpleado: number) {
    this.myLoadContent = false;
    this.clientFoto.existeFoto(idEmpleado).subscribe(
      (data) => {
        this.fotoExistente = data;
        this.myLoadContent = true;

        if (this.fotoExistente == true) {
          this.obtenerFoto(this.idEmpleado);
        }
      },
      (error) => {
        this.myLoadContent = true;
        this.errorMessage("Error", "No se pudo validar si la foto existe o no");
      }
    );
  }

  ocultarVentana() {
    this.ocultarDialog.emit(false);
  }

}
