import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Message } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Puesto } from 'src/app/api/puesto';
import { PuestoService } from 'src/app/procs/puesto/puesto.service';
import { MessageCodes } from '@axks/components';

@Component({
  selector: 'axks-puesto-cat',
  templateUrl: './puesto-cat.component.html',
  styleUrls: ['./puesto-cat.component.scss'],
  providers: [MessageService]
})
export class PuestoCatComponent implements OnInit, OnChanges {

  catalog: Puesto[];
  @Input() value?: number;

  @Input() idArea: number;

  @Output() onValueChange = new EventEmitter<Puesto>();

  virtualScroll : boolean = false;

  constructor(private arestps: PuestoService,
    private messages: MessageService) { }


  ngOnInit(): void {
    //this.populate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* try{
      if(changes.idArea.currentValue !== changes.idArea.previousValue){
        this.value = undefined;
        this.catalog = [] as Puesto[];
      }
    }catch(e){} */
    
    this.populate();
  }

  populate() {
    //this.value = null;
    let filter = {} as Puesto;
    
    filter.idAreaLaboral = this.idArea;

    this.arestps.find(filter).subscribe(
      (data) => {
        
        this.catalog = data;
        if(this.catalog.length>10){
          this.virtualScroll = true;
        }

        let puesto = this.catalog.find(c => (c.id == this.value));

        if(!puesto) {
          this.value = null;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
        
          this.processMessage(message);
        } catch (e) {
       
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron puesto en está Área. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({severity:sevMes, summary: "Catálogo de puestos", detail: messText});
  }

  onChange(event){
    let puest = {
      id: this.value
    } as Puesto;

    let puesto = this.catalog.find(tc => (tc.id == this.value));

    if(puesto && puesto.id === this.value){
      puest.puesto = puesto.puesto
    }

    this.onValueChange.emit(puest);
  }
  

}
