import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContrVencerLiderService {

  constructor(private httpClient: HttpClient) { }

  listaLider(idLider: number): Observable<any>{
     //let prueba = idLider+22
    let path = environment.msDashboardLiderRev + "/" + idLider;
  
    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, {headers});
  }

}
