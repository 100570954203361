import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent, BaseMainComponent, BaseMessages, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoContrato } from 'src/app/api/tipo-contrato';
import { TipoContratoPsService } from 'src/app/procs/tipo-contrato/tipo-contrato-ps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-tipo-contrato',
  templateUrl: './tipo-contrato.component.html',
  styleUrls: ['./tipo-contrato.component.scss'],
  providers: [MessageService]
})
export class TipoContratoComponent extends BaseMainComponent<TipoContratoPsService> implements OnInit {


  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private psService: TipoContratoPsService) {
    super(deviceService, messageService, screenModeService, sessProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let profile = this.getProfile() as ProfileC;
    return {
      registerItem: {
        idEmpresa: profile.empresa,
        empresa: {id: profile.empresa}
      } as TipoContrato,
      minimumItem: {
        idEmpresa: profile.empresa,
        empresa: {id: profile.empresa}
      } as TipoContrato,
      item: {
        idEmpresa: profile.empresa,
        empresa: {id: profile.empresa}
      } as TipoContrato
    } as ComponentItem;
  }
  getService(): TipoContratoPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'id', inDetail: true, inResultList: true },
      { header: 'Tipo de contrato', field: 'tipoContrato', inAll: true },
      /* {header:'Empresa', field:'empresa.nombre', inDetail:true, inResultList:true} */
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Tipo de contrato";
  }

}
