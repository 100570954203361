import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { PeriodoPago } from 'src/app/api/contratacion/periodo-pago';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { PeriodoPagoRestcService } from 'src/app/client/contratacion/periodo-pago-restc.service';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { SueldoItem } from '../../../api/empleado/sueldo';
import { SueldoRestcService } from '../../../client/sueldo/sueldo-restc.service';
import { CurrencyPipe } from '@angular/common';
import { AjusteSueldo } from '../../../api/ajuste-sueldo/ajuste-sueldo';

@Component({
  selector: 'axks-ajuste-sueldo',
  templateUrl: './ajuste-sueldo.component.html',
  styleUrls: ['./ajuste-sueldo.component.scss']
})
export class AjusteSueldoComponent extends BaseView implements OnInit {

  catEmpleados: EmpleadoNombre[];
  catPeriodos: PeriodoPago[];
  item: SueldoItem;
  sueldos: SueldoItem[];
  nombreEmpleado: string;
  isLoading: boolean = false;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoRestcService,
    private clientSueldo: SueldoRestcService,
    private clientPeriodo: PeriodoPagoRestcService,
    private confirmationService: ConfirmationService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idUsuarioInactividad: this.profile.idEmpleado
      }
    }
    this.initCatEmpleados();
    this.initCatPeriodos();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de empleados activos");
      }
    );
  }

  initCatPeriodos() {
    this.clientPeriodo.search({}).subscribe(
      (data) => {
        this.catPeriodos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de periodos de pago");
      }
    );
  }

  showSueldos(event) {
    this.nombreEmpleado = event.originalEvent.target.innerText;
    this.searchSueldo(event.value);
  }

  searchSueldo(idEmpleado: number) {
    this.showProgress = true;
    this.sueldos = [];
    let filtro = {} as SueldoItem;
    filtro.idEmpleado = idEmpleado;
    this.clientSueldo.search(filtro).subscribe(
      (data) => {
        this.sueldos = data;
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el historico de sueldos");
        this.showProgress = false;
      }
    );
  }

  guardarSueldo() {
    if (this.item && this.item.idEmpleado && this.item.sueldo && this.item.sueldoDiario && this.item.fechaAplica) {
      if (this.item.sueldo > 1 && this.item.sueldoDiario > 1) {
        this.isLoading = true;
        this.clientSueldo.addElement(this.item).subscribe(
          (data) => {
            this.isLoading = false;
            this.searchSueldo(this.item.idEmpleado);
            setTimeout(() => {
              this.item = {};
            }, 500);
            this.successMessage("Listo", "Sueldo actualizado correctamente");
          },
          (error) => {
            this.isLoading = false;
            this.errorMessage("Error", "No se pudo actualizar el sueldo");
          }
        );
      }
      else {
        this.infoMessage("Sueldo invalido", "Favor de poner un sueldo valido");
      }
    }
    else {
      this.infoMessage("Campos faltantes", "Favor de revisar que esten llenos todos los campos");
    }
  }

  borrarSueldo(value) {
    this.showProgress = true;

    this.clientSueldo.delete(value.idSueldo, value.idEmpleado).subscribe(
      (data) => {
        this.showProgress = false;
        this.searchSueldo(value.idEmpleado);
        setTimeout(() => {
          this.item = {};
        }, 500);
        this.successMessage(data.message, data.details);
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  confirmarAjuste() {
    this.messageService.clear();
    if (this.item.idEmpleado && this.item.sueldo && this.item.sueldoDiario) {
      let empEncontrado = this.catEmpleados.find(emp => emp.id == this.item.idEmpleado);
      let nombreEmpleado = empEncontrado ? empEncontrado.nombreCompleto : "";
      this.confirmationService.confirm({
        header: 'Confirmar operación',
        message: '¿Estas seguro de querer ajustar el sueldo de ' + nombreEmpleado + ' a ' + this.formatoMoneda(this.item.sueldo) + '?' +
          ' Una vez realizado no se podra deshacer la operación.',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ajustar sueldo',
        acceptButtonStyleClass: 'p-button-warning',
        acceptIcon: 'pi pi-save',
        rejectLabel: 'Cancelar',
        rejectIcon: 'pi pi-ban',
        accept: () => {
          this.guardarSueldo();
        },
        reject: (type) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              break;
            case ConfirmEventType.CANCEL:
              break;
          }
        }
      });
    }
    else {
      this.warningMessage("Información faltante", "Favor de seleccionar un empleado y definir un sueldo para continuar")
    }
  }

  confirmarBorrado(value) {
    this.confirmationService.confirm({
      header: 'Confirmar operación',
      message: '¿Estas seguro de querer borrar el sueldo seleccionado?' +
        ' Una vez realizado no se podra deshacer la operación y se tendra que volver a generar el sueldo.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Borrar',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
      rejectLabel: 'Cancelar',
      rejectIcon: 'none',
      accept: () => {
        this.borrarSueldo(value);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      }
    });
  }

  limpiarDatos() {
    this.item = {};
    this.sueldos = [];
  }

  formatoMoneda(value: any): string {
    let pipe = new CurrencyPipe("es-MX");
    return pipe.transform(value, "MXN", "$");
  }

}
