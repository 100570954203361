import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Checador } from 'src/app/api/checador/checador';
import { ChecadorPsService } from 'src/app/procs/checador/checador-ps.service';
import { TipoDescuentoPsService } from 'src/app/procs/tipo-descuento/tipo-descuento-ps.service';
import { TipoDescuento } from '../../../api/tipo-descuento';
import { faCalendarCheck, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { TipoDescuentoEnum } from '../../../enums/tipo-descuento.enum';

@Component({
  selector: 'axks-asistencia-revisar',
  templateUrl: './asistencia-revisar.component.html',
  styleUrls: ['./asistencia-revisar.component.scss']
})
export class AsistenciaRevisarComponent extends BaseView implements OnInit {

  fechaInicial: string;
  fechaFinal: string;
  items: Checador[];
  yearRange: string;
  rangoFechas: Date[];
  tpDescuentos: TipoDescuento[];
  showDialog: boolean;
  item: any;
  isLoading: boolean;
  iconDollar = faDollarSign;
  iconCalendarCheck = faCalendarCheck;

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private checadorRestc: ChecadorPsService,
    private tpDescRestc: TipoDescuentoPsService
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    this.initYearRange();
    this.initCatTipoDescuento();

    setTimeout(() => {
      this.configTituloReportes();
    }, 50);

    this.showDialog = false;
    this.item = {};
  }

  initYearRange() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 30) + ':' + (year + 5);
  }

  initCatTipoDescuento() {
    this.tpDescuentos = [];
    this.tpDescRestc.find({}).subscribe(
      (data) => {
        let tpDescs = data as TipoDescuento[];
        for (let item of tpDescs) {
          switch (item.idTipoDescuento) {
            case TipoDescuentoEnum.INASISTENCIA:
            case TipoDescuentoEnum.RETARDO:
              this.tpDescuentos.push(item);
              break;
            default:
              break;
          }
        }
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catálogo de tipos de descuento");
      }
    );
  }

  initAsistenciasRevisar() {
    this.messageService.clear();
    if (this.rangoFechas && this.rangoFechas[0] && this.rangoFechas[1]) {
      this.configRangoFechas(this.rangoFechas);
      this.showProgress = true;
      // console.log("Se ejecuta busqueda");
      this.checadorRestc.porRevisar(this.fechaInicial, this.fechaFinal).subscribe(
        (data) => {
          this.items = data;
          if (this.items.length == 0) {
            this.infoMessage("Sin registros", "No hay asistencias por revisar en este periodo de tiempo");
          }
          this.showProgress = false;
        },
        (error) => {
          this.errorMessage("Error", "No se pudo consultar las asistencias por revisar.");
          this.showProgress = false;
        }
      );
    }
    else {
      this.warningMessage("Datos faltantes", "Se debe seleccionar una fecha inicial y final antes de poder consultar las asistencias por revisar.");
    }
  }

  /**
  * Metodo para configurar las fechas iniciales y finales
  */
  configRangoFechas(rangoFechas: Date[]) {
    // Se genera la fecha inicial del evento recuperado del componente.
    let dateIni = rangoFechas[0];
    let dateFin = rangoFechas[1];

    // Se recuperan las fechas con formato
    this.fechaInicial = formatDate(dateIni, "yyyy-MM-dd '00:00:00'", 'es-MX');
    this.fechaFinal = formatDate(dateFin, "yyyy-MM-dd '23:59:59'", 'es-MX');
  }

  configTituloReportes() {
    this.tituloReportes.emit("Asistencias por revisar");
  }

  mostrarVentana(value: any) {
    this.showDialog = true;
    this.item = value;
    this.item.idUserEmp = this.profile.idEmpleado;
  }

  generarDescuento() {
    if (this.item && this.item.idTipoDescuento && this.item.monto && this.item.fecDescuento) {
      this.isLoading = true;
      this.messageService.clear();
      this.checadorRestc.generarDescuento(this.item).subscribe(
        (data) => {
          this.successMessage(data.message, data.details);
          setTimeout(() => {
            this.isLoading = false;
            this.showDialog = false;
            this.item = {
              idUserEmp: this.profile.idEmpleado
            };
            this.initAsistenciasRevisar();
          }, 500);
        },
        (error) => {
          this.isLoading = false;
          this.errorMessage("Error", error.error.message);
        }
      );
    }
    else {
      this.messageService.clear('msg-dialog');
      this.messageService.add({
        summary: "Datos faltantes", 
        detail: "Favor de llenar todos los campos",
        severity: 'warn',
        key: 'msg-dialog'
      });
    }
  }

  validarAsistencia(value: Checador) {
    this.showProgress = true;
    this.messageService.clear();
    this.checadorRestc.validarAsistencia(value.id).subscribe(
      (data) => {
        this.showProgress = false;
        this.successMessage(data.message, data.details);
        setTimeout(() => {
          this.initAsistenciasRevisar();
        }, 1000);
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  exportExcel() {
    let itemsExcel = [];
    if (this.items.length > 0) {
      for(let item of this.items) {
        itemsExcel.push({
          NO_REGISTRO: item.id,
          NO_EMPLEADO: item.idEmpleado.id,
          EMPLEADO: item.idEmpleado.nombreCompleto,
          ENTRADA: item.fecEntrada,
          SALIDA: item.fecSalida,
          LUGAR_TRABAJO: item.sucursal
        });
      }
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(itemsExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Asistencias-por-revisar");
      });
    }
    else {
      this.infoMessage("Sin elementos", "No hay elementos para exportar a excel");
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) +
        "-" + (mes < 10 ? "0" + mes : mes) +
        "-" + now.getFullYear() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }
}