import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { AjusteSueldoPsService } from 'src/app/procs/ajuste-sueldo/ajuste-sueldo-ps.service';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { AjusteSueldo } from 'src/app/api/ajuste-sueldo/ajuste-sueldo';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { faBroom } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'axks-reportes-ajuste-sueldo',
  templateUrl: './reportes-ajuste-sueldo.component.html',
  styleUrls: ['./reportes-ajuste-sueldo.component.scss']
})
export class ReportesAjusteSueldoComponent extends BaseView implements OnInit {

  filtro: AjusteSueldo;
  fechaFinal: string;
  items: AjusteSueldo[];
  yearRange: string;
  catEmpleados: EmpleadoNombre[];
  faBroom = faBroom;

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private clientRestc: AjusteSueldoPsService,
    private clientEmp: EmpleadoRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    this.initYearRange();
    this.initCatEmpleados();
    setTimeout(() => {
      this.initCargaReportes();
    }, 500);

    setTimeout(() => {
      this.configTituloReportes();
    }, 50);

    if(!this.filtro) {
      this.filtro = {
        key: {}
      };
    }
  }

  initYearRange() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 30) + ':' + (year + 5);
  }

  initCatEmpleados() {
    this.clientEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de empleados activos");
      }
    );
  }


  initCargaReportes() {
    this.messageService.clear();
    if (this.filtro.fecInicio || this.filtro.fecFin) {
      if (this.filtro.fecInicio && this.filtro.fecFin) {
        this.llamarServicio();
      }
      else{
        this.infoMessage("Rango de fechas incompleto", "Se deben seleccionar la fecha inicial y final para hacer una busqueda por rango de fechas")
      }
    }
    else {
      this.llamarServicio();
    }
  }

  limpiarFiltros() {
    this.filtro = {
      key: {}
    };
  }

  llamarServicio() {
    this.showProgress = true;
    this.clientRestc.find(this.filtro).subscribe(
      (data) => {
        this.items = data;
        if (this.items.length == 0) {
          this.infoMessage("Sin registros", "No hay vacaciones que expiren en este periodo de tiempo");
        }
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar las vacaciones.");
        this.showProgress = false;
      }
    );
  }

  configTituloReportes() {
    this.tituloReportes.emit("Ajustes de sueldo");
  }

  exportExcel() {
    if (this.items.length > 0) {
      import("xlsx").then(xlsx => {
        let itemsExcel = [];
        for(let item of this.items) {
          itemsExcel.push({
            NUM_EMPLEADO: item.key.idEmpleado,
            EMPLEADO: item.empleado,
            PERIODOS: (item.periodoAnterior ? item.periodoAnterior : "") +" → "+ item.periodoNuevo,
            AJUSTE_SUELDO: this.formatoMoneda(item.sueldoAnterior) + " → " + this.formatoMoneda(item.nuevoSueldo),
            FECHA_APLICACION: this.formatoFecha(item.fecAplica)
          });
        }
        const worksheet = xlsx.utils.json_to_sheet(itemsExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Ajustes-de-sueldo");
      });
    }
    else {
      this.infoMessage("Sin elementos", "No hay elementos para exportar a excel");
    }
  }

  formatoMoneda(value: any): string {
    if(!value) value = 0;
    let pipe = new CurrencyPipe("es-MX");
    return pipe.transform(value, "MXN", "$");
  }

  formatoFecha(value: any): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) +
        "-" + (mes < 10 ? "0" + mes : mes) +
        "-" + now.getFullYear() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

}

