import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OrganigramaPsService } from '../../../procs/organigrama/organigrama-ps.service';
import { Organigrama } from '../../../api/organigrama/organigrama';
@Component({
  selector: 'axks-banca-liberacion-recursos',
  templateUrl: './banca-liberacion-recursos.component.html',
  styleUrls: ['./banca-liberacion-recursos.component.scss']
})
export class BancaLiberacionRecursosComponent extends BaseView implements OnInit {

  empleados: Organigrama[];

  @Output() tituloReportes = new EventEmitter<String>();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private restcService: OrganigramaPsService) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    this.initEmpleadosAsignados();
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initEmpleadosAsignados() {
    this.showProgress = true;
    this.restcService.recursosPorLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.empleados = data;
        if (this.empleados.length == 0) {
          this.infoMessage("Sin registros", "No hay colaboradores a su cargo");
        }
        this.showProgress = false;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar los colaboradores asignados.");
        this.showProgress = false;
      }
    );
  }

  liberarEmpleado(item: Organigrama) {
    item.idUserEmp = this.profile.idEmpleado;
    this.showProgress = true;
    this.restcService.liberarRecurso(item).subscribe(
      (data) => {
        this.showProgress = false;
        let msg = data as Message;
        this.successMessage(msg.message, msg.details);
        setTimeout(() => {
          this.initEmpleadosAsignados();
        }, 1000);
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  configTituloReportes() {
    this.tituloReportes.emit("Colaboradores asignados");
  }

  exportExcel() {
    if (this.empleados.length > 0) {
      import("xlsx").then(xlsx => {
        let empleadosExcel = [];
        for(let emp of this.empleados) {
          empleadosExcel.push({
            ID_COLABORADOR: emp.idEmpleado,
            COLABORADOR: emp.nombreEmpleado,
            FEC_INI_ASIGNACION: emp.fecIniAsigna,
            ID_LIDER: emp.idJefeInmediato,
            LIDER: emp.jefeInmediato,
          });
        }
        const worksheet = xlsx.utils.json_to_sheet(empleadosExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Colaboradores-asignados");
      });
    }
    else {
      this.infoMessage("Sin elementos", "No hay elementos para exportar a excel");
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()) +
        "-" + (mes < 10 ? "0" + mes : mes) +
        "-" + now.getFullYear() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }


}
