import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Persona } from 'src/app/api/contratacion/persona';

@Component({
  selector: 'axks-personales-form',
  templateUrl: './personales-form.component.html',
  styleUrls: ['./personales-form.component.scss']
})
export class PersonalesFormComponent extends BaseView implements OnInit, AfterContentInit {

  @Input() item: Persona;
  @Input() rfc: string;
  @Output() rfcChange = new EventEmitter();

  op = OperationMode;

  genders: any[];
  civilStatus: any[];

  yearRange: any;

  Alphabetic = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|1234567890*¬¸~─,¨:;-]/;
  Alphanumeric = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
      } as Persona;
    }

    if (!this.item.nacionalidad) {
      this.item.nacionalidad = 'Mexicana'
    }

    if(this.rfc) {
      this.item.rfc = this.rfc;
    }

    nmprs: ['', [Validators.required]]

    this.initGendersCat();
    this.initDates();
    this.initCivilStatus();
  }

  ngAfterContentInit(): void {
    window.scroll(0, 0)
  }

  initGendersCat() {
    this.genders = [{
      label: 'Masculino', value: 'M'
    }, {
      label: 'Femenino', value: 'F'
    }
    ];
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 100) + ':' + (year - 18);
  }

  initCivilStatus() {
    this.civilStatus = [
      { label: 'Soltero/a', value: 'S' },
      { label: 'Casado/a', value: 'C' },
      { label: 'Divorciado/a', value: 'S' }
    ]
  }

  funcionRfc() {
    this.rfcChange.emit(this.rfc);
  }

}
