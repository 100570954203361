<!-- {{item|json}} -->
<div style="padding-inline: 25vw;" *ngIf="esRenovacion == true">
    <br>
    <div class="p-grid style-toolbar">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <span style="font-weight: bold; font-size: 0.9rem;">Datos de renovación sugeridos por el lider</span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <span style="font-weight: bold;">Tipo de contrato:
            </span><span>{{prerenovacion.tipoContrato.tipoContrato}}</span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <span style="font-weight: bold;">Duración del contrato: </span>
            <span *ngIf="prerenovacion.tipoContrato.id == tipoContrato.PLANTA">Indefinido</span>
            <span *ngIf="prerenovacion.tipoContrato.id == tipoContrato.PROYECTO">{{prerenovacion.dias}} días</span>
        </div>
    </div>
    <br>
</div>

<div class="p-grid frm-part" *ngIf="item">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('tipo')">
        <axks-tipo-contrato-cat [(value)]="item.idTipoContrato" [idEmpresa]="item.idEmpresa"
            (onValueChange)="onValueChange($event)"></axks-tipo-contrato-cat>
    </div>
   
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecContrat')">
        <span class="p-float-label">
            <p-calendar id="fl-feccont" [(ngModel)]="item.fecContratacion" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [yearRange]="yearRange" [monthNavigator]="true" dataType="string" appendTo="body"  ></p-calendar>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Fecha de contratación</label>
            <!--  {{item.fecContratacion}} -->
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecTerContrat') && item.tipoContrato !='Planta' ">
        <span class="p-float-label">
            <p-calendar id="fl-feccont" [(ngModel)]="item.fecTerminoContrato" dateFormat="yy/mm/dd"
                [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true" dataType="string" appendTo="body"  ></p-calendar>
            <label for="fl-nmemp" class="label-full">Fecha fin de contratación</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idPeriodoPago')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tcnm" [(ngModel)]="item.idPeriodoPago" [options]="catPeriodopago"
                optionLabel="periodoPago" optionValue="id" [autoDisplayFirst]="false" [style]="{'width':'100%'}"
                (onChange)="onValueChangePeriodo($event)"  appendTo="body" emptyMessage="Sin resultados"></p-dropdown>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Periodo de Pago</label>
        </span>
        <!-- {{item.idPeriodoPago|json}} -->
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('sueldo') && item.idPeriodoPago">
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.sueldo" inputId="currency-us" mode="currency" currency="MXN"
                locale="es-MX">
            </p-inputNumber>
            <label for="fl-coment" *ngIf="item.idPeriodoPago == PERIODO_PAGO.SEMANAL" class="label-full"><span [style]="{'color':'red'}">*</span> Sueldo semanal</label>
            <label for="fl-coment" *ngIf="item.idPeriodoPago != PERIODO_PAGO.SEMANAL" class="label-full"><span [style]="{'color':'red'}">*</span> Sueldo mensual</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('sueldoDiario') && item.idPeriodoPago">
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.sueldoDiario" inputId="currency-us" mode="currency" currency="MXN"
                locale="es-MX">
            </p-inputNumber>
            <label for="fl-coment"><span [style]="{'color':'red'}">*</span> Sueldo diario</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('imss')">
        <p-checkbox [(ngModel)]="item.imss" binary="true" inputId="imss"></p-checkbox>
        &nbsp; <label for="imss" class="form-field-label">IMSS</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('infonavit')">
        <p-checkbox [(ngModel)]="infonavit" binary="true" inputId="infonavit" (click)="funcionInfonavit()"></p-checkbox>
        &nbsp; <label for="infonavit" class="form-field-label">INFONAVIT</label>
    </div>

</div>
<p-toast></p-toast>