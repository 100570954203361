<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('area')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-area" [(ngModel)]="item.idAreaLaboral" [options]="areas" optionLabel="area"
                optionValue="id" [filter]="true" filterBy="area" [autoDisplayFirst]="false" [baseZIndex]="20000"
                appendTo="body"></p-dropdown>
            <label for="fl-area">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">*</span>
                Área
            </label>
        </span>

    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('puesto')">
        <span class="p-float-label">
            <input id="fl-puesto" type="text" pInputText [(ngModel)]="item.puesto">
            <label for="fl-puesto">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">*</span>
                Puesto
            </label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('salario')">
        <span class="p-float-label">
            <p-inputNumber inputId="fl-sueldo" [(ngModel)]="item.salario" mode="currency" currency="MXN" locale="es-MX">
            </p-inputNumber>
            <label for="fl-salario">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">*</span>
                Salario
            </label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('descripcion')">
        <span class="p-float-label">
            <input id="fl-descrip" type="text" pInputText [(ngModel)]="item.descripcion">
            <label for="fl-descrip">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">*</span>
                Descripción
            </label>
        </span>
    </div>
</div>