import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DireccionDet } from 'src/app/api/direccion/direccion-det';
import { EmpleadoDetalle } from '../../../api/empleado/empleado-detalle';
import { TipoContacto } from 'src/app/enums/tipo-contacto.enum';


@Component({
  selector: 'axks-empleado-form',
  templateUrl: './empleado-form.component.html'
})
export class EmpleadoFormComponent extends BaseView implements OnInit {

  @Input() item: EmpleadoDetalle = {};
  @Input() operationMode: OperationMode;

  @Output() emitEmpsInactivos = new EventEmitter<boolean>();

  opMode = OperationMode;
  TP_CONTACTO = TipoContacto;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        direcciones: [{}],
        contactos: [{}],
        puesto: {
          area: {}
        },
        mediosContacto: [],
        contrato: [{
          sueldo: [{}]
        }],
        datosBancarios: [{}]
      } as EmpleadoDetalle;
    }

    this.emitirEmpsInactivos({checked: false});
  }

  changeDir(event) {
    let dir = event as DireccionDet;
    this.item.direcciones = [
      {
        id: dir.id,
        calle: dir.calle,
        numExt: dir.numExt,
        numInt: dir.numInt,
        alcMun: dir.alcMun,
        ciudad: dir.ciudad,
        colonia: dir.colonia,
        cp: dir.cp,
        estado: dir.estado,
        pais: dir.pais
      }
    ];
  }

  onInfonavitChange(event) {
    this.item.infonavit = event;
  }

  setRFC(event) {
    this.item.rfc = event;
  }

  emitirEmpsInactivos(event){
    this.emitEmpsInactivos.emit(event.checked);
  }
}
