import { Injectable } from '@angular/core';

import { Area } from 'src/app/api/area';
import { BaseProcess, BaseService, Message } from '@axks/components'; 
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AreaRestcService } from 'src/app/client/area/area-restc.service';

@Injectable({
  providedIn: 'root'
})
export class AreaPsService extends BaseProcess<AreaRestcService>{

  constructor(httpClient: HttpClient,
    crest: AreaRestcService) { 
      super(httpClient, crest);
    }


}
