import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'axks-banca',
  templateUrl: './banca.component.html',
  styleUrls: ['./banca.component.scss']
})
export class BancaComponent extends BaseView implements OnInit {

  items = [] as any[];
  indexMenu: number;
  tituloReporte: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.indexMenu) {
      this.indexMenu = null;
    }
  }

  accederMenu(event) {
    this.tituloReporte = null;
    this.indexMenu = event;
  }

  setTituloReportes(event) {
    this.tituloReporte = event;
  }
}
