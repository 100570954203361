import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { LiderCat } from 'src/app/api/empleado/lider-cat';

@Component({
  selector: 'axks-historico-solicitudes-apoyo-form',
  templateUrl: './historico-solicitudes-apoyo-form.component.html',
  styleUrls: ['./historico-solicitudes-apoyo-form.component.scss']
})
export class HistoricoSolicitudesApoyoFormComponent extends BaseView implements OnInit {

  @Input() item: SolicitudApoyo;
  @Input() empleadosActivos: EmpleadoNombre[];
  @Input() lideres: LiderCat[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProv);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
  }
}
