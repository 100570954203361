import { Component, Input, OnInit } from '@angular/core';
import { OperationScreenModeService, SessionClientProvider, BaseView, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoChecador } from 'src/app/api/checador/checador';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';

@Component({
  selector: 'axks-empleado-checador-form',
  templateUrl: './empleado-checador-form.component.html',
  styleUrls: ['./empleado-checador-form.component.scss']
})
export class EmpleadoChecadorFormComponent extends BaseView implements OnInit {

  @Input() item: EmpleadoChecador;

  catEmpleados: EmpleadoNombre[];

  op = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.initCatEmpleados();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de empleados activos");
      }
    );
  }

}
