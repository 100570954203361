<div class="progress container" *ngIf="!loadContent ||showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>
<ng-container *ngIf="loadContent">
    <!-- {{item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Contratar nuevo Empleado" [addNewButton]="true"
        searchLabel="Buscar Contrato" [searchButton]="false" [sectionTitle]="getComponentTitle()"
        [refreshButton]="false">
    </axks-operations-bar>

    <axks-detail-tab *ngIf="showDetail()" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields">
    </axks-detail-tab>

    <axks-contrato-wizard #cmpForm id="idd" *ngIf="showWizard()" [operationMode]="operationMode" [item]="item.registerItem"
        (onSave)="onWizardSave($event)" (onCancel)="onWizardCancel($event)" [esRenovacion]="esRenovacion()"
        [class]="'cmp-form-' + operationMode" [prerenovacion]="prerenovacion"></axks-contrato-wizard>

    <axks-contrato-form #cmpForm *ngIf="!showWizard()" [item]="item.item" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode">
    </axks-contrato-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)"
        (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar" *ngIf="!showWizard()" [printButton]="false" [csvFileButton]="false" [pdfFileButton]="false"
        [txtFileButton]="false">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>