import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ContactoReg } from 'src/app/api/contacto/contacto-reg';
import { TipoContacto } from 'src/app/api/contacto/tipo-contacto';

@Component({
  selector: 'axks-contacto-form-telefono',
  templateUrl: './contacto-form-telefono.component.html',
  styleUrls: ['./contacto-form-telefono.component.scss']
})
export class ContactoFormTelefonoComponent extends BaseView implements OnInit {

  @Input() item: ContactoReg;
  @Input() tipoContacto: TipoContacto;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    if (!this.tipoContacto) {
      this.tipoContacto = {
        id: this.item.idTipoContacto
      }
    }
  }

}