import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Checador } from 'src/app/api/checador/checador';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { ChecadorPsService } from 'src/app/procs/checador/checador-ps.service';

@Component({
  selector: 'axks-asistencia-form',
  templateUrl: './asistencia-form.component.html',
  styleUrls: ['./asistencia-form.component.scss']
})
export class AsistenciaFormComponent extends BaseView{

  @Input() item: Checador;
  catEmpleados: EmpleadoNombre[];
  archivosCargados: any[] = [];

  @Output() onShowProgress = new EventEmitter<boolean>();
  @Output() onReloadMain = new EventEmitter<boolean>();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoRestcService,
    private clientCheck: ChecadorPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idEmpleado: {},
        idEmpLider: {}
      };
    }

    if (!this.archivosCargados) {
      this.archivosCargados = [];
    }


    this.initCatEmpleados();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de empleados activos");
      }
    );
  }

  subirExcel(event) {
    this.onShowProgress.emit(true);
    this.clientCheck.cargarExcel(event.files[0], this.profile.idEmpleado).subscribe(
      (data) => {
        this.onShowProgress.emit(false);
        this.successMessage("Excel subido", "Se guardo el archivo correctamente");
        setTimeout(() => {
          this.onReloadMain.emit(true);
        }, 500);
      },
      (error) => {
        this.onShowProgress.emit(false);
        this.errorMessage("Error", error.error.message);
      }
    );
  }

}
