<p-progressSpinner *ngIf="!loadContent || showProgress"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner>

<ng-container *ngIf="loadContent">
    <h2>Renovación de contratos para colaboradores</h2>
    <p-dataView #dv [value]="listaRenov" [paginator]="true" [rows]="9" filterBy="nombreEmpleado" layout="list">
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Buscar por nombre"
                        (input)="dv.filter($event.target.value)">
                </span>
            </div>
        </ng-template>
        <ng-template let-itemlider pTemplate="listItem">
            <div class="p-col-12">
                <div class="itemlider-list-item" style="padding-inline: 30px">
                    <div class="itemlider-list-detail">
                        <div class="itemlider-name">
                            <span style="font-weight: bold;">Nombre: </span>
                            {{itemlider.nombreEmpleado}}
                        </div><br>
                        <div class="itemlider-description">
                            <span style="font-weight: bold;">Estatus: </span>
                            {{itemlider.estatus}}
                        </div><br>
                        <div class="itemlider-description">
                            <span style="font-weight: bold;">Fecha inicio de contrato: </span>
                            {{itemlider.fecIniContrato | date:"dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" :
                            "es-MX"}}
                        </div>
                        <div class="itemlider-description">
                            <span style="font-weight: bold;">Fecha fin de contrato: </span>
                            {{itemlider.fecFinContrato | date:"dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" :
                            "es-MX"}}
                        </div>
                        <div class="itemlider-description">
                            <span style="font-weight: bold;">Tipo de contrato actual: </span>
                            {{itemlider.tipoContrato}}
                        </div>
                        <br>
                        <br>
                        <div class="itemlider-description">
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
                                    <axks-tipo-contrato-cat [(value)]="item.idTipoContrato" [idEmpresa]="item.idEmpresa"
                                        (onValueChange)="onValueChange($event)"></axks-tipo-contrato-cat>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.idTipoContrato == tipoContratoEnum.PROYECTO">
                            <br>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
                                    <div class="itemlider-description">
                                        <span class="p-float-label">
                                            <p-dropdown id="fl-dias" [options]="dia" [(ngModel)]="item.numDias"
                                                optionValue="code" [autoDisplayFirst]="false" optionLabel="name"
                                                [showClear]="true" [style]="{'width':'100%'}">
                                            </p-dropdown>
                                            <label for="fl-dias"><span [style]="{'color':'red'}">*</span> Duración del
                                                contrato</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-block: 20px;">
                        <div>
                            <p-button type="button" (click)="autorizar(itemlider)" label="Renovar"
                                styleClass="p-button-info"></p-button> &nbsp;
                            <p-button type="button" (click)="rechazar(itemlider)" label="No renovar"
                                styleClass="p-button-danger"></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</ng-container>
<p-toast></p-toast>