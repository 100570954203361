import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ContratosRenovacion } from '../../../api/contratos-renovacion';
import { ListContraVencLiderService } from '../../../client/list-contra-venc-lider/list-contra-venc-lider.service';
import { ContratoRenovacionReg } from '../../../api/contrato-renovacion/contrato-renovacion-reg';
import { ContratoRenovacion } from '../../../api/contrato-renovacion/contrato-renovacion';
import { AutorizaRenovacionRestcService } from '../../../client/autoriza-renovacion/autoriza-renovacion-restc.service';
import { TipoContratoPsService } from 'src/app/procs/tipo-contrato/tipo-contrato-ps.service';
import { TipoContrato } from 'src/app/api/enums/tipo-contrato.enum';

interface Dia {
  name: string,
  code: number
}

@Component({
  selector: 'axks-list-contr-renov-lider',
  templateUrl: './list-contr-renov-lider.component.html',
  styleUrls: ['./list-contr-renov-lider.component.scss']
})
export class ListContrRenovLiderComponent extends BaseView implements OnInit {

  @Input() value?: number;
  @Input() idEmpresa: number;
  @Input() item: ContratosRenovacion;

  dia: Dia[];
  items: ContratoRenovacion[];
  listaRenov: ContratosRenovacion[] = [];
  vistoBuenoLiderReg: ContratoRenovacionReg;
  autorizaRenovacion: ContratoRenovacionReg[] = [];
  idTipocontrato: number;
  profile = this.getProfile() as ProfileC;
  tipoContratoEnum = TipoContrato;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRenova: ListContraVencLiderService,
    private autorizaLiderRestc: AutorizaRenovacionRestcService,
    private arestps: TipoContratoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);

    this.dia = [
      { name: '60 días', code: 60 },
      { name: '90 días', code: 90 },
      { name: '120 días', code: 120 }
    ];
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes() {
    this.showProgress = true;
    this.listaRenova.register(this.profile.idEmpleado).subscribe(
      (data) => {
        this.listaRenov = data as ContratosRenovacion[];
        this.showProgress = false;
        if (this.listaRenov.length == 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin renovaciones', detail: 'No hay ningúna renovación de contrato por revisar' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener las renovaciones de contrato' });
      }
    );
  }

  onValueChange(event) {
    this.item.idTipoContrato = event.id;
    /* this.item.idBancoReceptor=event.id; */
    this.item["tipoContrato"] = event.tipoContrato;
    /*  this.item["bancoReceptor"]=event.bancoReceptor; */

    if (this.item.idTipoContrato == this.tipoContratoEnum.PLANTA) {
      this.item.numDias = null;
    }
  }

  autorizar(valor: any) {

    if (this.item.idTipoContrato != null || this.item.idTipoContrato != undefined) {
      if ((this.item.idTipoContrato == this.tipoContratoEnum.PROYECTO && this.item.numDias != null) || this.item.idTipoContrato == this.tipoContratoEnum.PLANTA) {
        this.showProgress = true;
        let aprovLider = {
          dias: this.item.numDias,
          id: {
            idEmpleado: valor.idEmpleado,
            idEmpresa: valor.idEmpresa,
          },
          idLider: this.profile.idEmpleado,
          idTipoContrato: this.item.idTipoContrato,
          renueva: true
        }

        this.autorizaLiderRestc.autorizaRenovacionLider(aprovLider).subscribe(
          (data) => {
            this.autorizaRenovacion = data as ContratoRenovacion[];
            this.showProgress = false;
            this.messageService.add({ severity: 'success', summary: 'Renovación aprovada', detail: 'Se envió la autorización de la renovación correctamente' });
            this.obtenerSolicitudes();
          },
          (error) => {
            this.showProgress = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar la autorización de la renovación de contrato' });
          }
        );
      }
      else {
        this.errorMessage("Falta de datos", "Favor de poner los datos requeridos");
      }
    }
    else {
      this.errorMessage("Falta de datos", "Favor de seleccionar el tipo de contrato");
    }
  }

  rechazar(valor: any) {
    let aprovLider = {
      id: {
        idEmpleado: valor.idEmpleado,
        idEmpresa: valor.idEmpresa
      },
      dias: null,
      idLider: this.profile.idEmpleado,
      idTipoContrato: null,
      renueva: false
    }
    this.showProgress = true;

    this.autorizaLiderRestc.autorizaRenovacionLider(aprovLider).subscribe(
      (data) => {
        this.autorizaRenovacion = data as ContratoRenovacionReg[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Renovación rechazada', detail: 'Se envió el rechazo de la renovación correctamente' });
        setTimeout(function () { location.reload(); }, 2000);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar el rechazo de la renovación de contrato' });
      }
    );
  }
}
