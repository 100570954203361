import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolRestcService extends ARestClient{

  constructor(client: HttpClient) { 
    super(client);
  }

  getBaseEndpoint(): string {
    return environment.msRoles +"/";
  }
  processBody(res: any) {
    return res || {};
  }

  

  
}
