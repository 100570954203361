import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorizaRenovacionRestcService {

  constructor(private httpClient: HttpClient) { }

  autorizaRenovacionLider(aprovLider: any): Observable<any>{
    let path = environment.msAutorizaLider;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, aprovLider, { headers });
  }

  autorizaRenovacionRH( aprovRH: any): Observable<any>{
    let path = environment.msAutorizaRH ;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path,  aprovRH, { headers });
  }

  /* defineContrato( contratoR: any): Observable<any>{
    let path = environment.msContratacion ;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, contratoR, { headers });
  } */
}
