import { Component, HostListener, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  version;
  constructor() { }

  ngOnInit(): void {
    this.version = environment.version;
    

  }

  

}
