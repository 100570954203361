import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ActionsBarModule, DetailModule, OperationsBarModule, ResultListModule, DetailTabModule, BaseMessages, SessionClientProvider } from '@axks/components';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { AreaComponent } from './views/area/area/area.component';
import { AreaFormComponent } from './views/area/area-form/area-form.component';
import { PuestoComponent } from './views/puesto/puesto/puesto.component';
import { PuestoFormComponent } from './views/puesto/puesto-form/puesto-form.component';
import { AreaCatComponent } from './views/area/area-cat/area-cat.component';
import { PuestoCatComponent } from './views/puesto/puesto-cat/puesto-cat.component';
import { TipoContratoComponent } from './views/tipo-contrato/tipo-contrato/tipo-contrato.component';
import { TipoContratoFormComponent } from './views/tipo-contrato/tipo-contrato-form/tipo-contrato-form.component';
import { TipoContratoCatComponent } from './views/tipo-contrato/tipo-contrato-cat/tipo-contrato-cat.component';
import { EmpleadoComponent } from './views/empleado/empleado/empleado.component';
import { EmpleadoFormComponent } from './views/empleado/empleado-form/empleado-form.component';
import { DireccionFormComponent } from './views/direccion/direccion-form/direccion-form.component';
import { ContactoFormComponent } from './views/contacto/contacto-form/contacto-form.component';
import { ContratoFormComponent } from './views/contrato/contrato-form/contrato-form.component';
import { TipoContactoCatComponent } from './views/tipo-contacto/tipo-contacto-cat/tipo-contacto-cat.component';
import { ContratoComponent } from './views/contrato/contrato/contrato.component';
import { StepsModule } from 'primeng/steps';
import { ContratoWizardComponent } from './views/contrato/contrato-wizard/contrato-wizard.component';
import { ButtonModule } from 'primeng/button';
import { PersonalesFormComponent } from './views/empleado/personales-form/personales-form.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HomeComponent } from './views/home/home.component';
import { OrganigramaFormComponent } from './views/contrato/organigrama-form/organigrama-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { VacacionesDashComponent } from './views/dashboard/vacaciones-dash/vacaciones-dash.component';
import { ChartModule } from 'primeng/chart';
import { ContratoPreviewComponent } from './views/contrato/contrato-preview/contrato-preview.component';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CuentaFormComponent } from './views/cuenta/cuenta-form/cuenta-form.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import localeEsMx from '@angular/common/locales/es-MX';
import { SolicitudesColaboradoresComponent } from './views/dashboard/solicitudes-colaboradores/solicitudes-colaboradores.component';
import { environment } from 'src/environments/environment';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { ContratoRenovacionComponent } from './views/contrato/contrato-renovacion/contrato-renovacion.component';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ListContrRenovLiderComponent } from './views/contrato/list-contr-renov-lider/list-contr-renov-lider.component';
import { ListContrRenovRhComponent } from './views/contrato/list-contr-renov-rh/list-contr-renov-rh.component';
import { ListboxModule } from 'primeng/listbox';
import { DashboardContratoFinRhComponent } from './views/contrato/dashboard-contrato-fin-rh/dashboard-contrato-fin-rh.component';
import { DashboardContratoFinLiderComponent } from './views/contrato/dashboard-contrato-fin-lider/dashboard-contrato-fin-lider.component';
import { OrganigramaEmpFormComponent } from './views/contrato/organigrama-emp-form/organigrama-emp-form.component';
import { ContratoEmpFormComponent } from './views/contrato/contrato-emp-form/contrato-emp-form.component';
import { AccordionModule } from 'primeng/accordion';
import { EmpleadoVistaComponent } from './views/empleado/empleado-vista/empleado-vista.component';
import { EmpleadoBajaComponent } from './views/empleado/empleado-baja/empleado-baja.component';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { BlockUIModule } from 'primeng/blockui';
import { ReportesMenuComponent } from './views/reportes/reportes-menu/reportes-menu.component';
import { ReportesComponent } from './views/reportes/reportes/reportes.component';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { ReportesContratosComponent } from './views/reportes/reportes-contratos/reportes-contratos.component';
import { ReportesVacacionesComponent } from './views/reportes/reportes-vacaciones/reportes-vacaciones.component';
import { FileUploadModule } from 'primeng/fileupload';
import { EmpleadoFotoComponent } from './views/empleado/empleado-foto/empleado-foto.component';
import { ReportesTemporalesComponent } from './views/reportes/reportes-temporales/reportes-temporales.component';
import { AjusteSueldoComponent } from './views/sueldo/ajuste-sueldo/ajuste-sueldo.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BancaComponent } from './views/banca/banca/banca.component';
import { BancaLiberacionRecursosComponent } from './views/banca/banca-liberacion-recursos/banca-liberacion-recursos.component';
import { BancaMenuComponent } from './views/banca/banca-menu/banca-menu.component';
import { BancaNoAsignadosComponent } from './views/banca/banca-no-asignados/banca-no-asignados.component';
import { SolicitudesApoyoFormComponent } from './views/banca/solicitudes-apoyo/solicitudes-apoyo-form/solicitudes-apoyo-form.component';
import { SolicitudesApoyoComponent } from './views/banca/solicitudes-apoyo/solicitudes-apoyo/solicitudes-apoyo.component';
import { SolicitudesPorAutorizarComponent } from './views/banca/solicitudes-por-autorizar/solicitudes-por-autorizar.component';
import { HistoricoSolicitudesApoyoFormComponent } from './views/banca/historico-solicitudes-apoyo/historico-solicitudes-apoyo-form/historico-solicitudes-apoyo-form.component';
import { HistoricoSolicitudesApoyoComponent } from './views/banca/historico-solicitudes-apoyo/historico-solicitudes-apoyo/historico-solicitudes-apoyo.component';
import { ContactoFormTelefonoComponent } from './views/contacto/contacto-form-telefono/contacto-form-telefono.component';
import { AsistenciaFormComponent } from './views/asistencia/asistencia-form/asistencia-form.component';
import { AsistenciaComponent } from './views/asistencia/asistencia/asistencia.component';
import { EmpleadoChecadorFormComponent } from './views/asistencia/empleado-checador-form/empleado-checador-form.component';
import { EmpleadoChecadorComponent } from './views/asistencia/empleado-checador/empleado-checador.component';
import { AsistenciaRevisarComponent } from './views/asistencia/asistencia-revisar/asistencia-revisar.component';
import { AsistenciaIndexComponent } from './views/asistencia/asistencia-index/asistencia-index.component';
import { AsistenciaMenuComponent } from './views/asistencia/asistencia-menu/asistencia-menu.component';
import { ReportesAjusteSueldoComponent } from './views/reportes/reportes-ajuste-sueldo/reportes-ajuste-sueldo.component';

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [
    AppComponent,
    AreaComponent,
    AreaFormComponent,
    PuestoComponent,
    PuestoFormComponent,
    AreaCatComponent,
    PuestoCatComponent,
    TipoContratoComponent,
    TipoContratoFormComponent,
    TipoContratoCatComponent,
    EmpleadoComponent,
    EmpleadoFormComponent,
    DireccionFormComponent,
    ContactoFormComponent,
    ContratoFormComponent,
    TipoContactoCatComponent,
    ContratoComponent,
    ContratoWizardComponent,
    PersonalesFormComponent,
    HomeComponent,
    OrganigramaFormComponent,
    VacacionesDashComponent,
    ContratoPreviewComponent,
    CuentaFormComponent,
    SolicitudesColaboradoresComponent,
    ContratoRenovacionComponent,
    ListContrRenovLiderComponent,
    ListContrRenovRhComponent,
    DashboardContratoFinRhComponent,
    DashboardContratoFinLiderComponent,
    OrganigramaEmpFormComponent,
    ContratoEmpFormComponent,
    EmpleadoVistaComponent,
    EmpleadoBajaComponent,
    ReportesMenuComponent,
    ReportesComponent,
    ReportesContratosComponent,
    ReportesVacacionesComponent,
    EmpleadoFotoComponent,
    ReportesTemporalesComponent,
    AjusteSueldoComponent,
    BancaComponent,
    BancaLiberacionRecursosComponent,
    BancaMenuComponent,
    BancaNoAsignadosComponent,
    SolicitudesApoyoFormComponent,
    SolicitudesApoyoComponent,
    SolicitudesPorAutorizarComponent,
    HistoricoSolicitudesApoyoFormComponent,
    HistoricoSolicitudesApoyoComponent,
    ContactoFormTelefonoComponent,
    AsistenciaFormComponent,
    AsistenciaComponent,
    EmpleadoChecadorFormComponent,
    EmpleadoChecadorComponent,
    AsistenciaRevisarComponent,
    AsistenciaIndexComponent,
    AsistenciaMenuComponent,
    ReportesAjusteSueldoComponent
  ],
  imports: [
    FontAwesomeModule,
    ConfirmDialogModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    TableModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    StepsModule,
    ButtonModule,
    SelectButtonModule,
    CalendarModule,
    RadioButtonModule,
    MessageModule,
    CheckboxModule,
    ChartModule,
    DividerModule,
    ScrollPanelModule,
    KeyFilterModule,
    InputNumberModule,
    TabViewModule,
    DataViewModule,
    DialogModule,
    RippleModule,
    RatingModule,
    ListboxModule,
    AccordionModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    ButtonModule,
    ToastModule,
    ProgressSpinnerModule,
    TooltipModule,
    BlockUIModule,
    MenubarModule,
    FileUploadModule
  ],
  providers: [MessageService, BaseMessages, SessionClientProvider, ConfirmationService,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: 'env', useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
