import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CodigoPostalPsService } from 'src/app/procs/direccion/codigo-postal-ps.service';
import { Asentamiento } from 'src/app/api/direccion/asentamiento';
import { DireccionDet } from 'src/app/api/direccion/direccion-det';
import { MessageService } from 'primeng/api';
import { CodigoPostalRestcService } from '../../../client/direccion/codigo-postal-restc.service';
import { DireccionPsService } from '../../../procs/direccion/direccion-ps.service';

@Component({
  selector: 'axks-direccion-form',
  templateUrl: './direccion-form.component.html',
  styleUrls: ['./direccion-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DireccionFormComponent extends BaseView implements OnInit {

  @Input() item: DireccionDet = {};
  @Input() esRenovacion: boolean = false;
  @Input() esVerdadero: string;

  @Output() onDirchange = new EventEmitter<DireccionDet>();
  @Output() renovDir = new EventEmitter<any>();

  disabled: boolean;
  asentamientos: Asentamiento[];
  selAsentamiento: Asentamiento;
  dirRenova: DireccionDet;
  Alphabetic = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|1234567890*¬¸~─,¨:;-]/;
  Alphanumeric = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private cpProcess: CodigoPostalPsService,
    private dirPs: DireccionPsService) {

    super(screenModeService, deviceService, messageService, sessProvider);

  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
      } as DireccionDet;
    }

    if (!this.item.pais) {
      this.item.pais = 'México'
    }

    if (this.esRenovacion) {
      this.disabled = true;
      this.dirPs.findById(this.item.id).subscribe(
        (data) => {
          this.dirRenova = data as DireccionDet;
          this.item.cp = this.dirRenova.cp;
          this.findWithCP(null);
        });
    } else {
      this.disabled = false;
    }
  }

  findWithCP(event) {
    this.asentamientos = [];
    this.selAsentamiento = {};

    if (this.item.cp) {
      this.cpProcess.findById(this.item.cp).subscribe(
        (data) => {
          this.asentamientos = data as Asentamiento[];
          if (this.esRenovacion) {
            //this.onSelAsentamiento(null)
            this.asentamientos.forEach(
              (ase) => {
                if (ase.asentamiento === this.dirRenova.colonia) {
                  this.selAsentamiento = ase;
                  this.onSelAsentamiento(undefined);
                }
              }
            );
          }
        }
      );
    }
  }

  onSelAsentamiento(event) {
    this.item = this.getDireccionDetalle();
    this.onDirchange.emit(this.item);
  }

  changeComplementDir(event) {
    if (this.item.id) {
      this.item = this.getDireccionDetalle();
      this.onDirchange.emit(this.item);
    }
  }

  getDireccionDetalle() {
    let dirDet = {
      id: this.selAsentamiento.id,
      alcMun: this.selAsentamiento.municipio,
      calle: this.item.calle,
      ciudad: this.selAsentamiento.ciudad,
      colonia: this.selAsentamiento.asentamiento,
      cp: this.selAsentamiento.cp,
      estado: this.selAsentamiento.estado,
      numExt: this.item.numExt,
      numInt: this.item.numInt,
      pais: this.item.pais
    } as DireccionDet;

    return dirDet;
  }
}
