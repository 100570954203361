import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Empleado } from 'src/app/api/empleado/empleado';
import { EmpleadoMin } from 'src/app/api/empleado/empleado-min';
import { EmpleadoFilter } from 'src/app/api/empleado/empleado-filter';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProfileC } from '@axks/components/lib/api/views/profile';

import { EmpleadoDetalle } from 'src/app/api/empleado/empleado-detalle';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { PreRenovacionReg } from 'src/app/api/pre-renovacion/pre-renovacion-reg';

@Component({
  selector: 'axks-empleado-vista',
  templateUrl: './empleado-vista.component.html',
  styleUrls: ['./empleado-vista.component.scss']
})
export class EmpleadoVistaComponent extends BaseMainComponent<EmpleadoPsService> implements OnInit {

  @Input() mostrarDetalle: boolean;

  @Output() propagarRenovacion = new EventEmitter<any>();

  extraButtons: ButtonModel[];
  tieneVacaciones: boolean;
  autorizaRenovacion: PreRenovacionReg[] = [];
  OP_MO = OperationMode;
  displayDialog: boolean = false;

  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private psService: EmpleadoPsService
  ) {
    super(deviceService, messageService, screenModeService, sessProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
    this.mostrarDetalle = true;
  }

  iniciarDetalle() {
    this.getService().detail
  }

  initEmptyItem(): ComponentItem {

    let profile = this.getProfile() as ProfileC;
    let idEmpleado = profile.idEmpleado

    let item = {
      item: {
        id: idEmpleado,
        direcciones: [],
        mediosContacto: [],
        personales: {}
      } as EmpleadoDetalle,
      minimumItem: {
        id: idEmpleado,
        personales: {}
      } as EmpleadoMin,
      registerItem: {
        id: idEmpleado,
        personales: {}
      } as Empleado
    } as ComponentItem;

    switch (this.operationMode) {
      case OperationMode.SEARCH:
        item.item = { personales: {} } as EmpleadoFilter;
        break;
      case OperationMode.EDIT:
        item.item = {} as Empleado;
        break;
    }

    return item;
  }

  getService(): EmpleadoPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      // Tab Datos personales
      { header: 'Nombre', field: 'personales.nombre', groupId: 'Datos Personales', inAll: true, showGroupId: false },
      { header: 'Apellido Paterno', field: 'personales.apellidoPaterno', groupId: 'Datos Personales', inAll: true },
      { header: 'Apellido Materno', field: 'personales.apellidoMaterno', groupId: 'Datos Personales', inAll: true },
      { header: 'Genero', field: 'personales.genero', groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatGenero },
      { header: 'Fecha de Nacimiento', field: "personales.fechaNacimiento", groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatDate },
      { header: 'CURP', field: 'personales.curp', groupId: 'Datos Personales', inDetail: true },
      { header: 'RFC', field: 'rfc', groupId: 'Datos Personales', inDetail: true },
      { header: 'Estado Civil', field: 'personales.estadoCivil', groupId: 'Datos Personales', inDetail: true, formatFunction: this.formatEstadoCivil },
      { header: 'NSS', field: 'personales.nss', groupId: 'Datos Personales', inDetail: true },

      // Tab Empleado
      { header: 'ID', field: 'id', inDetail: true, inResultList: true },
      { header: 'Puesto', field: 'puesto.puesto', inDetail: true },
      { header: 'Área', field: 'puesto.area.area', inDetail: true },
      { header: 'Sueldo', field: 'sueldo.sueldo', inDetail: true, formatFunction: this.formatMonto },
      { header: 'Sueldo diario', field: 'sueldo.sueldoDiario', inDetail: true, formatFunction: this.formatMonto },

      // Tab Dirección
      { header: 'Calle', field: 'calle', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'No. Exterior', field: 'numExt', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'No. Interior', field: 'numInt', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Colonia', field: 'colonia', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Código postal', field: 'cp', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Alcaldía ó Municipio.', field: 'alcMun', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Ciudad', field: 'ciudad', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'Estado', field: 'estado', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },
      { header: 'País', field: 'pais', arrayFieldName: 'direcciones', groupId: 'Dirección', inDetail: true },

      // Tab Medios de contacto
      { header: 'Contacto', field: 'contacto', arrayFieldName: 'mediosContacto', groupId: 'Medios Contacto', inDetail: true },
      { header: 'Tipo de Contacto', field: 'tipoContacto.tipoContacto', arrayFieldName: 'mediosContacto', groupId: 'Medios Contacto', inDetail: true },

      // Tab Contrato activo
      { header: 'ID contrato ', field: 'contrato.idContrato', inDetail: true, groupId: 'Contrato Activo' },
      { header: 'Fecha contratacion ', field: 'contrato.fecContratacion', inDetail: true, groupId: 'Contrato Activo', formatFunction: this.formatDate },
      { header: 'Fecha Ingreso ', field: 'fechaIniContratacion', inResultList: true, formatFunction: this.formatDate },
      { header: 'Fecha termino contrato ', field: 'contrato.fecTerminoContrato', inDetail: true, groupId: 'Contrato Activo', formatFunction: this.formatDate },
      { header: 'Tipo de contrato ', field: 'contrato.nombreTipoContrato', inDetail: true, groupId: 'Contrato Activo' },
      { header: 'Jefe Inmediato ', field: 'jefeInmediato.nombre', inDetail: true, groupId: 'Contrato Activo' },

      // Tab Cuentas Bancarias
      { header: 'Número de cuenta ', field: 'numeroCuenta', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },
      { header: 'Banco ', field: 'idBancoReceptor.bancoReceptor', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },
      { header: 'Tipo de cuenta', field: 'idTipoCuenta.tipoCuenta', arrayFieldName: 'datosBancarios', inDetail: true, groupId: 'Cuentas Bancarias' },

      // Tab Historico de contratos
      { header: 'ID contrato', field: 'idContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Historico de contratos' },
      { header: 'Fecha Contrataciòn', field: 'fecContratacion', arrayFieldName: 'contratos', inDetail: true, groupId: 'Historico de contratos', formatFunction: this.formatDate },
      { header: 'Fecha Termino de contrato', field: 'fecTerminoContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Historico de contratos', formatFunction: this.formatDate },
      { header: 'Estatus', field: 'nombreEstatus', arrayFieldName: 'contratos', inDetail: true, groupId: 'Historico de contratos' },
      { header: 'Tipo de contrato', field: 'nombreTipoContrato', arrayFieldName: 'contratos', inDetail: true, groupId: 'Historico de contratos' }
    ] as ColumnDefinition[];
  }

  formatDate(fecha: string): string {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(fecha, "dd 'de' MMMM 'de' yyyy");
  }

  formatMonto(monto): string {
    let pipe = new CurrencyPipe("es-MX", "MXN");
    return pipe.transform(monto);
  }

  formatGenero(value: string): string {
    if (value == 'M')
      return 'Masculino';
    else if (value == 'F')
      return 'Femenino';
    else
      return '';
  }

  formatEstadoCivil(value: string): string {
    if (value == 'S')
      return 'Soltero/a';
    else if (value == 'C')
      return 'Casado/a';
    else if (value == 'V')
      return 'Viudo/a'
    else
      return value;
  }

  getIdOfSelectedItem() {
    let profile = this.getProfile() as ProfileC;
    let empresa = profile.empresa;
    let idEmpleado = profile.idEmpleado;

    let id = "?idEmpleado=" + idEmpleado + "&idEmpresa=" + empresa;
    return id;
  }

  getComponentTitle(): string {
    return "Empleado";
  }

  rowSelect(idEmpleado) {
    super.rowSelect(idEmpleado);
  }

  obtenerIdEmp() {
    let profile = this.getProfile() as ProfileC;
    return profile.idEmpleado;
  }

  cargarTabla() {
    let profile = this.getProfile() as ProfileC;
    let idEmpleado = profile.idEmpleado;
    this.item.minimumItem.id = idEmpleado;

    this.rowSelect(idEmpleado);
  }
}