<!-- {{item | json}}   -->

<h2 style="text-align: center;" class="frm-resume-header">Resumen</h2>
<p-scrollPanel [style]="{width: '100%', height: '65vh'}">
<p-divider align="center" >
    <h3 class="frm-resume-subheader">Datos Personales</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Nombre: </div><br>
        <div class="wz-preview-value">{{item.personales.nombre}} {{item.personales.apellidoPaterno}} {{item.personales.apellidoMaterno}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Genero: </div><br>
        <div class="wz-preview-value">{{genero(item.personales.genero || '')}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Fecha Nacimiento: </div><br>
        <div class="wz-preview-value">{{item.personales.fechaNacimiento | date: "dd 'de' MMMM 'de' yyyy": 'es-MX'}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>CURP: </div><br>
        <div class="wz-preview-value">{{item.personales.curp}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Estdo Civil: </div><br>
        <div class="wz-preview-value">{{estadoCivil(item.personales.estadoCivil || '')}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>NSS: </div><br>
        <div class="wz-preview-value">{{item.personales.nss}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>RFC: </div><br>
        <div class="wz-preview-value">{{item.rfc || ''}}</div>
    </div> 
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Nacionalidad: </div><br>
        <div class="wz-preview-value">{{item.personales.nacionalidad || ''}}</div>
    </div>
    <ng-container *ngIf="item.personales.nacionalidad!='Mexicana'">
        <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
            <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>N° de Documento Migratorio: </div><br>
            <div class="wz-preview-value">{{item.personales.numDocMigratorio || ''}}</div>
        </div> 
    </ng-container> 
</div>
<p-divider align="center" >
    <h3 class="frm-resume-subheader">Dirección</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>País: </div><br>
        <div class="wz-preview-value">{{direccion.pais || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Estado: </div><br>
        <div class="wz-preview-value">{{direccion.estado || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Municipio: </div><br>
        <div class="wz-preview-value">{{direccion.alcMun || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Ciudad: </div><br>
        <div class="wz-preview-value">{{direccion.ciudad || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Colonia: </div><br>
        <div class="wz-preview-value">{{direccion.colonia || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Calle: </div><br>
        <div class="wz-preview-value">{{direccion.calle || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>No. Exterior: </div><br>
        <div class="wz-preview-value">{{direccion.numExt || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
        <div class="wz-preview-label">No. Interior: </div><br>
        <div class="wz-preview-value">{{direccion.numInt || ''}}</div>
    </div>
</div>
<p-divider align="center" >
    <h3 class="frm-resume-subheader">Medios de contacto</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngFor="let contacto of item.mediosContacto">
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Contacto: </div><br>
        <div class="wz-preview-value">{{contacto.contacto || ''}}</div>
    </div>
</div>

<p-divider align="center" >
    <h3 class="frm-resume-subheader">Datos del Contrato</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Tipo de contrato: </div><br>
        <div class="wz-preview-value">{{item.contrato["tipoContrato"] || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Fecha de contratación: </div><br>
        <div class="wz-preview-value">{{item.contrato.fecContratacion | date: "dd 'de' MMMM 'de' yyyy": 'es-MX' || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label">Fecha término contrato: </div><br>
        <div class="wz-preview-value">{{item.contrato.fecTerminoContrato | date: "dd 'de' MMMM 'de' yyyy": 'es-MX' || ''}}</div>
    </div>
    <!-- <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>RFC: </div><br>
        <div class="wz-preview-value">{{item.contrato.rfc || ''}}</div>
    </div> -->
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Periodo de pago: </div><br>
        <div class="wz-preview-value">{{item.contrato["periodoPago"] || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Sueldo: </div><br>
        <div class="wz-preview-value">{{item.contrato.sueldo | currency: 'MXN'}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Sueldo diario: </div><br>
        <div class="wz-preview-value">{{item.contrato.sueldoDiario | currency: 'MXN'}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label">IMSS: </div><br>
        <!-- <div class="wz-preview-value">{{item.contrato.imss || ''}}</div> -->
        <div class="wz-preview-value" *ngIf="item.contrato.imss==true; else noTiene">Si </div>
        <ng-template #noTiene><div class="wz-preview-value">No</div></ng-template>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label">INFONAVIT: </div><br>
        <!-- <div class="wz-preview-value">{{item.infonavit || ''}}</div> -->
        <div class="wz-preview-value" *ngIf="item.infonavit==true; else noTiene">Si</div>
        <ng-template #noTiene><div class="wz-preview-value">No</div></ng-template>
    </div>
</div>

<p-divider align="center" >
    <h3 class="frm-resume-subheader">Organigrama</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Área Laboral: </div><br>
        <div class="wz-preview-value">{{item["area"]|| ''}}</div>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Puesto: </div><br>
        <div class="wz-preview-value">{{item["puesto"]}}</div>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label">Es líder: </div><br>
       <!--  <div class="wz-preview-value">{{item.lider || ''}}</div> -->
        <div class="wz-preview-value" *ngIf="item.lider==true; else noTiene">Si</div>
        <ng-template #noTiene><div class="wz-preview-value">No</div></ng-template>
        
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Jefe inmediato: </div><br>
        <div class="wz-preview-value">{{item["jefeInmediato"]}}</div>
    </div>
    <!-- <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Fecha asignación: </div><br>
        <div class="wz-preview-value">{{item.fecIniAsig || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label">Fecha fin asignación: </div><br>
        <div class="wz-preview-value">{{item.fecFinAsig || ''}}</div>
    </div> -->
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Rol en el sistema: </div><br>
        <div class="wz-preview-value">{{item["rol"] || ''}}</div>
    </div>
</div>

<p-divider align="center" >
    <h3 class="frm-resume-subheader">Datos Bancarios</h3>
</p-divider>
<div class="p-grid frm-resume">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Clabe Interbancaria: </div><br>
        <div class="wz-preview-value">{{item.cuentaBancoEmpleado.numeroCuenta || ''}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Banco Receptor: </div><br>
        <div class="wz-preview-value">{{item.cuentaBancoEmpleado.idBancoReceptor.bancoReceptor}}</div>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" >
        <div class="wz-preview-label"><span [style]="{'color':'red'}">*</span>Tipo de cuenta: </div><br>
        <div class="wz-preview-value">{{item.cuentaBancoEmpleado.idTipoCuenta.tipoCuenta}}</div>
    </div>
</div>
<br><br>
<!-- {{item | json}} -->
</p-scrollPanel>