import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoChecador } from 'src/app/api/checador/checador';
import { ChecadorPsService } from 'src/app/procs/checador/checador-ps.service';
import { EmpleadoChecadorPsService } from '../../../procs/checador/empleado-checador-ps.service';

@Component({
  selector: 'axks-empleado-checador',
  templateUrl: './empleado-checador.component.html',
  styleUrls: ['./empleado-checador.component.scss']
})
export class EmpleadoChecadorComponent extends BaseMainComponent<EmpleadoChecadorPsService> implements OnInit {

  @Output() tituloReportes = new EventEmitter<String>();


  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProv: SessionClientProvider,
    private clientEmpCheck: EmpleadoChecadorPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProv);
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as EmpleadoChecador,
      minimumItem: {} as EmpleadoChecador,
      registerItem: {} as EmpleadoChecador
    } as ComponentItem;
    return empty;
  }
  getService(): EmpleadoChecadorPsService {
    return this.clientEmpCheck;
  }
  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      { field: 'idEmpleado', header: 'Núm. Empleado', inAll: true },
      { field: 'empleado', header: 'Empleado', inAll: true },
      { field: 'idChecador', header: 'ID Checador', inAll: true },
      { field: 'nur', header: 'NUR', inAll: true },
      { field: 'sucursal', header: 'Lugar de trabajo', inAll: true }
    ] as ColumnDefinition[];

    return cols;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.idEmpChecador || this.item.item.idEmpChecador;
  }
  getComponentTitle(): string {
    return '';
  }

  configTituloReportes() {
    this.tituloReportes.emit('Empleado checador');
  }

}
