import { Injectable } from '@angular/core';
import { Empleado } from 'src/app/api/empleado';
import { Arest, ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Empleadocat } from '../../api/empleadocat';
import { EmpleadoFilter } from '../../api/empleado/empleado-filter';
import { EmpleadoMin } from '../../api/empleado/empleado-min';
import { EmpleadoMinVw, EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { LiderCat } from 'src/app/api/empleado/lider-cat';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoRestcService extends ARestClient {//Arest<Empleado,number>{

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msEmpleado + "/";
  }
  processBody(res: any) {
    let body = res;
    return body || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "ps/detalle";
    paths.remove = "ps/remove";
    paths.modify = "";
    paths.findById = "ps/findById";
    paths.search = "ps/search";
    paths.all = "ps/all";
    paths.add = "ps/add";
    paths.addUpld = "ps/addUpld";


    return paths;
  }
  catEmpleado(): Observable<Empleadocat> {
    let path = environment.msEmpleadoCat;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }
  persona(id: number): Observable<any> {
    let path = environment.msPersona + "/" + id;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers });
  }

  detalle(idEmpleado: number, idEmpresa: number) {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail + "?idEmpleado=" + idEmpleado + "&idEmpresa=" + idEmpresa;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }

  bajaEmpleado(liquidarEmp: any): Observable<any> {
    let path = environment.msLiquidacion;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, liquidarEmp, { headers });
  }

  cargarFoto(idEmpleado: number, foto: any): Observable<any> {
    let path = this.getBaseEndpoint() + "ps/cargar-foto";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const formData = new FormData();
    formData.append("file", foto);
    formData.append("idEmpleado", idEmpleado.toString());

    return this.httpClient.post(path, formData, { headers });
  }

  existeFoto(idEmpleado: number): Observable<any> {
    let path = this.getBaseEndpoint() + "ps/existe-foto";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idEmpleado", idEmpleado.toString());

    return this.httpClient.get(path, { headers, params });
  }

  obtenerFoto(idEmpleado: number): Observable<any> {
    let path = this.getBaseEndpoint() + "ps/obtener-foto";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idEmpleado", idEmpleado.toString());

    let result = this.httpClient.get(path, { headers, params, responseType: 'blob' });
    return result;
  }

  searchWithSwitch(filtro: EmpleadoFilter, empsInactivos: boolean): Observable<EmpleadoMin[]> {
    let path = this.getBaseEndpoint() + "ps/search-with-switch";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("empsInactivos", empsInactivos.toString());

    return this.httpClient.post(path, filtro, { headers, params }) as Observable<EmpleadoMin[]>;
  }

  empleadosActivos(idEmpresa: number): Observable<EmpleadoNombre[]> {
    let path = this.getBaseEndpoint() + "ps/empleados-contrato-activo/" + idEmpresa;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<EmpleadoNombre[]>;
  }

  lideresActivos(idEmpresa: number): Observable<LiderCat[]> {
    let path = this.getBaseEndpoint() + "ps/catlideres/" + idEmpresa;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<LiderCat[]>;
  }

}
