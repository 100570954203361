<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <div class="p-grid">
            <div class="p-col-8">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="dp-lider" [(ngModel)]="filtro.idLiderSolicitante" [autoDisplayFirst]="false"
                        [options]="lideres" optionLabel="nombre" optionValue="idEmpleado" [filter]="true"
                        filterBy="nombre" [style]="{'width':'100%'}" [showClear]="true"></p-dropdown>
                    <label for="dp-lider">Líder</label>
                </span>
            </div>
            <div class="p-col-4">
                <br>
                <button pButton type="button" label="Buscar" icon="pi pi-search"
                    (click)="initCargaSolicitudes()"></button>
            </div>
        </div>
    </div>

    <div class="p-sm-0 p-md-6 p-lg-8 p-xl-9 p-col-0"></div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <p-table #tc [value]="items" [resizableColumns]="true" [paginator]="true" [showCurrentPageReport]="true"
            [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]" class="result result-list"
            [globalFilterFields]="['empleado','liderAutoriza', 'liderSolicitante']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        Solicitudes pendientes
                    </span>

                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tc.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">Núm. Solicitud<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="empleado">Colaborador<p-sortIcon field="empleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fecSolicitud">Fecha de solicitud<p-sortIcon field="fecSolicitud"></p-sortIcon>
                    </th>
                    <th pSortableColumn="liderSolicitante">Líder solicitante<p-sortIcon
                            field="liderSolicitante"></p-sortIcon>
                    </th>
                    <th>
                        Respuesta a solicitud
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        {{value.id}}
                    </td>
                    <td>
                        {{value.empleado}}
                    </td>
                    <td>
                        {{value.fecSolicitud | date: "dd 'de' MMMM 'de' yyyy, hh:mm:ss a"}}
                    </td>
                    <td>
                        {{value.liderSolicitante}}
                    </td>
                    <td>
                        <p-button icon="pi pi-check-circle" styleClass="p-button-rounded" pTooltip="Autorizar solicitud"
                            (onClick)="procesarSolicitud(value, true)"></p-button>
                        &nbsp; &nbsp;
                        <p-button icon="pi pi-ban" styleClass="p-button-rounded p-button-danger" pTooltip="Rechazar solicitud"
                            (onClick)="procesarSolicitud(value, false)"></p-button>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-toast></p-toast>