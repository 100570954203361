import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ColumnDefinition, OperationMode, OperationScreenModeService, BaseMainComponent, ComponentItem, BaseMessages, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Contrato } from 'src/app/api/contrato';
import { ContratacionPsService } from 'src/app/procs/contratacion/contratacion-ps.service';
import { ContratacionReg } from 'src/app/api/contratacion/contratacion-reg';
import { DireccionMinReg } from 'src/app/api/direccion/direccion-min-reg';
import { ContactoReg } from 'src/app/api/contacto/contacto-reg';
import { PersonaReg } from 'src/app/api/contratacion/persona-reg';
import { ContratoMinReg } from 'src/app/api/contratacion/contrato-min-reg';
import { Empleado } from 'src/app/api/empleado/empleado';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { ContratacionFilter } from '../../../api/contratacion/contratacion-filter';
import { ContratacionDetalle, ContratoSearch } from '../../../api/contratacion/contratacion-detalle';
import { PreRenovacionRestcService } from '../../../client/pre-renovacion/pre-renovacion-restc.service';
import { Prerenovacion } from '../../../api/pre-renovacion/prerenovacion';
import { PreRenovacionReg } from '../../../api/pre-renovacion/pre-renovacion-reg';
import { AutorizaRenovacionRestcService } from 'src/app/client/autoriza-renovacion/autoriza-renovacion-restc.service';
import { ContratoId } from 'src/app/api/contratacion/contratacion';

@Component({
  selector: 'axks-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.scss'],
  providers: [MessageService]
})
export class ContratoComponent extends BaseMainComponent<ContratacionPsService> implements OnInit {

  @Input() valor: Prerenovacion;
  @Input() item

  activeState: boolean[] = [true, false, false];
  prerenovacion: PreRenovacionReg;
  redirectToWizard: boolean = false;
  OP_MO: OperationMode;
  idEmpleado: number;
  idEmpresa: number;
  preRenovacionReg: PreRenovacionReg[] = [];
  renuevItem: ContratacionReg;

  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private psService: ContratacionPsService,
    private psRenovacion: PreRenovacionRestcService,
    private autorizaRhRestc: AutorizaRenovacionRestcService) {
    super(deviceService, messageService, screenModeService, sessProvider);

    let urlParams = new URLSearchParams(window.location.search);

    this.idEmpresa = parseInt(urlParams.get("ep"));
    this.idEmpleado = parseInt(urlParams.get("em"));
    this.redirectToWizard = false;
    if (urlParams.get("cw")) {
      this.redirectToWizard = true
    }

    /* if (this.idEmpleado && this.idEmpresa) {
      let emplEmpr = {
        idEmpleado: this.idEmpleado,
        idEmpresa: this.idEmpresa
      }
    } */
  }

  ngOnInit(): void {

    // Se carga en caso de ser renovación
    if (this.idEmpleado && this.idEmpresa) {
      let emplEmpr = {
        idEmpleado: this.idEmpleado,
        idEmpresa: this.idEmpresa,

      }

      this.showProgress = true;

      this.autorizaRhRestc.autorizaRenovacionRH(emplEmpr).subscribe(
        (data) => {
          this.prerenovacion = data as PreRenovacionReg;
          this.prerenovacion.contratacionReg.contrato.sueldo = this.prerenovacion.contratacionReg.sueldo;
          this.prerenovacion.contratacionReg.contrato.sueldoDiario = this.prerenovacion.contratacionReg.sueldoDiario;
          this.prerenovacion.contratacionReg.contrato.idPeriodoPago = this.prerenovacion.contratacionReg.idPeriodoPago;
          this.prerenovacion.contratacionReg.contrato.imss = this.prerenovacion.contratacionReg.imss;

          this.showProgress = false;
          
          setTimeout(() => { super.ngOnInit(); }, 1000);
        },
        (error) => {
          this.showProgress = false;
          this.errorMessage("Error", "No se pudo cargar la información previa del empleado");
        }
      );
    }
    // Se carga en caso de ser contratación 
    else {
      super.ngOnInit();
    }
  }

  initEmptyItem(): ComponentItem {

    let item = {} as ComponentItem;
    let profile = this.getProfile() as ProfileC;
    let idEmp = profile.empresa;

    if (this.idEmpresa && this.idEmpleado) {

      item.registerItem = this.prerenovacion.contratacionReg;

    } else {
      item.registerItem = {
        idEmpresa: idEmp,
        idEmpleadoUsuario: profile.idEmpleado,
        direcciones: [{} as DireccionMinReg] as DireccionMinReg[],
        mediosContacto: [] as ContactoReg[],
        personales: {} as PersonaReg,
        contrato: {} as ContratoMinReg,

      } as ContratacionReg;
    }

    item.item = {
      contrato: {} as Contrato,
      empleado: {} as Empleado,
      jefeInmediato: {} as Empleado
    } as ContratacionDetalle;
    item.minimumItem = {} as ContratoSearch;

    switch (this.operationMode) {
      case OperationMode.SEARCH:
        item.item = {} as ContratacionFilter;
        break;
      case OperationMode.EDIT:
        item.item = {} as Empleado;
        break;
    }

    return item;
  }

  getService(): ContratacionPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idContrato', inDetail: true, inResultList: true },
      { header: 'Tipo de Contrato', field: 'nombreTipoContrato', inDetail: true },
      { header: 'Fecha de Contratación', field: 'fecContratacion', inDetail: true },
      { header: 'Fecha Termino Cont', field: 'fecTerminoContrato', inDetail: true },

      { header: 'No. Empleado', field: 'idEmpleado', inAll: true, groupId: 'Empleado', showGroupId: true },
      { header: 'Nombre', field: 'nombre', inResultList: true, groupId: 'Empleado', showGroupId: true },
      { header: 'Apellido paterno', field: 'apellidoPaterno', inResultList: true, groupId: 'Empleado', showGroupId: true },
      { header: 'Apellido materno', field: 'apellidoMaterno', inResultList: true, groupId: 'Empleado', showGroupId: true },
      { header: 'Puesto', field: 'puesto', inResultList: true, groupId: 'Empleado', showGroupId: true },
      { header: 'Tipo de contrato', field: 'tipoContrato', inResultList: true, groupId: 'Empleado', showGroupId: true },

      { header: 'Nombre Completo', field: 'nombreEmpleado', inAll: false, groupId: 'Empleado', inDetail: true },

      { header: 'Estatus', field: 'nombreEstatus', inDetail: true, groupId: 'Empleado', showGroupId: true },

    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem(): ContratoId {
    let id = {} as ContratoId;
    if (this.item.minimumItem.idContrato) {
      id.idEmpleado = this.item.minimumItem.idEmpleado;
      id.idEmpresa = this.item.minimumItem.idEmpresa;
      id.idContrato = this.item.minimumItem.idContrato;
    } else {
      id.idEmpleado = this.item.item.empleado.id;
      id.idEmpresa = this.item.item.idEmpresa;
      id.idContrato = this.item.item.contrato.idContrato;
    }

    return id;
  }

  getComponentTitle(): string {
    return "Contratación";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "tipo", "fecContrat", "fecTerContrat", "idEmpleado"
    ];
    this.formFields = [
      "idPeriodoPago", "sueldo", "sueldoDiario", "imss", "infonavit", "tipo", "fecContrat", "fecTerContrat",
    ];
    this.detailFields = [];
  }

  showWizard(): boolean {
    if (this.prerenovacion) {
      this.screenModeService.screenMode.next(OperationMode.ADDNEW);
      this.operationMode = OperationMode.ADDNEW;
    } else {
      if (this.redirectToWizard) {
        this.screenModeService.screenMode.next(OperationMode.ADDNEW);
        this.operationMode = OperationMode.ADDNEW;
      }
    }
    return this.operationMode == OperationMode.ADDNEW ? true : false;
  }

  formatEdoCivil(clave: string) {
    switch (clave) {
      case 'S':
        return 'Soltero/a';
      case 'C':
        return 'Casado/a';
      case 'V':
        return 'Viudo/a';
      default:
        return 'Soltero';
    }
  }

  onWizardSave(event) {
    this.save(event);
  }

  onWizardCancel(event) {
    this.cancel(event);
  }

  procesaRenovacion(mensaje) {

  }

  esRenovacion(): boolean {
    return this.prerenovacion ? true : false;
  }

  onTabClose(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Closed', detail: 'Index: ' + event.index })
  }

  onTabOpen(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Expanded', detail: 'Index: ' + event.index });
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }
}

