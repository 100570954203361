import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { CuentaEmpleado } from 'src/app/api/cuenta/cuenta-empleado';
import { EmpleadoBaja } from 'src/app/api/empleado/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { CuentaEmpleadoPsService } from '../../../procs/cuenta-empleado/cuenta-empleado-ps.service';

@Component({
  selector: 'axks-empleado-baja',
  templateUrl: './empleado-baja.component.html',
  styleUrls: ['./empleado-baja.component.scss']
})
export class EmpleadoBajaComponent extends BaseView implements OnInit {

  @Input() item: EmpleadoBaja;
  @Input() showDialog: boolean;
  @Input() profile = super.getProfile() as ProfileC;
  @Input() idEmpleado: number;

  @Output() onHidden = new EventEmitter<boolean>();
  @Output() onReloadDetail = new EventEmitter();

  yearRange: string;
  cuentasBancarias: CuentaEmpleado[];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private liquidacionEmpleado: EmpleadoRestcService,
    private clientCuentas: CuentaEmpleadoPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idEmpleado: this.idEmpleado,
        idEmpresa: this.profile.empresa,
        idUserEmp: this.profile.idEmpleado
      }
    }

    this.initDates();
    this.initCuentasBancarias();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 5) + ':' + (year + 5);
  }

  initCuentasBancarias() {
    let filtro = {
      key: {
        idEmpleado: this.idEmpleado
      },
      activa: true
    } as CuentaEmpleado;
    this.clientCuentas.find(filtro).subscribe(
      (data) => {
        this.cuentasBancarias = data;
        for (let cItem of this.cuentasBancarias) {
          cItem.label = cItem.idBancoReceptor.bancoReceptor + " - " + cItem.numeroCuenta + " - " + cItem.idTipoCuenta.tipoCuenta;
        }
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener las cuentas bancarias del empleado");
      }
    );
  }

  guardar() {
    if (
      this.item.cuenta &&
      this.item.cuenta.numeroCuenta && this.item.cuenta.numeroCuenta.trim() != "" &&
      this.item.monto && this.item.monto > 0 &&
      this.item.fechaTermino &&
      this.item.tipoCuenta && this.item.tipoCuenta.trim() != "" &&
      this.item.plazaBanxico && this.item.plazaBanxico.trim() != ""
    ) {
      this.showProgress = true;

      this.liquidacionEmpleado.bajaEmpleado(this.item).subscribe(
        (data) => {
          let result = data as Message;
          this.successMessage(result.message, result.details);
          this.recargarDetalle();
          this.ocultarDialog();
          this.showProgress = false;
        },
        (error) => {
          this.showProgress = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        }
      );
    }
    else {
      this.infoMessage("Información necesaria", "Favor de llenar los campos requeridos");
    }
  }

  ocultarDialog() {
    this.onHidden.emit(false);
  }

  recargarDetalle() {
    this.onReloadDetail.emit();
  }
}
