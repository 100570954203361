<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('area')">
        <span class="p-float-label">
            <input id="fl-nmemp" type="text" pInputText [(ngModel)]="item.area">
            <label for="fl-nmemp"><span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red;">* </span> Área laboral</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('descripcion')">
        <span class="p-float-label">
            <input id="fl-nmemp" type="text" pInputText [(ngModel)]="item.descripcion">
            <label for="fl-nmemp"><span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red;">* </span>Descripción</label>
        </span>
    </div>
</div>
<p-toast></p-toast>