<p-progressSpinner *ngIf="!loadContent || showProgress"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner>

<ng-container *ngIf="loadContent">
    <h2>Renovación de contratos (RR.HH.)</h2>
    <p-dataView #dv [value]="listaRenov" [paginator]="true" [rows]="9" filterBy="nombreEmpleado" layout="list">
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Buscar por nombre"
                        (input)="dv.filter($event.target.value)">
                </span>
            </div>
        </ng-template>
        <ng-template let-itemrh pTemplate="listItem">
            <div class="p-col-12">
                <div class="itemrh-list-item" style="padding-inline: 30px">
                    <div class="itemrh-list-detail">
                        <div class="itemrh-name">
                            <span style="font-weight: bold;">Nombre: </span>
                            {{itemrh.nombreEmpleado}}
                        </div><br>
                        <div class="itemrh-description">
                            <span style="font-weight: bold;">Estatus: </span>
                            {{itemrh.estatus}}
                        </div><br>
                        <div class="itemrh-description">
                            <span style="font-weight: bold;">Fecha inicio de contrato: </span>
                            {{itemrh.fecIniContrato | date:"dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                        </div>
                        <div class="itemrh-description">
                            <span style="font-weight: bold;">Fecha fin de contrato: </span>
                            {{itemrh.fecFinContrato | date:"dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                        </div>
                        <div class="itemrh-description">
                            <span style="font-weight: bold;">Tipo de contrato actual: </span>
                            {{itemrh.tipoContrato}}
                        </div>
                        <br>
                        <div class="itemrh-description">
                            <span style="font-weight: bold;">Renovación de contrato: </span>
                            <span *ngIf="itemrh.renovar == null || itemrh.renovar == undefined">Pendiente de revisar por
                                el lider</span>
                            <span *ngIf="itemrh.renovar == true">Autorizado por lider</span>
                            <span *ngIf="itemrh.renovar == false">No autorizado por lider</span>
                        </div>
                        <div class="itemrh-description" *ngIf="itemrh.renovar == true">
                            <span style="font-weight: bold;">Nuevo tipo de contrato sugerido: </span>
                            {{itemrh.tipoContratoNuevo}}
                        </div>
                        <div class="itemrh-description" *ngIf="itemrh.renovar == true">
                            <span style="font-weight: bold;">Nueva duración del contrato sugerido: </span>
                            <span *ngIf="itemrh.idTipoContratoNuevo == tipoContratoEnum.PROYECTO">{{itemrh.numDias}}
                                días</span>
                            <span *ngIf="itemrh.idTipoContratoNuevo == tipoContratoEnum.PLANTA">Indefinido</span>
                        </div>
                    </div>
                    <div style="padding-block: 20px;">
                        <div>
                            <p-button type="button" (click)="autorizar(itemrh)" label="Renovar"
                                styleClass="p-button-info"
                                [disabled]="itemrh.decisionLider == true && itemrh.renovar == true ? false : true">
                            </p-button>
                        </div>
                        <div>
                            <p-button type="button" (click)="rechazar(itemrh)" label="Liquidar"
                                styleClass="p-button-danger" [disabled]="itemrh.decisionLider == true ? false : true">
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>

    <axks-empleado-baja *ngIf="showDialogBaja" [showDialog]="showDialogBaja" (onHidden)="ocultarDialog($event)"
        [idEmpleado]="idEmpleado" [class]="'cmp-form-'+operationMode" [profile]="profile" (onHidden)="ocultarDialog($event)"
        (onReloadDetail)="obtenerListaRh()"></axks-empleado-baja>
</ng-container>
<p-toast></p-toast>