<!-- <p-progressSpinner *ngIf="!loadContent || showProgress"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner> -->
<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">

    <div class="p-grid">
        <div class="p-col-12">
            <axks-detail-tab *ngIf="mostrarDetalle" [data]="workItem()" [colsDefinition]="colDefinition"
                [detailFields]="detailFields" class="axks-detail-tab-100">
            </axks-detail-tab>
        </div>

    </div>
</ng-container>