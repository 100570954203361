import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContratoReporte, CuotaTemporales } from 'src/app/api/reportes/reportes';

@Injectable({
  providedIn: 'root'
})
export class ReportesRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msReportes;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  contratosPorVencer(fechaInicio: string, fechaFin: string): Observable<ContratoReporte[]> {
    let path = this.getBaseEndpoint() + "contratos-por-vencer" + "?fecha-inicio=" + fechaInicio + "&fecha-fin=" + fechaFin;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<ContratoReporte[]>;
  }


  vacacionesPorVencer(fechaInicio: string, fechaFin: string): Observable<any> {
    let path = this.getBaseEndpoint() + "vacaciones-por-vencer" + "?fecha-inicio=" + fechaInicio + "&fecha-fin=" + fechaFin;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }

  cuotasTemporales(anio: number): Observable<CuotaTemporales[]> {
    let path = this.getBaseEndpoint() + "cuotas-temporales";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("anio", anio.toString());

    return this.httpClient.get(path, { headers, params }) as Observable<CuotaTemporales[]>;
  }
}
