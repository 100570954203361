import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMessages, BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoContrato } from 'src/app/api/tipo-contrato';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-tipo-contrato-form',
  templateUrl: './tipo-contrato-form.component.html',
  styleUrls: ['./tipo-contrato-form.component.scss']
})
export class TipoContratoFormComponent extends BaseView implements OnInit {

  @Input() item: TipoContrato = {};


  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {
        empresa: {}
      } as TipoContrato;
    }
  }

}
