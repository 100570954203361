<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<div class="contenedor-principal">
    <p-card class="borde-redondeado">
        <!-- {{item | json}} -->
        <h3>Ajuste de sueldo</h3>

        <div class="p-grid" style="margin-left: 1.5rem;">
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-emp" [options]="catEmpleados" [(ngModel)]="item.idEmpleado" optionValue="id"
                        optionLabel="nombreCompleto" [autoDisplayFirst]="false" [filter]="true"
                        filterBy="nombreCompleto" (onChange)="showSueldos($event)" [style]="{'width':'90%'}">
                    </p-dropdown>
                    <label for="fl-emp">Empleado</label>
                </span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-emp" [options]="catPeriodos" [(ngModel)]="item.idPeriodo" optionValue="id"
                        optionLabel="periodoPago" [autoDisplayFirst]="false" [filter]="true" filterBy="periodoPago"
                        [style]="{'width':'90%'}">
                    </p-dropdown>
                    <label for="fl-emp">Periodo de pago</label>
                </span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <br>
                <span class="p-float-label">
                    <p-inputNumber inputId="fl-sueldo" [(ngModel)]="item.sueldo" mode="currency" currency="MXN"
                        locale="es-MX" [style]="{'width':'90%'}" [min]="1">
                    </p-inputNumber>
                    <label for="fl-sueldo">Sueldo</label>
                </span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <br>
                <span class="p-float-label">
                    <p-inputNumber inputId="fl-sueldo" [(ngModel)]="item.sueldoDiario" mode="currency" currency="MXN"
                        [style]="{'width':'90%'}" locale="es-MX" [min]="1"></p-inputNumber>
                    <label for="fl-sueldo">Sueldo diario</label>
                </span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <br>
                <span class="p-float-label">
                    <p-calendar inputId="calendar" [(ngModel)]="item.fechaAplica" [showIcon]="true"
                        [style]="{'width':'90%'}" dataType="string" dateFormat="yy-mm-dd">
                    </p-calendar>
                    <label for="calendar">Fecha de aplicación</label>
                </span>
            </div>

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 contenedor-boton-guardar">
                <p-button label="Limpiar" icon="pi pi-refresh" (onClick)="limpiarDatos()"></p-button>
                &nbsp;&nbsp;
                <p-button label="Guardar" icon="pi pi-save" [loading]="isLoading" (onClick)="confirmarAjuste()"
                    pTooltip="Al guardar se generara un nuevo sueldo, pasando el ultimo sueldo al historico">
                </p-button>
            </div>
        </div>
    </p-card>

    <p-card class="tabla-borde-redondeado">
        <div class="p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <p-table [value]="sueldos" [responsive]="true" [resizableColumns]="true" [paginator]="true"
                    [showCurrentPageReport]="true" [rows]="25"
                    currentPageReportTemplate="{first} - {last} de {totalRecords}"
                    [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
                    [globalFilterFields]="['nombreEmpleado','nombreTipoContrato', 'nombreEstatus']">

                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                            <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">Historico de sueldos.
                                {{nombreEmpleado}}
                            </span>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="idSueldo">ID Sueldo<p-sortIcon field="idSueldo"></p-sortIcon>
                            </th>
                            <th pSortableColumn="sueldo">Sueldo<p-sortIcon field="sueldo"></p-sortIcon>
                            </th>
                            <th pSortableColumn="sueldoDiario">Sueldo diario<p-sortIcon field="sueldoDiario">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="fechaAplica">Fecha de aplicación<p-sortIcon field="fechaAplica">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="periodoPago">Periodo de pago<p-sortIcon field="periodoPago">
                                </p-sortIcon>
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-value>
                        <tr [pSelectableRow]="value">
                            <td>
                                <span class="p-column-title">ID Sueldo</span>
                                {{value.idSueldo}}
                            </td>
                            <td>
                                <span class="p-column-title">Sueldo</span>
                                {{value.sueldo | currency:'MXN'}}
                            </td>
                            <td>
                                <span class="p-column-title">Sueldo diario</span>
                                {{value.sueldoDiario | currency:'MXN'}}
                            </td>
                            <td>
                                <span class="p-column-title">Fecha de aplicación</span>
                                {{value.fechaAplica | date: "dd 'de' MMMM 'de' yyyy"}}
                            </td>
                            <td>
                                <span class="p-column-title">Periodo de pago</span>
                                {{value.periodoPago}}
                            </td>
                            <td style="display: flex; justify-content: center;">
                                <p-button icon="pi pi-trash" styleClass="p-button-danger" pTooltip="Borrar sueldo"
                                    tooltipPosition="bottom" (onClick)="confirmarBorrado(value)"></p-button>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </p-card>
</div>

<p-confirmDialog [baseZIndex]="20000"></p-confirmDialog>

<p-toast></p-toast>