import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreaRestcService extends ARestClient{
  

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msArea+"/";
  }
  processBody(res: any) {
    let body = res ;
    return body || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths =super.getRestPaths();
    paths.detail = "";
    paths.remove = "remove";

    return paths;
  }
}
