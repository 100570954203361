import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area/area';
import { Puesto } from '../../../api/puesto/puesto';
import { PuestoReg } from '../../../api/puesto/puesto-reg';
import { PuestoService } from '../../../procs/puesto/puesto.service';
import { AreaPsService } from 'src/app/procs/area/area-ps.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'axks-puesto',
  templateUrl: './puesto.component.html',
  styleUrls: ['./puesto.component.scss'],
  providers: [MessageService]
})
export class PuestoComponent extends BaseMainComponent<PuestoService> implements OnInit {

  areas: Area[];

  constructor(
    protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private puestops: PuestoService,
    private areaRestc: AreaPsService
  ) {
    super(deviceService, messageService, screenModeService, sessProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initCatAreas();
  }

  initEmptyItem(): ComponentItem {
    let profile = this.getProfile() as ProfileC;
    let empresa = profile.empresa;
    let idEmpresa = Number(empresa) || -1;

    let areaEmpty = { idEmpresa: idEmpresa } as Area;

    let empty = {
      item: { area: areaEmpty } as Puesto,
      registerItem: {} as PuestoReg,
      minimumItem: { area: areaEmpty } as Puesto
    } as ComponentItem;

    return empty;
  }

  getService(): PuestoService {
    return this.puestops;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'id', inDetail: true, inResultList: true },
      { header: 'Puesto', field: 'puesto', inAll: true },
      { header: 'Salario', field: 'salario', inAll: true, formatFunction: this.formatoMoneda },
      { header: 'Descripción', field: 'descripcion', inDetail: true },
      { header: 'Área', field: 'area.area', inAll: true }

    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Puestos";
  }

  componentsFields(): void {
    super.componentsFields();
    this.formSearch = ["area", "puesto", "salario"];
  }

  initCatAreas() {
    this.areaRestc.find({}).subscribe(
      (data) => {
        this.areas = data;
      },
      (error) => {
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  formatoMoneda(value: any): string {
    let pipe = new CurrencyPipe("es-MX");
    return pipe.transform(value, "MXN", "$");
  }


}
