<!-- {{item | json}} -->
<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" style="padding-top: 3vh;"
        *ngIf="allowFormFields('numeroCuenta')">
        <span class="p-float-label">
            <input id="fl-numcta" type="text" pInputText pKeyFilter="int" [(ngModel)]="item.numeroCuenta">
            <label for="fl-numcta" class="label-full"><span [style]="{'color':'red'}">* </span>Cuenta ó CLABE</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idBancoReceptor')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-bcrt" [(ngModel)]="item.idBancoReceptor.id" [options]="catBancoRec" optionValue="id"
                optionLabel="bancoReceptor" [autoDisplayFirst]="false" autoWidth="false" [style]="{'width':'100%'}"
                appendTo="body" [filter]="true" filterBy="bancoReceptor" (onChange)="setLabel($event)"
                emptyFilterMessage="Sin resultados" emptyMessage="Sin resultados">
            </p-dropdown>
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">* </span>Banco receptor</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idBancoReceptor')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-tp-cuenta" [(ngModel)]="item.idTipoCuenta" [options]="catTipoCuenta"
                optionLabel="tipoCuenta" [autoDisplayFirst]="false" autoWidth="false" [style]="{'width':'100%'}"
                appendTo="body" emptyMessage="Sin resultados">
            </p-dropdown>
            <label for="fl-tp-cuenta" class="label-full"><span [style]="{'color':'red'}">* </span>Tipo de cuenta</label>
        </span>

    </div>
</div>
<p-toast></p-toast>