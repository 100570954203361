import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { Rol } from 'src/app/api/usuario/rol';
import { RolRestcService } from 'src/app/client/usuario/rol-restc.service';

@Injectable({
  providedIn: 'root'
})
export class RolPsService extends BaseProcess<RolRestcService>{

  client: RolRestcService;
  constructor(httpClient: HttpClient, restc: RolRestcService) { 
    super(httpClient, restc);
    this.client = restc;
  }

  getAllRoles(idEmpresa: number): Observable<Rol>{
    return this.client.findById("all/"+idEmpresa);
  }
}
