import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { faClipboardCheck, faClipboardList, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { faUsers, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { IndexMenuBanca } from '../../../enums/index-menu-banca.enum';
import { Roles } from '../../../enums/roles.enum';

@Component({
  selector: 'axks-banca-menu',
  templateUrl: './banca-menu.component.html',
  styleUrls: ['./banca-menu.component.scss']
})
export class BancaMenuComponent extends BaseView implements OnInit {

  @Output() numMenu = new EventEmitter<number>();
  @Output() tituloReportes = new EventEmitter<String>();

  iconUsers = faUsers;
  iconUsersFree = faPeopleArrows;
  iconHelp = faHandshake;
  iconCheck = faClipboardCheck;
  iconClipboardCheck = faClipboardList;

  INDEX = IndexMenuBanca

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  emitirNumReporte(event) {
    this.numMenu.emit(event);
  }

  configTituloReportes() {
    this.tituloReportes.emit("Menu");
  }

  showCard(numCard: number): boolean {
    let roles = this.profile.rol;
    let result = false as boolean;
    switch (numCard) {
      case IndexMenuBanca.EMPLEADOS_ASIGNADOS:
      case IndexMenuBanca.SOLICITUDES_APOYO:
      case IndexMenuBanca.SOLICITUDES_APOYO_AUTORIZAR:
        roles.forEach(rol => {
          if (rol.idRol == Roles.LIDER)
            result = true;
        });
        break;
      case IndexMenuBanca.TODAS_SOLICITUDES_APOYO:
        roles.forEach(rol => {
          if (rol.idRol == Roles.DIRECTOR)
            result = true;
        });
        break;
      case IndexMenuBanca.EMPLEADOS_SIN_ASIGNACION:
        roles.forEach(rol => {
          if (rol.idRol == Roles.DIRECTOR || rol.idRol == Roles.LIDER)
            result = true;
        });
        break;
      default:
        result = false;
        break;
    }

    return result;
  }
}

