import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Area } from 'src/app/api/area';
import { LiderCat } from 'src/app/api/empleado/lider-cat';
import { AreaRestcService } from 'src/app/client/area/area-restc.service';
import { CatLiderPsService } from 'src/app/procs/empleado/cat-lider-ps.service';
import { EmpleadoDetalle } from '../../../api/empleado/empleado-detalle';
import { Puesto } from '../../../api/puesto';
import { PuestoRestcService } from '../../../client/puesto/puesto-restc.service';

@Component({
  selector: 'axks-organigrama-emp-form',
  templateUrl: './organigrama-emp-form.component.html',
  styleUrls: ['./organigrama-emp-form.component.scss']
})
export class OrganigramaEmpFormComponent extends BaseView implements OnInit {

  @Input() item = {
    puesto: {
      area: {}
    }
  } as EmpleadoDetalle;

  catArea: Area[] = [];
  catPuesto: Puesto[] = [];
  catLider: LiderCat[];
  profile = this.getProfile() as ProfileC;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private catLiderPs: CatLiderPsService,
    protected sessProvider: SessionClientProvider,
    private clientPuesto: PuestoRestcService,
    private clientArea: AreaRestcService) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        puesto: {
          area: {}
        }
      } as EmpleadoDetalle;
    }

    this.initCatLider(this.profile.empresa);
    this.initCatAreas();
    if (this.item.puesto) {
      this.initCatPuestos(this.item.puesto.area.id);
    }
  }

  initCatLider(idEmpresa: number) {
    this.catLiderPs.getCatalogoLider(idEmpresa).subscribe(
      (data) => {
        this.catLider = data as LiderCat[];
      },
      (error) => {
        this.errorMessage("Lideres", "No se pudo cargar el catalogo de lideres");
      }
    );
  }

  initCatPuestos(idArea: any) {
    let filtro = {
      area: {
        id: idArea
      }
    } as Puesto;
    this.clientPuesto.search(filtro).subscribe(
      (data) => {
        this.catPuesto = data;
      },
      (error) => {
        this.errorMessage("Puestos", "No se pudo cargar el catalogo de puestos");
      }
    );
  }

  initCatAreas() {
    this.clientArea.getAll().subscribe(
      (data) => {
        this.catArea = data;
      },
      (error) => {
        this.errorMessage("Areas", "No se pudo cargar el catalogo de areas");
      }
    );
  }

  setArea(event) {
    this.item.puesto.id = null;
    this.initCatPuestos(event.value);
  }

}
