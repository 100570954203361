import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado';
import { SolicitudApoyo } from 'src/app/api/banca/solicitud-apoyo';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { OrganigramaPsService } from 'src/app/procs/organigrama/organigrama-ps.service';

@Component({
  selector: 'axks-solicitudes-apoyo-form',
  templateUrl: './solicitudes-apoyo-form.component.html',
  styleUrls: ['./solicitudes-apoyo-form.component.scss']
})
export class SolicitudesApoyoFormComponent extends BaseView implements OnInit {

  @Input() item: SolicitudApoyo;
  @Input() empleadosActivos: EmpleadoNombre[];
  OP_MODE = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider,
    private clientEmp: EmpleadoRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProv);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
  }

  changeLider(event) {
    if (this.operationMode == this.OP_MODE.ADDNEW) {
      this.showProgress = true;
      this.clientEmp.detalle(event.value, this.profile.empresa).subscribe(
        (data) => {
          let item = data as any;
          this.item.idLiderAutoriza = item.jefeInmediato.idEmpleado;
          this.item.liderAutoriza = item.jefeInmediato.nombre;
          this.showProgress = false;
        },
        (error) => {
          this.errorMessage("Error", "No se pudo obtener el jefe inmediato del colaborador");
          this.showProgress = false;
        }
      );
    }
  }

}
