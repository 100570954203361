<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('tipoContacto')">
        <axks-tipo-contacto-cat [(value)]="tipoContacto"></axks-tipo-contacto-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('contacto')">
        <span class="p-float-label">
            <input id="fl-cont" type="text" pInputText [(ngModel)]="item.contacto">
            <label for="fl-coment" class="label-full"><span [style]="{'color':'red'}">*</span> Medio de contacto</label>
        </span>
    </div>
</div>

<p-toast></p-toast>