<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>
<!-- {{rangoFechas | json}}
{{fechaInicial}} - {{fechaFinal}} -->
<!-- {{item | json}} -->

<div class="p-grid" style="padding-top: 20px;">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <div class="p-grid">
            <div class="p-col-8">
                <br>
                <span class="p-float-label">
                    <p-calendar [yearNavigator]="true" [monthNavigator]="true" [yearRange]="yearRange"
                        dateFormat="dd/mm/yy" [readonlyInput]="true" inputId="cal-month" [showIcon]="true"
                        selectionMode="range" [(ngModel)]="rangoFechas"
                        pTooltip="Seleccionar una fecha inicial y una fecha final para una consulta por un rango de tiempo">
                    </p-calendar>
                    <label for="cal-month">Rango fechas</label>
                </span>
            </div>
            <div class="p-col-4">
                <br>
                <button pButton type="button" pTooltip="Buscar" icon="pi pi-search"
                    (click)="initAsistenciasRevisar()"></button>
            </div>
        </div>
    </div>

    <div class="p-sm-0 p-md-6 p-lg-8 p-xl-9 p-col-0"></div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <p-table #tv [value]="items" [paginator]="true" class="result-list result" [showCurrentPageReport]="true"
            [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}" [scrollable]="true"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
            [globalFilterFields]="['idEmpleado.nombreCompleto', 'fecEntrada', 'fecSalida', 'sucursal']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <div class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        <span *ngIf="fechaInicial && fechaFinal">
                            Asistencias por revisar del: {{fechaInicial | date: "dd
                            'de' MMMM 'de' yyyy"}} al {{fechaFinal | date: "dd 'de' MMMM 'de' yyyy"}}
                        </span>
                    </div>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tv.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">Núm. Registro<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th pSortableColumn="idEmpleado">Núm. Empleado<p-sortIcon field="idEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreCompleto">Empleado<p-sortIcon field="nombreCompleto"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fecEntrada">Entrada<p-sortIcon field="fecEntrada"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fecSalida">Salida<p-sortIcon field="fecSalida"></p-sortIcon>
                    </th>
                    <th pSortableColumn="sucursal">Lugar de trabajo<p-sortIcon field="sucursal"></p-sortIcon>
                    </th>
                    <th>Acción a realizar</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr>
                    <td>
                        {{value.id}}
                    </td>
                    <td>
                        {{value.idEmpleado.id}}
                    </td>
                    <td>
                        {{value.idEmpleado.nombreCompleto}}
                    </td>
                    <td>
                        {{value.fecEntrada | date: "dd 'de' MMMM 'de' yyyy, hh:mm:ss a" : "GMT-0" : "es-MX"}}
                    </td>
                    <td>
                        {{value.fecSalida | date: "dd 'de' MMMM 'de' yyyy, hh:mm:ss a" : "GMT-0" : "es-MX"}}
                    </td>
                    <td>
                        {{value.sucursal}}
                    </td>
                    <td>
                        <p-button type="button" (onClick)="mostrarVentana(value)" pTooltip="Generar descuento"
                            styleClass="p-button-danger p-button-rounded">
                            <fa-icon [icon]="iconDollar"></fa-icon>
                        </p-button>
                        &nbsp;
                        <p-button type="button" (onClick)="validarAsistencia(value)" pTooltip="Marcar como asistencia válida"
                            styleClass="p-button-rounded">
                            <fa-icon [icon]="iconCalendarCheck"></fa-icon>
                        </p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Descuento del empleado" [(visible)]="showDialog" position="center" [modal]="true" [closable]="true"
    [style]="{width: '40vw', 'background-color':'#f4f4f4'}" [baseZIndex]="20000" (onHide)="showDialog = false"
    contentStyleClass="content-dialog"
    [breakpoints]="{'1200px': '60vw', '992px': '80vw', '768px': '90vw', '576px': '100vw'}">

    <!-- {{items | json}} -->
    <!-- <p-card> -->
        <div class="p-grid frm-part">
            <div class="p-col-12">
                <div class="p-field">
                    <label for="fl-tpdesc">Tipo de descuento</label>
                    <p-dropdown id="fl-tpdesc" [options]="tpDescuentos" [(ngModel)]="item.idTipoDescuento"
                        [style]="{'width':'100%'}" optionLabel="tipoDescuento" optionValue="idTipoDescuento" [showClear]="true"
                        [autoDisplayFirst]="false"></p-dropdown>
                </div>
            </div>

            <div class="p-col-12">
                <div class="p-field">
                    <label for="fl-monto">Monto</label>
                    <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto" mode="currency" currency="MXN"
                        [style]="{'width':'100%'}">
                    </p-inputNumber>
                </div>
            </div>

            <div class="p-col-12">
                <div class="p-field">
                    <label for="fl-fecTermino">Fecha de descuento</label>
                    <p-calendar [(ngModel)]="item.fecDescuento" [baseZIndex]="20000" appendTo="body"
                        [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange" inputId="fl-fecTermino"
                        dataType="string" dateFormat="yy/mm/dd" [showIcon]="true">
                    </p-calendar>
                </div>
            </div>
        </div>
    <!-- </p-card> -->

    <ng-template pTemplate="footer">
        <p-button type="button" (onClick)="generarDescuento()" [loading]="isLoading" label="Generar descuento"
            icon="pi pi-save"></p-button>
    </ng-template>
    <p-toast key="msg-dialog"></p-toast>
</p-dialog>

<p-toast></p-toast>